import {useEffect} from 'react'
import { confirm } from '../asset';
import { useNavigate, useLocation } from 'react-router-dom';

const Successful = () => {
    const location = useLocation();
    const { gasVolume, totalAmount, meterNumbers, authUrl, reference, total_service_charge } = location.state || {}
    const navigate = useNavigate();

    useEffect(()=> {
        const timeoutId = setTimeout(()=> {
            navigate('/receipt', { state: { gasVolume, totalAmount, meterNumbers, reference, total_service_charge } });

        }, 3000)
        return () => clearTimeout(timeoutId)
    }, [navigate])
    return (
        <div className="bg-[#3361FF] h-screen">
            <div className="p-6 pt-[50%]">
                    <div className="flex flex-col items-center max-w-md mx-auto rounded-2xl mt-4 p-4">
                    <img src={confirm} alt='confirmImg'/>                   
                    <h2 className='text-xs text-white font-semibold mb-[30%] pt-[5%]'>Successful</h2>                  
                </div>
            </div>
        </div>
    );
}

export default Successful