

import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const Linemy = ({data, selectedMeter}) => {
  const formattedData = data.map((item) => ({
    date: item.created_at ? new Date(item.created_at).toLocaleDateString() : "",
    totalUsed: item.total_volume_used || 0,
    totalPurchased: item.total_volume_purchased || 0,
  }));

  const selectedMeterData = {
    date: 'Selected Meter',
    totalUsed: selectedMeter ? selectedMeter.total_volume_used || 0 : 0,
    totalPurchased:  selectedMeter ? selectedMeter.total_volume_purchased || 0 : 0,
  }

  const dataWithSelectedMeter = [...formattedData, selectedMeterData]

  return (
    <LineChart
      width={280}
      height={300}
      data={dataWithSelectedMeter}
      margin={{ top: 5, right: 50, left: -20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="totalUsed" stroke="#8884d8" activeDot={{ r: 8 }} name="Total Used" />
      <Line type="monotone" dataKey="totalPurchased" stroke="#82ca9d" activeDot={{ r: 8 }} name="Total Purchased" />
    </LineChart>
  );
};

export default Linemy;
