import React from 'react'
import { CheckCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const PickPaymentConfirmed = () => {
  return (
    <div>
            <div className="p-6 pb-[30%] pt-[40%]">               
                <div className="flex flex-col items-center max-w-md mx-auto rounded-2xl mt-4 p-4 bg-white">
                    <CheckCircle className='text-green-700' style={{fontSize: '3rem'}}/>
                    
                    <h2 className='font-bold text-2xl mt-[10%]'>Payment Confirmed!</h2>
                    
                    <Link to='/assigningRider'>
                        <button className="w-full p-4 text-white mt-[50%] hover:text-[#1F6FE2] hover:bg-white bg-[#1F6FE2] border border-[#7F9395] text-sm font-bold rounded-md">
                            Track Order
                        </button>
                    </Link>
                </div>
            </div>
        </div>
  )
}

export default PickPaymentConfirmed


