import React, { useCallback, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { MdOutlineMoreVert } from "react-icons/md";
import { plus, valve } from "../../asset";
import Barmy2 from "../../components/BarChart2";
import { Error } from "@mui/icons-material";

const CustomerDashboard = () => {
  const [selectedTab, setSelectedTab] = useState("Week");
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [theMeters, setTheMeters] = useState([]);
  const [userDashboard, setUserDashboard] = useState(null)
  const navigate = useNavigate()

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  useEffect(() => {
    const fetchUserDashboard = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/stats/customer_dashboard`, {
          headers: {
            token: localStorage.getItem('token'),
            "Content-Type": "application/json",
          },
        }
        )
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const data = await response.json();

        const { lpg_bought, total_spent, graph_data } = data.data;

        const chartData = Object.entries(graph_data).map(([yearMonth, revenue]) => {
          const [year, month] = yearMonth.split('-');
          return { name: `${month}-${year}`, Revenue: revenue };
        });

        setUserDashboard({
          total_spent,
          lpg_bought,
          chartData,
        });
      } catch (error) {
      }
    };
    fetchUserDashboard();
  }, [energyUrl]);

  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/users/me/orders`,
          {
            method: "GET",
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch order history");
        }
        const data = await response.json();
        setOrders(data.data.items);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchOrderHistory();
  }, [energyUrl]);

  useEffect(() => {
    const fetchMeterHistory = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/users/me?detailed=true`, {
          headers: {
            token: localStorage.getItem('token'),
            "Content-Type": "application/json",
          },
        }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch meter history");
        }

        const data = await response.json();
        setTheMeters(data.data);
      } catch (error) {

      } finally {
        setLoading(false);
      }
    };

    fetchMeterHistory();
  }, [energyUrl]);

  const handleSelectedMeterChange = useCallback((meter) => {
    navigate(`/meterHistoryGroup/${meter.id}`, { state: { meter } });
  }, [navigate])

  if (!theMeters) {
    return <div className="flex justify-center items-center text-xs mt-8 text-green-600">Loading...</div>;
  }

  return (
    <>
      <div className="bg-[#F7F8FA] overflow-x-hidden overflow-y-hidden">
        <div className="flex justify-center pt-[10%] gap-[35%] items-center">
          <h2 className="font-bold left-0">Dashboard</h2>
          <Link to="/buyToken">
            <div className="flex justify-center gap-3 items-center border border-l border-r text-xs text-white border-[#1F6FE2] p-2 rounded-full bg-[#1F6FE2] hover:text-[#1F6FE2] hover:bg-white">
              <img src={plus} alt="logoImg" />
              Buy Gas
            </div>
          </Link>
        </div>

        <div className="bg-red-100 h-20 p-4 ml-[6%] mr-[6%] mt-[5%] flex items-center justify-center">
          <Error className="text-red-600 -mt-[14%] mr-[2%]" style={{ width: '16px', height: '16px' }} />
          <p className="text-[10px]">Anytime you switch the LPG cylinder of your facility. It's very
            essential to click this button for replacement so you never run out of gas.</p>
          <Link to='/gasManAlert'>
            <button className="bg-orange-600 text-white hover:bg-orange-700 hover:text-white p-2 ml-[3%] mr-[3%]  flex gap-1 rounded-sm">
              <p className="text-[7px]">Changed</p>
              <p className="text-[7px]">Cylinder</p>
            </button>
          </Link>
        </div>

        <div className="flex justify-center mt-[10%] bg-transparent border gap-6 text-sm text-black p-3 rounded-full ml-[6%] mr-[6%]">
          <div
            className={`hover:border hover:border-l hover:border-r text-sm text-black hover:border-black pl-2 pr-2 pt-1 pb-1 rounded-full hover:bg-white ${selectedTab === "Week" ? "border" : ""
              }`}
            onClick={() => setSelectedTab("Week")}
          >
            Week
          </div>

          <div
            className={`hover:border hover:border-l hover:border-r text-sm text-black hover:border-black pl-2 pr-2 pt-1 pb-1 rounded-full hover.bg-white ${selectedTab === "Month" ? "border" : ""
              }`}
            onClick={() => setSelectedTab("Month")}
          >
            Month
          </div>

          <div
            className={`hover:border hover.border-l hover.border-r text-sm text.black hover.border-black pl-2 pr-2 pt-1 pb-1 rounded-full hover.bg-white ${selectedTab === "Year" ? "border" : ""
              }`}
            onClick={() => setSelectedTab("Year")}
          >
            Year
          </div>
        </div>

        <div className="p-6 pb-4">
          {userDashboard && (
            <div className="max-w-md mx-auto rounded-lg p-4 bg-white">
              <div className="mt-[2%] grid grid-cols-3 text-[10px] gap-6 max-w-md mx-auto p-4 items-center justify-between">
                <div>
                  <h2 className="text-2xl font-bold">{userDashboard.lpg_bought}KG</h2>
                  <h2>LPG Bought</h2>
                </div>

                <div>
                  <h2 className="text-2xl font-bold">₦{userDashboard.total_spent.toLocaleString()}</h2>
                  <h2>Expenses</h2>
                </div>
              </div>

              <div className="mt-[20%] text-[8px]">
                <Barmy2 data={userDashboard.chartData} />
              </div>
            </div>
          )}
        </div>

        <div className="p-6">
          <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
            <div className="flex justify-between mb-[10%]">
              <h2 className="text-black font-semibold text-sm">Meters</h2>
              <MdOutlineMoreVert />
            </div>

            <div className="text-[10px] pb-4">
              {theMeters && theMeters.meters ? (
                theMeters.meters.map((meter, idx) => (
                  <>
                    <div key={idx} className="grid grid-col-3 justify-start items-center border border-green-500  p-8 cursor-pointer" onClick={() => handleSelectedMeterChange(meter)}>
                      <div className="font-semibold text-xs flex flex-col">
                        <div>{meter.type}</div>
                        <div className="text-black font-normal text-xs flex">
                          <img src={valve} alt="valveImg" className="w-4 h-4 " />  {
                            meter.valve_is_open === true ? 'Open' :
                              meter.valve_is_open === false ? 'Closed' : 'N/A'
                          }
                       </div>
                       <div className="text-green-600 text-xs font-bold">Tag name:{meter.tag_name}
                       </div>
                      </div>
                   
                      <div className="font-semibold text-xs cursor-pointer">
                        {meter.meter_number}
                      </div>
                      <div className="text-green-500 font-bold text-xs">
                        {meter.total_kg_purchased ? `${meter.total_kg_purchased}KG` : <span className="text-xs">N/A</span>} <br />

                        <span className="text-black font-normal text-[10px]">{new Date(meter.created_at).toLocaleDateString()}</span>
                      </div>

                    </div>
                  </>
                ))
              ) : (
                <div className="flex justify-center items-center text-xs text-green-600">Loading...</div>
              )}
            </div>
          </div>
          <Link to="/meterHistory">
            <button className="w-full mt-[2%] mb-[10%] text-black text-sm">
              View All
            </button>
          </Link>
        </div>

        <div className="p-6">
          <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
            <div className="flex justify-between mb-[10%]">
              <h2 className="text-black font-semibold text-sm">Order History</h2>
              <MdOutlineMoreVert />
            </div>

            <div className="text-[10px] pb-4">
              {loading ? (
                <div className="flex justify-center items-center text-xs text-green-600">Loading...</div>
              ) : (
                orders.map((order) => (
                  <Link to={`/revampInvoice/${order.id}`} key={order.id}>
                    <div className="flex justify-between  pt-4 pb-4 border-b">
                      <h2>{order.serial_number}</h2>
                      <h2>{new Date(order.created_at).toLocaleDateString()}</h2>
                      <h2>{order.weight}kg</h2>
                      <h2 className="text-green-500">{`₦${order.amount}`}</h2>
                    </div>
                  </Link>
                ))
              )}
            </div>
          </div>
          <Link to="/orderHistory">
            <button className="w-full mt-[2%] mb-[10%] pb-4 text-black text-sm">
              View All
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CustomerDashboard;
