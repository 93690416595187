
import React, { useState, useCallback, useEffect } from "react";
import { proceed } from "../asset/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const BuyToken = () => {
  const navigate = useNavigate();
  const [meters, setMeters] = useState([]);
  const [selectedMeter, setSelectedMeter] = useState(null);
  const [meterNumber, setMeterNumber] = useState("");
  const [weight, setWeight] = useState("");
  const [loading, setLoading] = useState(false);
  const [rate, setRate] = useState(null);
  const [totalRate, setTotalRate] = useState("");
  const [serviceCharges, setServiceCharges] = useState(null);
  const [vats, setVats] = useState(null);
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [created, setCreated] = useState("");
  const [totalProductPrices, setTotalProductPrices] = useState(null);
  const [orderId, setOrderId] = useState("");

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL
  const rateApiUrl = `${energyUrl}/rates/customer`
  const userApiUrl = `${energyUrl}/users/me?detailed=true`;
  const callback_url = `${window.location.origin}/buyToken`;

  const handleBack = useCallback(() => navigate(-1), [navigate]);

  useEffect(() => {
    const fetchMeters = async () => {
      try {
        const response = await fetch(userApiUrl, {
          headers: {
            token: localStorage.getItem('token'),
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          const availableMeters = data.data.meters;
          setMeters(availableMeters);
          if (availableMeters.length > 0) {

            setSelectedMeter(availableMeters[0].meter_number);
            setMeterNumber(availableMeters[0].meter_number);
          } else {
            setMeterNumber("");
          }
        } else {
          toast.error("Failed to fetch meters");
        }
      } catch (error) {
        toast.error("An error occurred while fetching meters");
      }
    };

    fetchMeters();
  }, [userApiUrl]);

  const handleMeterChange = (event) => {
    setSelectedMeter(event.target.value);
    setMeterNumber(event.target.value);
  };

  useEffect(() => {
    const fetchRate = async () => {
      try {
        const response = await fetch(rateApiUrl);
        if (response.ok) {
          const data = await response.json();
          setRate(data.data);
        } else {
          toast.error("Failed to fetch rate");
        }
      } catch (error) {
        toast.error("An error occurred while fetching rate");
      }
    };
    fetchRate();
  }, [rateApiUrl]);

  const handleWeightChange = (e) => {
    const enteredWeight = e.target.value;

    if (!isNaN(enteredWeight) && enteredWeight !== "") {
      setWeight(enteredWeight);

      if (rate) {
        const calculatedTotalRate = parseFloat(enteredWeight) * rate;
        setTotalRate(calculatedTotalRate.toFixed(2));
      }
    } else {
      setWeight("");
      setTotalRate("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(`${energyUrl}/meters/vend`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          callback_url,
          meter_number: meterNumber,
          weight: parseInt(weight, 10),
          rate: rate,
          customer_address: customerAddress,
          customer_name: customerName,
          created_at: created,
          total_product_price: totalProductPrices || 0,
          total_service_charge: serviceCharges || 0,
          VAT: vats || 0,
        }),
      });

      if (response.ok) {
        const orderData = await response.json();
        if (orderData.status === "success") {
          toast.success(orderData.message);
          const { auth_url, reference } = orderData.data;
          setOrderId(orderData.data.order.id);
          setCustomerAddress(orderData.data.customer_address);
          setCustomerName(orderData.data.customer_name);
          setCreated(orderData.data.order.created_at);
          setTotalProductPrices(orderData.data.order.total_product_price || 0);
          setServiceCharges(orderData.data.order.total_service_charge || 0)
          setVats(orderData.data.order.VAT || 0)
          navigate('/reviewOrder', {
            state: {
              meterNumbers: meterNumber,
              gasVolume: weight,
              totalAmount: totalRate,
              authUrl: auth_url,
              reference,
              created: orderData.data.order.created_at,
              customerAddress: orderData.data.customer_address,
              customerName: orderData.data.customer_name,
              totalProductPrice: orderData.data.order.total_product_price || 0,
              serviceCharge: orderData.data.order.total_service_charge || 0,
              vat: orderData.data.order.VAT || 0,
              orderId: orderData.data.order.id,
            },
          });

        } else {
          toast.error("Failed to submit the buy gas order");
        }
      } else {
        toast.error("Failed to submit the buy gas order");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the order");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-[#F7F8FA] ">
      <div className="flex pt-[10%] justify-center border-b gap-[50%] pb-[6%] items-center">
        <h2 className="font-bold">Buy Gas</h2>
        <img src={proceed} alt="logoImg" className="h-6 cursor-pointer" onClick={handleBack} />
      </div>

      <div className="p-6 pb-24">
        <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
          <h2 className="text-black font-semibold text-sm">
            Select Meter Number
          </h2>
          <div className="mb-8">
            <div className="relative">
              {meters.length > 0 ? (
                <select
                  value={selectedMeter}
                  onChange={handleMeterChange}
                  className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                >
                  <option value="">Select a meter</option>

                  {meters
                    .filter((meter) => meter.is_validated)
                    .map((meter, idx) => (
                      <option key={idx} value={meter.meter_number}>
                        {meter.meter_number} {meter.tag_name}
                      </option>
                    ))}
                </select>
              ) : (
                <input
                  type="text"
                  value={meterNumber}
                  onChange={(e) => setMeterNumber(e.target.value)}
                  className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                  placeholder="Enter Meter Number"
                />
              )}
            </div>
          </div>


          <h2 className="text-black font-semibold text-sm">Weight</h2>
          <div className="mb-8">
            <div className="relative">
              <input
                type="text"
                value={weight}
                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                placeholder="Enter LPG weight"
                onChange={handleWeightChange} />
            </div>
          </div>

          <h2 className="text-black font-semibold text-sm">Rate</h2>
          <div className="mb-8 text-sm">
            <p>{rate ? `Rate/Kg: ${rate}` : "Fetching rate..."}</p>
          </div>

          <h2 className="text-black font-semibold text-sm">Amount</h2>
          <div className="mb-8 text-sm">
            <p className="text-green-500 font-bold">₦ {totalRate ? parseFloat(totalRate).toLocaleString("en") : "0.00"}</p>
          </div>

          <button
            onClick={handleSubmit}
            className="w-full pt-4 pb-4 mt-[10%] mb-[10%] hover:text-[#1F6FE2] hover:bg-white text-white bg-[#1F6FE2] border border-[#7F9395] text-sm font-bold rounded-md">
            {loading ? "Reviewing..." : "Order Review"}
          </button>

          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default BuyToken;