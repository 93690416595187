import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLogout } from "../../hook/useLogout";

const CustomerProfile = () => {
  const { logout } = useLogout()
  const [userDetails, setUserDetails] = useState(null)
  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/users/me?detailed=true`, {
          headers: {
            token: localStorage.getItem('token'),
            "Content-Type": "application/json",
          },
        }
        )

        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const data = await response.json();
        setUserDetails(data.data);
      } catch (error) {
      }
    };

    fetchUserDetails();
  }, [energyUrl]);

  const handleLogout = () => {
    logout()
  }

  if (!userDetails) {
    return <div className="flex justify-center items-center text-xs mt text-green-600">Loading...</div>;
  }


  return (
    <div className="bg-[#F7F8FA] ">

      <div className="p-2 pb-24">
        <div className="max-w-md mx-auto rounded-lg p-4 bg-white">
          {userDetails && (
            <>
              <div className="flex flex-row justify-between mx-auto px-4 mt-[12%]">
                <div className="flex gap-2 items-center">               
                  <img src={userDetails.image} alt="userImg" className="border rounded-full border-black p-4 w-4 h-4" />                
                  <div>
                    <h2 className="font-bold">User</h2>                   
                    <h2 className="font-bold text-xs">{userDetails.firstname} {userDetails.lastname}</h2>                   
                  </div>
                </div>
                <div className="w-[1px] h-6 bg-[#7F9395] mt-4"></div>
                <div className="relative text-xs mt-[5%]">
                  <div>
                    <h2 className="font-bold text-[#FF9D00]">Meter Number</h2>                    
                      <h2 className="font-bold">{userDetails.meter.meter_number}</h2>                   
                  </div>
                </div>
              </div>

              <div className="w-full h-[1px] bg-[#7F9395] mt-[6%]"></div>

              <div className="mt-[2%] grid grid-cols-3 text-[10px] gap-4 max-w-md mx-auto p-4 items-center justify-between">
                <div>
                  <h2>Agent Name</h2>
                  <h2 className="font-bold">{userDetails.agent_name}</h2>
                </div>

                <div>
                  <h2>Gender</h2>
                  <h2 className="font-bold">{userDetails.gender}</h2>
                </div>

                <div>
                  <h2>Date of Birth</h2>
                  <h2 className="font-bold">{userDetails?.dob}</h2>
                </div>

                <div>
                  <h2>State</h2>
                  <h2 className="font-bold">{userDetails.state}</h2>
                </div>

                <div>
                  <h2>Country</h2>
                  <h2 className="font-bold">{userDetails.country}</h2>
                </div>

                <div>
                  <h2>Phone Number</h2>
                  <h2 className="font-bold">{userDetails.phone}</h2>
                </div>
              </div>

              <div className="grid grid-cols-1 -mt-[6%] text-[10px] gap-4 max-w-md mx-auto p-4 items-center">
                <div>
                  <h2>Email</h2>
                  <h2 className="font-bold">
                    <span>{userDetails.email}</span>
                  </h2>
                </div>

                <div>
                  <h2>Address</h2>
                  <h2 className="font-bold">
                    <span>
                      {userDetails.address}
                    </span>
                  </h2>
                </div>
              </div>
            </>
          )}

          <div>
            <Link to="/">
              <button
                className="w-full mt-[20%] mb-[10%] pt-4 pb-4 text-white hover:text-[#e21f1f] hover:bg-white bg-[#e21f1f] border border-[#7F9395] text-sm font-bold rounded-md"
                onClick={handleLogout}>
                Log out
              </button>
            </Link>
          </div>


          <button className="w-full pt-4 pb-4 text-white bg-[#a9c4e9] border border-[#7F9395] text-sm font-bold rounded-md">
            Edit Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerProfile;
