import React, { useState, useEffect } from "react";
import { plus } from "../../asset";
import { Link } from "react-router-dom";
import Barmy from "../../components/BarChart";
import { DashboardSharp, Home } from "@mui/icons-material";
import { MdOutlineMoreVert } from "react-icons/md";


const PNDashboard = () => {
  const [selectedTab, setSelectedTab] = useState("Week");
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDashboard, setUserDashboard] = useState(null)

  const pndUrl = process.env.REACT_APP_PND_BASE_URL

  useEffect(() => {
    const fetchUserDashboard = async () => {
      try {
        const response = await fetch(
          `${pndUrl}/stats/agent_dashboard`, {
          headers: {
            token: localStorage.getItem('token'),
            "Content-Type": "application/json",
          },
        }
        )
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const data = await response.json();
        const { customers_total, total_lpg_sold, total_revenue, orders_graph_data } = data.data;

        const chartData = Object.entries(orders_graph_data).map(([yearMonth, revenue]) => {
          const [year, month] = yearMonth.split('-');
          return { name: `${month}-${year}`, Revenue: revenue };
        });

        setUserDashboard({
          customers_total,
          total_lpg_sold,
          total_revenue,
          chartData,
        });
      } catch (error) {
      }
    };

    fetchUserDashboard();
  }, [pndUrl]);

  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const response = await fetch(
          `${pndUrl}/users/me/orders`,
          {
            method: "GET",
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch order history");
        }
        const data = await response.json();
        setOrders(data.data.items);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchOrderHistory();
  }, [pndUrl]);


  return (
    <div className="bg-[#F7F8FA] overflow-x-hidden">
      <div className="flex flex-col pt-[10%] justify-start gap-[28%] items-center">

        <div className="flex justify-center gap-[80%]">
          <div className="flex">
            <DashboardSharp />
            <h2 className="font-bold left-0 mb-[10%]">Dashboard</h2>
          </div>

          <div className="flex">
            <Home />
            <h2 className="font-bold left-0 mb-[10%]">Home</h2>
          </div>

        </div>

        <div className="mr-[55%]">
          <Link to={{ pathname: (`/order`) }}>
            <div className="flex justify-center items-center border border-l border-r text-xs text-white border-[#1F6FE2] p-2 rounded-full bg-[#1F6FE2]">
              <img src={plus} alt="logoImg" />
              Order Now
            </div>
          </Link>
        </div>
      </div>

      <div className="flex justify-center mt-[10%] bg-transparent border gap-6 text-sm text-black p-3 rounded-full ml-[6%] mr-[6%]">
        <div
          className={`hover:border hover:border-l hover:border-r text-sm text-black hover:border-black pl-2 pr-2 pt-1 pb-1 rounded-full hover:bg-white ${selectedTab === "Week" ? "border" : ""
            }`}
          onClick={() => setSelectedTab("Week")} > Week </div>

        <div
          className={`hover:border hover:border-l hover:border-r text-sm text-black hover:border-black pl-2 pr-2 pt-1 pb-1 rounded-full hover.bg-white ${selectedTab === "Month" ? "border" : ""
            }`}
          onClick={() => setSelectedTab("Month")} > Month </div>

        <div
          className={`hover:border hover.border-l hover.border-r text-sm text.black hover.border-black pl-2 pr-2 pt-1 pb-1 rounded-full hover.bg-white ${selectedTab === "Year" ? "border" : ""
            }`}
          onClick={() => setSelectedTab("Year")} >  Year </div>
      </div>

      <div className="p-6 pb-4">
        {/* {userDashboard && ( */}
        <div className="max-w-md mx-auto rounded-lg p-4 bg-white">
          <div className="mt-[2%] grid grid-cols-3 text-[10px] gap-4 max-w-md mx-auto p-4 items-center justify-between">
            <div>
              <h2 className="text-md font-bold"> 23
                {/* {userDashboard.customers_total} */}
              </h2>
              <h2>Total Customers</h2>
            </div>

            <div>
              <h2 className="text-md font-bold"> 5
                {/* {userDashboard.total_lpg_sold} */}
              </h2>
              <h2>LPG Sold</h2>
            </div>

            <div>
              <h2 className="text-md font-bold"> 20,000
                {/* {userDashboard.total_revenue} */}
              </h2>
              <h2>Revenue</h2>
            </div>
          </div>

          <div className="mt-[20%] text-[8px]">
            <Barmy
            // data={userDashboard.chartData}                 
            />
          </div>
        </div>
        {/* )} */}
      </div>

      <div className="p-6 pb-24">
        <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
          <div className="flex justify-between mb-[10%]">
            <h2 className="text-black font-semibold text-sm">Order History</h2>
            <MdOutlineMoreVert />
          </div>

          <div className="text-[10px] pb-4">
            {loading ? (
              <div className="flex justify-center items-center text-xs text-green-600">Loading...</div>
            ) : (
              orders.map((order) => (
                <Link to={`/invoice/${order.id}`} key={order.id}>
                  <div className="flex justify-between  pt-4 pb-4 border-b">
                    <h2>{order.serial_number}</h2>
                    <h2>{new Date(order.created_at).toLocaleDateString()}</h2>
                    <h2>{order.weight}kg</h2>
                    <h2 className="text-green-500">{`₦${order.amount}`}</h2>
                  </div>
                </Link>
              ))
            )}
          </div>
        </div>
        <Link to="/orderHistory">
          <button className="w-full mt-[5%] mb-[10%] pb-4 text-black text-sm">
            View All
          </button>
        </Link>
      </div>
    </div>
  );
};
export default PNDashboard;