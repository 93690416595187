import React, { useState } from "react";
import { Category1, proceed } from "../asset/index";
import { useNavigate, Link } from "react-router-dom";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCallback } from "react";

const Customers = () => {
  const [tab, setTab] = useState("individual");
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()
  const handleBack = useCallback(() => navigate(-1), [navigate]);

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/agents/me/customers`, {
          headers: {
            token: localStorage.getItem('token'),
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        if (data.status === "success") {
          setCustomers(data.data.items);
        } else {
          toast.error("Failed to fetch customer data:", data.message);
        }
      } catch (error) {
        toast.error("Network error while fetching customer data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, [energyUrl]);

  const handleTabChange = (selectedTab) => {
    setTab(selectedTab);
  };


  const filterCustomers = () => {
    return customers.filter((customer) => customer.role === tab);
  };

  return (
    <div className="bg-[#F7F8FA]">
      <ToastContainer />
      <div className="flex pt-[10%] justify-center gap-[40%] items-center">
        <div className="flex gap-2">
          <img src={Category1} alt="CategoryImg" className="w-[14%] h-[14%]" />
          <h2 className="font-bold">Customers</h2>
        </div>

        <img src={proceed} alt="logoImg" className="h-6" onClick={handleBack} />

      </div>

      <div className="p-6 pb-24">
        <div className="max-w-md mx-auto p-4 bg-white">
          <div className="flex justify-center gap-6 text-sm text-black p-3 ml-[6%] mr-[6%] cursor-pointer">
            <div
              className={`text-sm text-black pl-2 pr-2 pt-1 pb-1 ${tab === "individual" ? "hover:text-blue-700" : ""}`}
              onClick={() => handleTabChange("individual")}
            >
              Individuals
            </div>
            <div
              className={`text-sm text-black pl-2 pr-2 pt-1 pb-1 ${tab === "business" ? "hover:text-blue-700" : ""}`}
              onClick={() => handleTabChange("business")}
            >
              Business
            </div>
          </div>

          <div className="mt-4">

            {filterCustomers().map((customer) => (
             <Link to = {`/customerUnitProfile/${customer.id}`} key = {customer.id} >
                <div className="flex justify-between items-center mb-4 border-t pt-2 pb-2">
                  <div className="flex items-center">
                    <span
                      className={`h-4 w-4 border rounded-full bg-${customer.status === "active"
                        ? "green"
                        : customer.status === "pending approval"
                          ? "red"
                          : "blue"
                        }-500`}
                    ></span>
                    <div>
                      <h2 className="text-[8px]">RW Number: {customer.serial_number}</h2>
                      {customer.role === "individual" ? (
                        <h2 className="text-xs font-bold">{customer.lastname}, {customer.firstname}</h2>
                      ) : (
                        <h2 className="text-xs font-bold">{customer.company_name}</h2>
                      )}
                    </div>
                  </div>
                  <button
                    className={`text-${customer.status === "active"
                      ? "green"
                      : customer.status === "pending approval"
                        ? "red"
                        : "yellow"
                      }-600 font-bold text-[10px] bg-${customer.status === "active"
                        ? "green"
                        : customer.status === "pending approval"
                          ? "red"
                          : "yellow"
                      }-50 w-fit p-2 h-10 border rounded-xl`}
                  >
                    {customer.status}
                  </button>
                </div>
                </Link>
            
            ))}
          </div>
          {/* <button className="w-full mt-[10%] mb-[40%] pb-4 text-black text-sm">View All {tab === "" ? "" : ""}</button> */}
        </div>
      </div>
    </div>
  );

}
export default Customers;