import React, { useState, useEffect } from 'react'
import { messaging, phoning } from '../../assetPicknDrop';
import { userReg } from '../../asset';
import { StarRate } from '@mui/icons-material';
import Map from '../../components/map/Map';
import { Link } from 'react-router-dom';

const ArrivedRider = () => {
    const [userLocation, setUserLocation] = useState(null)
    const [riderLocation, setRiderLocation] = useState({ lat: 0, lng: 0 })

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setUserLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
            },
            (error) => console.error('Error getting user location:', error)
        )
    }, []);

    useEffect(() => {
        const updateLocationInterval = setInterval(() => {
            setRiderLocation({
                lat: riderLocation.lat + 0.02,
                lng: riderLocation.lng + 0.02
            })
        }, 1000)
        return () => clearInterval(updateLocationInterval)
    }, [riderLocation])


    return (
        <div>
            <div>
                <div className='ml-[0%] w-full h-[40vh]'>
                    <Map riderLocation={userLocation} />
                </div>
                <div className='bg-[#FCF2EB] flex items-center justify-between p-4 pl-5 pr-5 text-black rounded-xl'>
                    <div>
                        <p className='text-sm font-bold'>Arrived</p>
                        <p className='font-thin text-[10px]'>Your delivery is here!</p>
                    </div>


                    <div className='bg-blue-500 h-15 pl-[10%] pr-[10%] p-4 flex items-center justify-center rounded-full text-white' >
                        <Link to='/modalCode'>
                            <p className='text-[10px]'>Confirm Delivery</p>
                        </Link>
                    </div>

                </div>
                <div className='flex p-3 justify-between items-center gap-[20%] ml-0 border-b'>
                    <div className='flex'>
                        <div>
                            <img src={userReg} alt='picImg' className='w-8 h-8 border rounded-full' /></div>
                        <div className='flex flex-col'>
                            <p className='text-xs font-bold'>Emmanuel Jude</p>
                            <p className='text-[11px] text-gray-300'>ABC12345 {'\u00a0'}<span className='font-semibold text-gray-700'>4.80 <StarRate className='text-yellow-500 -mt-1' style={{ fontSize: '120%' }} /></span></p>
                        </div>
                    </div>
                    <div className='flex w-[5%] gap-4 mr-[14%]'>
                        <img src={phoning} alt='phoneImg' />
                        <img src={messaging} alt='messageImg' />
                    </div>
                </div>


            </div>
        </div>
    )
}

export default ArrivedRider