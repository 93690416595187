import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { proceed } from "../../asset";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MeterForm = () => {
  const { id } = useParams()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [unitGroups, setUnitGroups] = useState([]);
  const [meterTypes, setMeterTypes] = useState([]);
  const handleBack = useCallback(() => navigate(-1), [navigate]);
  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  const [formData, setFormData] = useState({
    unitgroup_id: "",
    tag_name: "",
    meter_type: "",
    meter_number: "",
    sim_number: "",
    sim_serial: "",
  });

  useEffect(() => {
    const fetchUnitGroups = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${energyUrl}/agents/me/unitgroups`,
          {
            method: "GET",
            headers: {
              token: localStorage.getItem('token'),
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setUnitGroups(data.data.items);
        } else {
          throw new Error("Failed to fetch unit groups")
        }
      } catch (error) {
        console.error("Error fetching unit groups:", error.message);
      }
    };

    const fetchMeterTypes = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${energyUrl}/meter_types`,
          {
            method: "GET",
            headers: {
              token: localStorage.getItem('token'),
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setMeterTypes(data.data.items);
        } else {
          throw new Error("Failed to fetch meter types")
        }
      } catch (error) {
        toast.error("Error fetching meter types:", error.message);

      } finally {
        setLoading(false);
      }
    };

    fetchUnitGroups()
    fetchMeterTypes()
  }, [energyUrl]);


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const response = await fetch(`${energyUrl}/agents/me/customers/${id}/meters`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to create meter");
      }


      const data = await response.json();
      toast.success(data.message)

      setTimeout(() => {
        navigate(`/customerUnitProfile/${id}`);
      }, 2000)

    } catch (error) {
      toast.error("Error Creating Meter:" + error.message);

    } finally {
      setLoading(false);
    }
  };

  const handleValueChange = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));

  };

  if (loading) {
    return <div className="flex justify-center items-center text-xs mt-8 text-green-600">Loading...</div>;
  }

  return (
    <form className="max-w-md mx-auto p-6" onSubmit={handleFormSubmit}>
      <ToastContainer />

      <div className="flex pt-4 justify-end border-b  pb-[6%] items-center">
        <img src={proceed} alt="logoImg" className="h-6 cursor-pointer" onClick={handleBack} />
        <h2 className="max-w-md mx-auto p-4 font-medium">Add New Meter</h2>
      </div>

      <div className="bg-[#f8ecd1] mt-8 pb-6 border rounded-sm">

        <div className="mt-4">
          <label htmlFor="unitgroup_id" className="block text-[#7F9395] text-bold text-sm">
            Assign Unit Group
          </label>
          <select
            name="unitgroup_id"
            onChange={(e) => handleValueChange("unitgroup_id", e.target.value)}
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
          >
            <option value="">Select unit group</option>
            {unitGroups.map((group, idx) => (
              <option key={idx} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
        </div>
      </div>


      <div className="mb-4 mt-4">
        <label className="text-[#7F9395] text-bold text-sm">Name Tag</label>
        <div className="relative">
          <input
            type="text"
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter tag name"
            onChange={(e) => handleValueChange("tag_name", e.target.value)}
          />
        </div>
      </div>


      <div className="mt-4">
        <label htmlFor="meter_type" className="block text-[#7F9395] text-bold text-sm">
          Meter Type
        </label>
        <select
          name="meter_type"
          onChange={(e) => handleValueChange("meter_type", e.target.value)}
          className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
        >
          <option value="">Select Meter Type</option>
          {meterTypes.map((type, idx) => (
            <option key={idx} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>


      <div className="mb-4 mt-4">
        <label className="text-[#7F9395] text-bold text-sm">Assign Meter</label>
        <div className="relative">
          <input
            type="text"
            onChange={(e) => handleValueChange("meter_number", e.target.value)}
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter Meter Number" />
        </div>
      </div>

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm">SIM Number</label>
        <div className="relative">
          <input
            type="text"
            onChange={(e) => handleValueChange("sim_number", e.target.value)}
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter SIM Number" />
        </div>
      </div>

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm">SIM Serial</label>
        <div className="relative">
          <input
            type="text"
            onChange={(e) => handleValueChange("sim_serial", e.target.value)}
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter SIM Serial" />
        </div>
      </div>

      <button
        className="w-full mt-[10%] mb-[30%] pt-4 pb-4 hover:text-[#1F6FE2] hover:bg-white text-white bg-[#1F6FE2] border border-[#7F9395] text-sm font-bold rounded-md">
        {loading ? "Adding new meter..." : "Add"}
      </button>
    </form>
  )
}

export default MeterForm