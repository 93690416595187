import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { toast } from "react-toastify";


const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

export const useLogin = () => {  
    const [error, setError] = useState(null)  
    const [isLoading, setIsLoading] = useState(null)  
    const { dispatch} = useAuthContext()  

    const login = async (email, password) => {  
        setIsLoading(true)  
        setError(null)  

        try {  
            const response = await fetch(`${energyUrl}/auth/login`, {    
                method: 'POST',  
                headers: {   
                    'Content-Type': 'application/json'  
                },   
                body: JSON.stringify({ email, password })  
            })   

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const json = await response.json()
            if (json) {
                localStorage.setItem('user', JSON.stringify(json.data));
                localStorage.setItem("token", json.token);
                dispatch({ type: "LOGIN", payload: { user: json.data, token: json.token } });
                return json; 
            }
        } catch (error) {
            toast.error('Login failed, please try again');
            throw new Error('Login failed, please try Again');
        } finally {
            setIsLoading(false);
        }
    }

    return { login, isLoading };
};