
import React from "react";
import { Spin } from "antd";
import { Rgas } from "../asset";

const LoadingScreen = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-red-500">
        <img src={Rgas} alt="gasImg" className="h-16" />
      </div>
      <Spin size="default" />

    </div>
  );
};

export default LoadingScreen;
