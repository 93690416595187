import React, { useState, useEffect } from 'react'
import { indicator, locating, messaging, phoning, record } from '../../assetPicknDrop';
import { userReg } from '../../asset';
import { StarRate } from '@mui/icons-material';
import Map from '../../components/map/Map';
import { useNavigate } from 'react-router-dom';

const DispatchRider = () => {
    const navigate = useNavigate()
    const [moveToSplashTwo, setMoveToSplashTwo] = useState(false)
    const [userLocation, setUserLocation] = useState(null)
    const [riderLocation, setRiderLocation] = useState({ lat: 0, lng: 0 })

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setUserLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
            },
            (error) => console.error('Error getting user location:', error)
        )
    }, []);

    useEffect(() => {
        const updateLocationInterval = setInterval(() => {
            setRiderLocation({
                lat: riderLocation.lat + 0.02,
                lng: riderLocation.lng + 0.02
            })
        }, 1000)
        return () => clearInterval(updateLocationInterval)
    }, [riderLocation])

    useEffect(() => {
        const stayTime = setTimeout(() => {
            setMoveToSplashTwo(true)
            navigate('/arrivedRider')
        }, 4000)
        return () => clearTimeout(stayTime)
    }, [navigate])

    if (moveToSplashTwo) {
        return null
    }

    return (
        <div>
            <div>
                <div className='ml-[0%] w-full h-[40vh]'>
                    <Map riderLocation={userLocation} />
                </div>
                <div className='bg-[#f7b8b4] flex items-center justify-between p-3 pl-5 pr-5 text-black rounded-xl'>
                    <div>
                        <p className='text-sm font-bold'>Driver is on his way to you</p>
                        <p className='font-semibold text-[10px]'>Hold on a bit, driver will meet you shortly</p>
                    </div>
                    <div className='bg-blue-500 h-15 w-[20%] flex flex-col items-center justify-center rounded-md text-white' >
                        <p className='text-[10px]'>ETA</p>
                        <p className='font-semibold text-2xl'>10</p>
                        <p className='font-semibold text-[10px]'>Min</p>
                    </div>
                </div>
                <div className='flex p-3 justify-between items-center gap-[20%] ml-0 border-b z-auto'>
                    <div className='flex gap-2'>
                        <div>
                            <img src={userReg} alt='picImg' className='w-8 h-8 border rounded-full' /></div>
                        <div className='flex flex-col'>
                            <p className='text-xs font-bold'>Emmanuel Jude</p>
                            <p className='text-[11px] text-gray-300'>ABC12345 {'\u00a0'}<span className='font-semibold text-gray-700'>4.80 <StarRate className='text-yellow-500 -mt-1' style={{ fontSize: '120%' }} /></span></p>
                        </div>
                    </div>
                    <div className='flex w-[5%] gap-4 mr-[14%]'>
                        <img src={phoning} alt='phoneImg' />
                        <img src={messaging} alt='messageImg' />
                    </div>
                </div>

                <div className='grid text-xs p-3 ml-[6%] mr-[6%] fixed border-b z-auto'>
                    <div className='flex flex-col p-3  rounded-md'>
                        <div className='flex gap-2  -ml-[13%]'>
                            <div>
                                <img src={record} alt='recordImg' className='w-4 h-4' />
                                <div className='ml-[40%]'>
                                    <div>|</div>
                                    <div>|</div>
                                    <div>|</div>
                                </div>
                            </div>

                            <p className='font-semibold text-xs text-[#33ccff]'>Sender</p>
                        </div>

                        <p className='font-semibold -mt-[26%]'>23 Ring road, Ikeja, Lagos State</p>
                        <p className='text-[11px] flex'> Rokswood Gas  {'\u00a0'}<img src={indicator} alt='pixImg' className='w-2 h-2 mt-1' />{'\u00a0'}<span className='font-semibold'>07000000000</span></p>
                    </div>

                    <div className='flex flex-col p-3  rounded-md'>
                        <div className='flex gap-2  -ml-[13%]'>
                            <img src={locating} alt='recordImg' className='w-4 h-4' />
                            <p className='font-semibold text-xs text-[#33ccff]'>Recipient</p>
                        </div>

                        <p className='font-semibold'>30 Ring road, Ikeja, Lagos State</p>
                        <p className='text-[11px] flex'> John Doe  {'\u00a0'}<img src={indicator} alt='pixImg' className='w-2 h-2 mt-1' />{'\u00a0'} <span className='font-semibold'>07000000000</span></p>
                    </div>

                    <div className='flex flex-col p-3  rounded-md'>
                        <p className='font-semibold text-xs text-[#33ccff]'>Item</p>
                        <p className='font-semibold'>3KG Cooking Gas</p>
                        <p className='text-[11px]'> Quantity{'\u00a0'}<span className='font-semibold'>1</span></p>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default DispatchRider