import React, { useEffect, useState } from "react";
import { MdOutlineMoreVert } from "react-icons/md";
import { proceed } from "../asset";
import { Link } from "react-router-dom";
import GroupUnitOrderVolume from "../components/GroupUnitOrderVolume";
import { useAuthContext } from "../hook/useAuthContext";

const GroupUnit = ({ shouldUpdate }) => {
  const { user } = useAuthContext()
  const [unitGroups, setUnitGroups] = useState([])
  const [dashboardStats, setDashboardStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  useEffect(() => {
    const fetchUnitGroups = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/agents/me/unitgroups?detailed=true&page=${currentPage}&pageSize=${itemsPerPage}`,
          {
            method: "GET",
            headers: {
              token: localStorage.getItem('token'),
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch unit groups");
        }
        const data = await response.json();
        setUnitGroups(data.data.items);

      } catch (error) {

      } finally {
        setLoading(false);
      }
    };

    const fetchDashboardStats = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/stats/agent_dashboard`,
          {
            method: "GET",
            headers: {
              token: localStorage.getItem('token'),
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch dashboard stats");
        }

        const data = await response.json();
        setDashboardStats(data.data);
      } catch (error) {

      } finally {
        setLoading(false);
      }
    };

    if (shouldUpdate) {
      fetchUnitGroups()
      fetchDashboardStats();
    }

    fetchUnitGroups();
    fetchDashboardStats();
  }, [user.token, shouldUpdate, currentPage, energyUrl]);

  const totalCustomers = dashboardStats ? dashboardStats.customers_total : 0;
  const totalPages = Math.ceil(unitGroups.length / itemsPerPage);

  const displayedUnitGroups = unitGroups.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="bg-[#F7F8FA] ">
      <div className="flex pt-[10%] justify-center gap-[42%] pb-[6%]">
        <h2 className="font-bold">Group Unit</h2>
        <Link to='/addUnitGroup'>
          <img src={proceed} alt="logoImg" className="h-6 " />
        </Link>
      </div>

      <div className="flex justify-center items-center gap-20 font-bold text-xs">
        <div className="border border-l border-r  text-black border-[#fbc8c5] p-4 rounded-lg bg-[#fbc8c5]">
          <h2>{dashboardStats ? dashboardStats.total_unitgroups : 0}</h2>
          <h2> Group Unit</h2>
        </div>

        <div className="border border-l border-r  text-white border-[#1F6FE2] p-4 rounded-lg bg-[#1F6FE2]">
          <h2>{totalCustomers}</h2>
          <h2> Total Customers</h2>
        </div>
      </div>

      <div className="p-6 pb-24">
        <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
          <div className="flex justify-between mb-[3%]">
            <h2 className="text-black font-semibold text-sm">Group Unit</h2>
            <MdOutlineMoreVert />
          </div>

          <div className="text-[8px] pb-4" >
            <div className="flex flex-col">
              {displayedUnitGroups.map(unitGroup => (
                <Link key={unitGroup.id} to={`/groupUnitGroup/${unitGroup.id}`}>
                  <GroupUnitOrderVolume data={[unitGroup]} />
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-[10%]">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`mx-1 px-3 py-1 ${currentPage === index + 1 ? "bg-gray-300" : "bg-gray-100"
                }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GroupUnit;