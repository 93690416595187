import React, { useState, useCallback, useEffect } from 'react'
import { Rgas, proceed } from '../asset';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RevampInvoice from '../pages/RevampInvoice';


const ReviewOrder = () => {

  const location = useLocation();
  const { gasVolume, totalAmount, meterNumbers, authUrl, amount, customerName,
    reference, orderId, customerAddress, serviceCharge, created, vat, totalProductPrice } = location.state;
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('Card');
  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  const handleBack = useCallback(() => navigate(-1), [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${energyUrl}/users/me?detailed=true`,
          {
            headers: {
              token: localStorage.getItem('token'),
              "Content-Type": "application/json"
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setUserData(data.data);
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("An error occurred while fetching user data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [energyUrl]);



  const handlePaymentVerification = async (reference) => {
    try {
      const response = await fetch(
        `${energyUrl}/transactions/verify/${reference}`
      );

      if (response.ok) {
        const data = await response.json();
        if (data.status === "success" && data.data.verified) {
          toast.success("Payment verification successful");
        } else {
          toast.error("Payment verification failed");
        }
      } else {
        toast.error("Failed to verify payment");
      }
    } catch (error) {
      toast.error("An error occurred while verifying payment");
    }
  };

  const handlePaymentProceed = async () => {
    setLoading(true);

    try {
      if (paymentMethod === 'Card') {
        const response = await fetch(`${energyUrl}/transactions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            token: localStorage.getItem('token'),
          },
          body: JSON.stringify({
            callback_url: `${window.location.origin}/buyToken`,
            order_id: orderId,
            payment_method: 'paystack',
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status) {
            window.location.replace(data.data.auth_url);

          } else {
            toast.error(data.message || 'Failed to initiate payment');
          }
        } else {
          toast.error('Failed to initiate payment');
        }
      } else if (paymentMethod === 'Bank Transfer') {
        navigate('/fund', {
          state: { gasVolume, totalAmount, meterNumbers, created, reference, amount, customerName },
        });
      }
    } catch (error) {
      toast.error('An error occurred while processing payment');
    } finally {
      setLoading(false);
    }
  };


  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat().format(number);
  };

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return '';

    const date = new Date(dateTimeString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  if (loading) {
    return <div className="flex justify-center items-center text-xs mt-8 text-green-600">Loading...</div>;
  }

  return (
    <div className='bg-[#F7F8FA] h-[100vh] mb-20'>
      <div className='flex items-center justify-between h-10 pb-10 pt-10 rounded-3xl ml-[6%] mr-[6%]'>
        <p className='text-xs font-bold'>Review Order</p>
        <img src={Rgas} alt='LogoImg' className='w-4 h-6' />
      </div>
      <hr className='bg-black mt-2' />
      <div className='flex items-center justify-between h-10 mt-2 rounded-3xl ml-[6%] mr-[6%]'>
        <p className='text-xs font-bold'>Details</p>
        <img src={proceed} alt='LogoImg' className='w-12 h-6 cursor-pointer' onClick={handleBack} />
      </div>

      <p className='text-xs ml-[6%] mt-2 text-gray-500'>Please confirm your order before payment. Thank you</p>


      <div className='bg-white mt-8 pb-10 ml-[6%] mr-[6%] rounded-xl'>
        <div className='grid grid-cols-2 gap-[10%] text-xs p-3'>
          <div className='flex flex-col p-3'>
            <p className='font-semibold text-gray-500'>Meter Number</p>
            <p className='font-bold'>{meterNumbers}</p>
          </div>

          <div className='flex flex-col p-3'>
            <p className='font-semibold text-xs text-gray-500'>Meter Name</p>
            <p className='font-bold flex'> {customerName}</p>
          </div>
        </div>

        <div className='flex flex-col text-xs -translate-y-5 p-6'>
          <p className='font-semibold text-xs text-gray-500'>Meter Address</p>
          <p className='font-bold'>{customerAddress}</p>
        </div>


        <div className='flex justify-between items-center -translate-y-10 text-xs p-6 text-gray-500'>
          <p className='font-semibold'>Payment Breakdown</p>
          <p className='font-semibold'>{formatDateTime(created)}</p>
        </div>

        <div>
          <div className='bg-[#D7DBDD] flex items-center -mt-10 justify-between ml-[6%] mr-[6%] p-3 pl-5 pr-5 text-black rounded-xl'>
            <div>
              <p className='text-[10px]'>Gas (kg)</p>
              <p className='font-semibold text-xl'>{gasVolume}KG</p>
            </div>
            <div style={{ backgroundColor: "#ccc", width: 2, height: "80%" }} />
          </div>

          <div className='bg-[#D7DBDD] flex items-center mt-2 justify-between ml-[6%] mr-[6%] p-3 pl-5 pr-5 text-black rounded-xl'>
            <div>
              <p className='text-[10px]'>Gas (total)</p>
              <p className='font-semibold text-xl'>₦{formatNumber(totalProductPrice)}</p>
            </div>
            <div style={{ backgroundColor: "#ccc", width: 2, height: "80%" }} />
          </div>

          {/* <div className='bg-[#D7DBDD] flex items-center mt-2 justify-between ml-[6%] mr-[6%] p-3 pl-5 pr-5 text-black rounded-xl'>
            <div>
              <p className='text-[10px] text-gray-100'>VAT</p>
              <p className='font-semibold text-gray-100 text-xl'>₦{formatNumber(vat)}</p>
            </div>
            <div style={{ width: 2, height: "80%" }} className='bg-gray-100 bg-opacity-40' />
          </div> */}

          <div className='bg-[#D7DBDD] flex items-center mt-2 justify-between ml-[6%] mr-[6%] p-3 pl-5 pr-5 text-black rounded-xl'>
            <div>
              <p className='text-[10px]'>Service Charge</p>
              <p className='font-semibold text-xl'>₦{formatNumber(serviceCharge)}</p>
            </div>
            <div style={{ backgroundColor: "#ccc", width: 2, height: "80%" }} />
          </div>

          <div className='bg-[#3361ff] flex items-center justify-between mt-2 ml-[6%] mr-[6%] p-3 pl-5 pr-5 text-white rounded-xl'>
            <div>
              <p className='text-[10px]'>Total</p>
              <p className='font-semibold text-xl'>₦{formatNumber(totalAmount)}</p>
            </div>
            <div style={{ backgroundColor: "#ccc", width: 2, height: "80%" }} />
          </div>
        </div>
      </div>

      <div className='flex flex-col p-3 ml-[6%] mr-[6%] rounded-md'>
        <p className='font-semibold text-xs mt-4'>Pay with</p>
        <div className='font-semibold mt-4'>
          <div className='p-4 rounded-md text-[15px] text-gray-800 hover:text-blue-500'>
            <input type='radio' id='card' name='paymentMethod' value='Card' checked={paymentMethod === 'Card'} onChange={handlePaymentMethodChange} />
            <label htmlFor='card'> {'\u00a0'}Card</label>
          </div>

          {/* <div className='p-4 rounded-md text-[15px] text-gray-800 hover:text-blue-500'>
            <input type='radio' id='bankTransfer' name='paymentMethod' value='Bank Transfer' checked={paymentMethod === 'Bank Transfer'} onChange={handlePaymentMethodChange} />
            <label htmlFor='payOnDelivery'> {'\u00a0'}Bank Transfer</label>
          </div> */}
        </div>

        <div
          onClick={handlePaymentProceed}
          className='bg-[#3361ff] flex items-center justify-center cursor-pointer mt-10 mb-10 p-5 pl-5 pr-5 text-white rounded-xl'>
          {loading ? "Proceeding..." : "Proceed to Payment"}
        </div>
      </div>



      <ToastContainer />

    </div>
  )
}

export default ReviewOrder