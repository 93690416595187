import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../hook/useAuthContext";
import { useNavigate } from "react-router-dom";
import { down, flag } from "../../asset";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const IndividualForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [genderTypes, setGenderTypes] = useState([]);
  const { user } = useAuthContext()
    // const [unitGroupsIndividual, setUnitGroupsIndividual] = useState([]);
  // const [meterTypes, setMeterTypes] = useState([]);
  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  const [formData, setFormData] = useState({
    address: "",    
    country: "",
    dob: "",
    email: "",
    firstname: "",
    gender: "",
    lastname: "",
    phone: "",
    state: ""
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (formData.email.trim() !== '' && !emailRegex.test(formData.email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    try {
      setLoading(true);

      const response = await fetch(`${energyUrl}/agents/me/customers/individuals`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to create individual");
      }

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message)

        setTimeout(() => {
          navigate('/congratulation');
        }, 2000)

      } else {
        toast.error("Failed to submit the form")
      }     

    } catch (error) {
      toast.error("Error Creating Individual:", error.message);

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // const fetchUnitGroups = async (tab) => {
    //   try {
    //     const response = await fetch(
    //       `${energyUrl}/agents/me/unitgroups`,
    //       {
    //         method: "GET",
    //         headers: {
    //           token: localStorage.getItem('token'),
    //           "Content-Type": "application/json",
    //         }
    //       }
    //     )

    //     if (!response.ok) {
    //       throw new Error("Failed to fetch unit groups");
    //     }

    //     const data = await response.json();

    //     if (tab === "individual") {
    //       setUnitGroupsIndividual(data.data.items);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching unit groups:", error.message);
    //   }
    // };
    // const fetchMeterTypes = async () => {
    //   try {
    //     const response = await fetch(
    //       `${energyUrl}/meter_types`,
    //       {
    //         method: "GET",
    //         headers: {
    //           token: localStorage.getItem('token'),
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );

    //     if (!response.ok) {
    //       throw new Error("Failed to fetch meter types");
    //     }

    //     const data = await response.json();
    //     setMeterTypes(data.data.items);
    //   } catch (error) {
    //     console.error("Error fetching meter types:", error.message);
    //   }
    // };

    const fetchGenderTypes = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/genders`,
          {
            method: "GET",
            headers: {
              token: localStorage.getItem('token'),
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch meter types");
        }

        const data = await response.json();
        setGenderTypes(data.data.items);
      } catch (error) {
        console.error("Error fetching meter types:", error.message);
      }
    };

    // fetchUnitGroups("individual");
    // fetchMeterTypes();
    fetchGenderTypes()
  }, [energyUrl, user.token]);

 
  const handleValueChange = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));

    };

  return (
    <form className="max-w-md mx-auto p-6" onSubmit={handleFormSubmit}>
     <ToastContainer />
     

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm">Surname</label>
        <div className="relative">
          <input
            type="text"
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter Surname"
            onChange={(e) => handleValueChange("lastname", e.target.value)}
         />
        </div>
      </div>

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm">
          First Name
        </label>
        <div className="relative">
          <input
            type="text"
            onChange={(e) => handleValueChange("firstname", e.target.value)}
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter First name"
          />
        </div>
      </div>

      <div className="mt-4">
        <label htmlFor="gender" className="block text-[#7F9395] text-bold text-sm">
          Gender
        </label>
        <select
          type="text"
          name="gender"
          onChange={(e) => handleValueChange("gender", e.target.value)}
          className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
        >
          <option value="">Select Gender</option>
          {genderTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-4 mb-4">
        <label htmlFor="dob" className="text-[#7F9395] text-bold text-sm">
          Date of Birth
        </label>
        <input
          type="date"
          id="dob"
          name="dob"
          onChange={(e) => handleValueChange("dob", e.target.value)}
          className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
        />
      </div>

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm">Email</label>
        <div className="relative">
          <input
            type="email"
            onChange={(e) => handleValueChange("email", e.target.value)}
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter email address" />
        </div>
      </div>

      <div className="mt-4 mb-4">
        <label className="text-[#7F9395] text-bold text-sm">Phone Number</label>
        <div className="relative w-full flex items-center border rounded-md focus:outline-none focus:border-[#1F6FE2]">
          <img src={flag} alt="flagImg" className="ml-4 mr-1 w-8 h-5" />
          <img src={down} alt="flagImg" className="mr-2 w-3 h-3" />

          <input
            type="text"
            className=" w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="+234 803 000 0000"
            onChange={(e) => handleValueChange("phone", e.target.value)}
          />
        </div>
      </div>

      <div className="mt-4">
        <label htmlFor="country" className="block text-[#7F9395] text-bold text-sm">
          Country
        </label>
        <select
          type="text"
          name="country"
          onChange={(e) => handleValueChange("country", e.target.value)}
          className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
        >

          <option value="Nigeria">Nigeria</option>
          <option value="Ghana">Ghana</option>
          <option value="Namibia">Namibia</option>
          <option value="Namibia">Namibia</option>
          <option value="Canada">Canada</option>
          <option value="USA">USA</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm">State</label>
        <div className="relative">
          <input
            type="text"
            onChange={(e) => handleValueChange("state", e.target.value)}
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter State"
          />
        </div>
      </div>     

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm">
          Residential Address
        </label>
        <div className="relative">
          <textarea
            type="message"
            onChange={(e) => handleValueChange("address", e.target.value)}
            className="w-full h-40 bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter Address" />
        </div>
      </div>
      <button 
        
        className="w-full mt-[10%] mb-[30%] pt-4 pb-4 hover:text-[#1F6FE2] hover:bg-white text-white bg-[#1F6FE2] border border-[#7F9395] text-sm font-bold rounded-md">
         {loading ? "Creating Individual..." : "Create Individual"}
      </button>
    </form>
  )
}

export default IndividualForm