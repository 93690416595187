import React from "react";
import { Rgas, bToken, pnd } from "../asset/index";
import { Link } from "react-router-dom";
const SplashPage = () => {


  return (
    <div className="bg-[#F9F9F9] overflow-y-hidden">
      <div className="pt-[20%] justify-center items-center flex flex-col">
        <img src={Rgas} alt="logoImg" className="left-40 w-[12%] h-[14%]" />
        <div className="flex flex-col items-center mt-2 justify-center -space-y-4 font-bold text-[25px] text-[#c3cad9] ">
          <h2>Efficiency</h2>
          <h2>Meets</h2>
          <h2>Innovation</h2>
        </div>
        <div className="flex flex-col items-center justify-center text-[70%] mt-4 mb-10 text-[#504b4b]">
          <p>Unlock the Power</p>
          <p> of Digital Gas Solutions</p>
        </div>
      </div>

      <div className="text-[#c3cad9] pt-[33%] pb-[30%] bg-white" style={{ borderRadius: '2rem' }}>
        <div className="flex flex-col gap-2 mt-[5%] justify-center items-center">
          <div className="flex justify-center items-center -mt-[20%] space-x-8">
            <Link to="/buyToken">
              <div className="flex flex-col">
                <img src={bToken} alt="buyTokenImg" />
                <p className="flex justify-center items-center text-xs mt-2 text-black">Buy Gas </p>
              </div>
            </Link>

            <Link to="/pickAndDrop">
              <div className="flex flex-col">
                <img src={pnd} alt="pickNDropImg" />
                <p className="flex justify-center items-center text-xs mt-2 text-black">Pick & Drop </p>
              </div>
            </Link>
          </div>

          <Link to="/login">
            <button className="w-[100%] bg-[#1F6FE2] pt-4 pb-4 pl-[7rem] pr-[7rem] mt-[20%] mb-5 text-white text-xs font-bold rounded-sm">
              Login
            </button>
          </Link>
          <Link to="/support">
            <p className="text-sm text-black -mt-5 flex mb-10">Don't have an account? {'\u00a0'} <span className="underline text-blue-700 cursor-pointer">click here</span></p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SplashPage;