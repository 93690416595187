import React, { useState, useCallback, useEffect } from 'react'
import { proceed } from '../asset';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FundWallet = () => {
    const location = useLocation();
    const { gasVolume, totalAmount, meterNumbers, authUrl, reference, total_service_charge } = location.state || {}
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL
    const handleBack = useCallback(() => navigate(-1), [navigate]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(
                    `${energyUrl}/users/me?detailed=true`,
                    {
                        headers: {
                            token: localStorage.getItem('token'),
                            "Content-Type": "application/json"
                        },
                    }
                );
                if (response.ok) {
                    const data = await response.json();
                    setUserData(data.data);
                } else {
                    console.error("Failed to fetch user data");
                }
            } catch (error) {
                console.error("An error occurred while fetching user data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [energyUrl]);


    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handlePayment = async () => {
        setLoading(true)
        toast.info('Confirming your payment')

        await new Promise(resolve => setTimeout(resolve, 10000))

        setLoading(false);
        toast.success('Successful payment');
        navigate('/successful', { state: { gasVolume, totalAmount, meterNumbers, reference, total_service_charge } });

    };

    // useEffect(() => {
    //     if (!loading) return;
    //     const timeoutId = setTimeout(() => {
    //         navigate('/buyToken');
    //     }, 5000);

    //     return () => clearTimeout(timeoutId);
    // }, [loading, navigate]);

    return (
        <div className='bg-[#F7F8FA] h-[100vh]'>
            <div className='flex items-center justify-between h-10 mt-12 ml-[6%] mr-[6%]'>
                <p className='text-xs font-bold'>Pay with Bank Transfer</p>
                <img src={proceed} alt='LogoImg' className='w-12 h-6 cursor-pointer' onClick={handleBack} />
            </div>
            <div className='mt-4 pb-10 ml-[6%] mr-[6%] rounded-xl'>
                <p className='ml-[6%] mr-[6%] text-[10px]'>Amount</p>
                <div className='bg-[#F3F3F3] flex items-center justify-between ml-[6%] mr-[6%] p-4 pt-4 pb-4 pl-5 pr-5 text-black rounded-md'>
                    <div>
                        <p className='font-semibold text-xl'>{userData?.last_purcharse?.total ? `₦${numberWithCommas(userData?.last_purcharse?.total)}` : <span className='text-xs text-green-500'>Loading...</span>} </p>
                    </div>
                    <div style={{ backgroundColor: "#ccc", width: 2, height: "80%" }} />
                </div>
                <div>
                    <p className='ml-[6%] mr-[6%] text-[10px] mt-8'>Send to the account below</p>
                    <div className='bg-[#F3F3F3] grid items-center justify-between ml-[6%] mr-[6%] p-8 pl-5 pr-5 text-black rounded-md'>
                        <div>
                            <p className='text-xs'>Account Name</p>
                            <p className='text-sm font-bold'>Rokswood Group</p>
                        </div>
                        <div>
                            <p className='text-xs mt-4'>Bank</p>
                            <p className='text-sm font-bold'>GTB</p>
                        </div>
                        <div>
                            <p className='text-xs mt-4'>Account Number</p>
                            <p className='text-sm font-bold'>1234567890</p>
                        </div>
                        <div style={{ backgroundColor: "#ccc", width: 2, height: "80%" }} />
                    </div>
                </div>
            </div>

            <div className='flex flex-col p-3 ml-[6%] mr-[6%] rounded-md'>
                <div
                    onClick={handlePayment}
                    disabled={loading}
                    className='bg-[#3361ff] flex items-center justify-center cursor-pointer mt-10 mb-10 p-5 pl-5 pr-5 text-white rounded-xl'>
                    {loading ? "Confirming Payment..." : "Paid"}
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default FundWallet