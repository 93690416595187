import React, {useState, useEffect} from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

const Map = ({riderLocation}) => {
    const [map, setMap] = useState(null)

    useEffect(()=> {
        if (map && riderLocation){
            const newLatLng = new L.LatLng(riderLocation.lat, riderLocation.lng)
            map.flyTo(newLatLng, map.getZoom(), {duration: 1})
        }
    }, [riderLocation, map])
  return (
    <div id='map-container' style={{height: '40vh', width: '100%'}}>
        <MapContainer 
            center={[6.465422, 3.406448]} 
            zoom={13} 
            style={{height: '40vh', width: '100%'}}
            whenCreated={setMap}>
            <TileLayer
                url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribute='&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors'/>
                {riderLocation && <Marker position={[riderLocation.lat, riderLocation.lng]} />}
        </MapContainer>      
    </div>
  )
}

export default Map
