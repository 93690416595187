import React, { useState, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { proceed, userReg } from "../asset";

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleBack = useCallback(() => navigate(-1), [navigate]);
  const [emailError, setEmailError] = useState('');

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter your email');
      return;
    } else {
      setEmailError('');
    }

    try {
      setLoading(true);
      const response = await axios.post(`${energyUrl}/auth/reset`, {
        email,
      });

      await toast.success(response.data.message);
      setTimeout(() => {
        navigate('/changePassword');
      }, 2000);

    } catch (error) {
      await toast.error("Failed to reset password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="overflow-y-hidden bg-[#ffffff] p-4 mt-12 mb-12">

      <div className="flex pt-4 justify-center border-b gap-[40%] pb-[6%] items-center">
        <h2 className="max-w-md mx-auto mt-[2%] p-4 font-medium">Reset Password</h2>
        <img src={proceed} alt="logoImg" className="h-6" onClick={handleBack} />
      </div>

      <div className="max-w-md mx-auto mt-[10%] p-4 text-sm">

        <div className="mb-4 ">
          <label className="text-gray-700 text-bold">Email</label>
          <div className="relative">
            <input
              type="email"
              className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
              placeholder="enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className="absolute top-0 right-0 m-2">
              <img
                src={userReg} alt="personImg"
                className="h-4 w-3 text-black"
              />
            </div>
          </div>
          {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
        </div>

        <button
          type="submit"
          disabled={loading}
          onClick={handleSubmit}
          className="w-full mt-[20%] mb-[20%] pt-4 pb-4 text-[#1F6FE2] hover:bg-[#1F6FE2] hover:text-white border border-[#1F6FE2] text-sm font-bold rounded-md"
        >
          {loading ? "Resetting..." : "Reset Password"}
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};

export default PasswordReset;