import React, { useCallback, useState } from 'react'
import { Rgas, userReg } from '../../asset'
import { ArrowBackOutlined, StarRate } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import QRCode from 'qrcode.react';

const PickFeedback = () => {
  const [ratings, setRatings] = useState('')
  const navigate = useNavigate();
  const handleBack = useCallback(() => navigate(-1), [navigate]);

  const handleRatingClick = (newRating) => {
    if (newRating === ratings){
      setRatings('')
    } else {      
    setRatings(newRating);
    }
  };

  return (
    <div>
      <div className='flex items-center justify-between h-10 mt-8 rounded-3xl ml-[6%] mr-[6%]'>
        <ArrowBackOutlined className='border rounded-full p-1 cursor-pointer' onClick={handleBack} />
        <img src={Rgas} alt='LogoImg' className='w-4 h-6' />
      </div>
      <div>
        <div className='grid text-xs p-3'>
          <div className='flex p-3 justify-center items-center gap-2'>
            <img src={userReg} alt='picImg' className='w-8 h-8 border rounded-full' />
            <div className='flex flex-col'>
              <p className='text-xs font-bold'>Emmanuel Jude</p>
              <p className='text-[11px] text-gray-300'>ABC12345 {'\u00a0'}<span className='font-semibold text-gray-700'>4.80 <StarRate className='text-yellow-500 -mt-1' style={{ fontSize: '120%' }} /></span></p>
            </div>
          </div>

          <div className='flex flex-col p-3 items-center justify-center'>
            <p className='font-semibold text-xl text-[#1F6FE2] '>Package Delivered</p>
            <p className='font-semibold text-xl '>₦ 3,400</p>
          </div>



          <div className='flex flex-col p-3 justify-center items-center'>
            <QRCode value='' className="w-48 h-48 mt-12" />

            <div className='font-semibold mt-8'>
              <p className='font-semibold text-[11px]'>Give a rating to enhance the delivery experience</p>
              <div className='flex gap-4 justify-center items-center'>             

                {[...Array(5)].map((_, idx) => (
          <StarRate
            key={idx}
            className={idx < ratings ? 'text-yellow-500' : 'text-gray-200'}
            style={{ fontSize: '250%', cursor: 'pointer' }}
            onClick={() => handleRatingClick(idx + 1)} 
          />
        ))}
              </div>
            </div>
            <textarea
              input='text'
              className='w-full h-40 bg-gray-100 p-4 mt-6 rounded-md'
              placeholder='Message'>

            </textarea>
          </div>

          <Link to='/pLogin'>
            <button className="w-full mt-[15%] mb-[10%] pt-4   pb-4 text-[#ffffff] bg-[#1F6FE2]  border border-[#7F9395] text-sm font-bold rounded-md">
              Submit
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PickFeedback