import React from 'react';
import { BarChart, Bar, XAxis, Tooltip, Legend } from 'recharts';

const Barmy = ({ data }) => {
  return (
    <BarChart
      width={280}
      height={300}
      data={data}
      margin={{ top: 5, bottom: 5, }}
      barSize={12}
    >
      <XAxis dataKey="name" scale="point" padding={{ left: 16, right: 10 }} />
      <Tooltip />
      <Legend />
      <Bar dataKey="Revenue" fill="#e61c0d" background={{ fill: '#eee' }} />
    </BarChart>
  );
};

export default Barmy;