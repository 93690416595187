import React from "react";

const VolumeCustomerRemaining = ({ data }) => {
  return (
    <div>
      {data.map((item, index) => (
        <div key={index} className="flex items-center justify-between text-xs mb-[15%] border-t border-white border-spacing-4 pt-[7%] ">
          <div className="relative w-[50%] h-2 bg-gray-200 rounded-full">
            <div
              className={`absolute h-full rounded-full ${item.percentage < 30 ? "bg-red-500" : "bg-green-500"
                }`}
              style={{
                width: `${item.percentage}%`,
              }}
            />
          </div>
          <span className="text-blue-500">{`${item.percentage}%`}</span>
        </div>
      ))}
    </div>
  );
};

export default VolumeCustomerRemaining;