import React, { useEffect, useState, useCallback } from "react";
import { proceed } from "../asset";
import { Link, useNavigate } from "react-router-dom";
import { useLogout } from "../hook/useLogout";
import CustomerProfile from "./profile/CustomerProfile";

const Profile = () => {
  const { logout } = useLogout()
  const [userDetails, setUserDetails] = useState(null)
  const navigate = useNavigate()
  const handleBack = useCallback(() => navigate(-1), [navigate]);

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/users/me?detailed=true`, {
          headers: {
            token: localStorage.getItem('token'),
            "Content-Type": "application/json",
          },
        }
        )

        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const data = await response.json();
        setUserDetails(data.data);
      } catch (error) {
      }
    };

    fetchUserDetails();
  }, [energyUrl]);

  const handleLogout = () => {
    logout()
  }

  if (!userDetails) {
    return <div className="flex justify-center items-center text-xs mt text-green-600">Loading...</div>;
  }

  return (
    <div className="bg-[#F7F8FA] ">
      <div className="flex pt-[10%] justify-center gap-[56%] items-center">
        <h2 className="font-bold">Profile</h2>
        <img src={proceed} alt="logoImg" className="h-6 cursor-pointer" onClick={handleBack} />
      </div>

      <div className="p-6 pb-24">
        {userDetails && userDetails.role === 'agent' ? (
          <div className="max-w-md mx-auto rounded-lg p-4 bg-white">
            <div className="flex justify-between mx-auto px-4 mt-[10%] text-xs">
              {userDetails.image && (
                <img src={userDetails.image} alt="userImg" className=" rounded-full border-black h-12 w-12" />
              )}

              <div>
                <h2 className="pl-2">Agent Name</h2>
                <h2 className="font-bold pl-2">{userDetails.firstname} {userDetails.lastname}</h2>
              </div>
              <div>
                <h2>Company</h2>
                <h2 className="font-bold">{userDetails.company_name}</h2>
              </div>
            </div>

            <div className="w-full h-[1px] bg-[#7F9395] mt-[6%]"></div>

            <div className="mt-[2%] flex text-[10px] gap-auto max-w-md mx-auto p-4 items-center justify-between">
              <div>
                <h2>Gender</h2>
                <h2 className="font-bold">{userDetails.gender}</h2>
              </div>

              <div>
                <h2>Date of Birth</h2>
                <h2 className="font-bold">{userDetails?.dob}</h2>
              </div>

              <div>
                <h2>State</h2>
                <h2 className="font-bold">{userDetails.state}</h2>
              </div>

              <div>
                <h2>Country</h2>
                <h2 className="font-bold">{userDetails.country}</h2>
              </div>
            </div>

            <div className="grid grid-cols-2 -mt-[6%] text-[10px] gap-4 max-w-md mx-auto p-4 items-center">
              <div>
                <h2>Phone Number</h2>
                <h2 className="font-bold">{userDetails.phone}</h2>
              </div>

              <div>
                <h2>Email</h2>
                <h2 className="font-bold">
                  <span>{userDetails.email}</span>
                </h2>
              </div>
            </div>

            <div className="grid grid-cols-1 -mt-[6%] text-[10px] gap-4 max-w-md mx-auto p-4 items-center">
              <div>
                <h2>Bank Information</h2>
                <h2 className="font-bold">
                  <span>{userDetails.bank_information}</span>
                </h2>
              </div>
            </div>

            <div className="grid grid-cols-1 -mt-[6%] text-[10px] gap-4 max-w-md mx-auto p-4 items-center">
              <div>
                <h2>Address</h2>
                <h2 className="font-bold">
                  <span>
                    {userDetails.address}
                  </span>
                </h2>
              </div>
            </div>

            <div>
              <Link to="/splash">
                <button
                  className="w-full mt-[40%] mb-[10%] pt-4 pb-4 text-white hover:text-[#e21f1f] hover:bg-white bg-[#e21f1f] border border-[#7F9395] text-sm font-bold rounded-md"
                  onClick={handleLogout}>
                  Log out
                </button>
              </Link>
            </div>

            <button className="w-full pt-4 pb-4 text-white bg-[#9fbce6] border border-[#7F9395] text-sm font-bold rounded-md">
              Edit Profile
            </button>
          </div>
        ) : (userDetails && userDetails.role === 'individual') || (userDetails && userDetails.role === 'business') ? (
          <CustomerProfile />
        ) : null}
      </div>
    </div>
  );
};

export default Profile;