import React, {useCallback, useState, useEffect} from 'react'
import { Rgas } from '../../asset'
import { fiftykg, fivekg, sixkg, tenkg, threekg, twelvehalfkg, twelvekg, twentyhalfkg, twentykg } from '../../assetPicknDrop'
import { ArrowBackOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

const ProductSelected = () => {
  const [quantity, setQuantity] = useState(1)
  const navigate = useNavigate();
  const handleBack = useCallback(() => navigate(-1), [navigate]);

  const incrementQuantity = ()  => {
    setQuantity((prevQuantity)=> prevQuantity + 1)
  }

  const decrementQuantity = ()  => {
    setQuantity((prevQuantity)=> Math.max(prevQuantity - 1, 1))
  }
 
  return (
    <div>
      <div className='flex items-center justify-between h-10 mt-8 rounded-3xl ml-[6%] mr-[6%]'>
        <ArrowBackOutlined className='border rounded-full p-1 cursor-pointer' onClick={handleBack} />
        <p className='text-xs text-[#A7A9B7]'>Product</p>
        <img src={Rgas} alt='LogoImg' className='w-4 h-6' />
      </div>

      <div>
        <div className='flex items-center justify-center text-xs mt-6'>
          <div className='bg-[#F9F9F9] rounded-md'>
            <img src={threekg} alt='threeImg' className='w-full h-full pt-6 pb-6  pl-24 pr-24' />
          </div>
        </div>

        <div className='flex items-center justify-between h-10 mt-4 ml-[10%] mr-[10%] border-b-gray-500'>
          <p className='text-xs text-[#A7A9B7]'>Product</p>
          <p className='text-xs'>3KG</p>
          <p className='text-xs'>₦2,700</p>
        </div>

        <div className='flex items-center justify-between h-10 ml-[10%] mr-[10%] border-b-gray-500'>
          <p className='text-xs text-[#A7A9B7]'>Quantity</p>
          <p className='text-xs text-[#A7A9B7]'>-</p>
          <p className='text-xs cursor-pointer'>
            <span onClick={decrementQuantity}>{'\u00a0'} - </span>{'\u00a0'}
            <span className='font-bold'>{quantity}</span>{'\u00a0'}  
            <span onClick={incrementQuantity}>+ </span> {'\u00a0'}</p>
        </div>

        <div className='flex flex-col h-10 ml-[10%] mr-[10%] border-b-gray-500'>
          <p className='text-xs text-[#A7A9B7] mt-3'>Description</p>
          <p className='text-xs'>Hassle-free Cooking Gas – the epitome of convenience delivered
            right to your doorstep! With our unique service, we don’t just provide you with cooking
            gas; we bring it to you, drop it off, and take away your empty cylinder. Say goodbye to
            the inconvenience of gas refills and welcome a seamless cooking experience into your life.
          </p>
        </div>

        <Link to='/pLogin'>
          <button className="w-[80%] bg-[#1F6FE2] pt-4 pb-4 pl-[7%] pr-[7%] mt-[50%] ml-[10%] mr-[10%] mb-5 text-white text-xs rounded-md">
            Order
          </button>
        </Link>

      </div>
    </div>
  )
}

export default ProductSelected