import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hook/useAuthContext";
import { down, flag } from "../../asset";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BusinessForm = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext()

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  const [formData, setFormData] = useState({
    address: "",
    cac_doc: "",
    cac_number: "",
    company_logo: "",
    company_name: "",
    country: "",
    email: "",
    phone: "",
    state: "",
    firstname: "",
    lastname: "",
  });

  const base64_arraybuffer = async (data) => {
    const base64url = await new Promise((r) => {
      const reader = new FileReader()
      reader.onload = () => r(reader.result)
      reader.readAsDataURL(new Blob([data]))
    })

    return `data:${data.type};base64,${base64url.split(",", 2)[1]}`
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (formData.email.trim() !== '' && !emailRegex.test(formData.email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    try {
      setLoading(true);

      const response = await fetch(`${energyUrl}/agents/me/customers/businesses`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to create business");
      }

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message)

        setTimeout(() => {
          navigate('/congratulation');
        }, 2000)

      } else {
        toast.error("Failed to submit the form")
      }

    } catch (error) {
      toast.error("Error creating Business:", error.message);

    } finally {
      setLoading(false);
    }
  };




  const handleValueChange = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));

  };

  return (
    <form className="max-w-md mx-auto p-6" onSubmit={handleFormSubmit}>
      <ToastContainer />



      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm"> First Name </label>
        <div className="relative">
          <input
            type="text"
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter first name"
            onChange={(e) => handleValueChange("firstname", e.target.value)}
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm"> Last Name </label>
        <div className="relative">
          <input
            type="text"
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter last name"
            onChange={(e) => handleValueChange("lastname", e.target.value)}
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm"> Company Name </label>
        <div className="relative">
          <input
            type="text"
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter company name"
            onChange={(e) => handleValueChange("company_name", e.target.value)}
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm">
          CAC RC Number
        </label>
        <div className="relative">
          <input
            type="text"
            onChange={(e) => handleValueChange("cac_number", e.target.value)}
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter CAC Number" />
        </div>

      </div>
      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm">
          Nature of Business
        </label>
        <div className="relative">
          <input
            type="text"
            onChange={(e) => handleValueChange("nature_of_business", e.target.value)}
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter nature of business" />
        </div>
      </div>

      <div className="mt-4 mb-4">
        <label htmlFor="phoneNumber" className="text-[#7F9395] text-bold text-sm">
          Upload CAC Document
        </label>
        <input
          type="file"
          name="logoInd"
          onChange={async (e) => handleValueChange("cac_doc", await base64_arraybuffer(e.target.files?.[0]))}
          className="w-full border border-gray-300 rounded-md p-2 mb-2"
          placeholder="Upload CAC document" />
      </div>

      <div className="mt-4 mb-4">
        <label htmlFor="phoneNumber" className="text-[#7F9395] text-bold text-sm">
          Company Logo
        </label>
        <input
          type="file"
          name="logoInd"
          onChange={async (e) => handleValueChange("company_logo", await base64_arraybuffer(e.target.files?.[0]))}
          className="w-full border border-gray-300 rounded-md p-2 mb-2"
          placeholder="Company Logo" />
      </div>

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm">Email</label>
        <div className="relative">
          <input
            type="text"
            onChange={(e) => handleValueChange("email", e.target.value)}
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter email address" />
        </div>
      </div>
      <div className="mt-4 mb-4">
        <label className="text-[#7F9395] text-bold text-sm">Phone Number</label>
        <div className="relative w-full flex items-center border rounded-md focus:outline-none focus:border-[#1F6FE2]">
          <img src={flag} alt="flagImg" className="ml-4 mr-1 w-8 h-5" />
          <img src={down} alt="flagImg" className="mr-2 w-3 h-3" />
          <input
            type="text"
            className=" w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="+234 803 000 0000"
            onChange={(e) => handleValueChange("phone", e.target.value)}
          />
        </div>
      </div>

      <div className="mt-4">
        <label htmlFor="country" className="block text-[#7F9395] text-bold text-sm">
          Country
        </label>
        <select
          type="text"
          name="country"
          onChange={(e) => handleValueChange("country", e.target.value)}
          className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
        >

          <option value="Nigeria">Nigeria</option>
          <option value="Ghana">Ghana</option>
          <option value="Namibia">Namibia</option>
          <option value="Rwanda">Rwanda</option>
          <option value="Canada">Canada</option>
          <option value="USA">USA</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm">State</label>
        <div className="relative">
          <input
            type="text"
            onChange={(e) => handleValueChange("state", e.target.value)}
            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter State"
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="text-[#7F9395] text-bold text-sm">
          Residential Address
        </label>
        <div className="relative">
          <textarea
            type="message"
            onChange={(e) => handleValueChange("address", e.target.value)}
            className="w-full h-40 bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            placeholder="Enter Address" />
        </div>
      </div>

      <button className="w-full mt-[10%] mb-[30%] pt-4 pb-4 hover:text-[#1F6FE2] hover:bg-white text-white bg-[#1F6FE2] border border-[#7F9395] text-sm font-bold rounded-md">
        {loading ? "Creating Business..." : "Create Business"}
      </button>
    </form>
  )
}

export default BusinessForm;