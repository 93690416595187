import React from 'react'
import { Rgas } from '../../asset'
import { fiftykg, fivekg, pickSearch, searchMe, sixkg, tenkg, threekg, twelvehalfkg, twelvekg, twentyhalfkg, twentykg } from '../../assetPicknDrop'
import { Link } from 'react-router-dom'


const PickNDrop = () => { 

  return (
    <div>
      <div className='flex items-center justify-center mt-10'>
        <img src={Rgas} alt='gasImg' className='w-8 h-12' />
      </div>

      <div className='flex items-center justify-center space-x-20 h-10 bg-[#F9F9F9] mt-4 rounded-3xl ml-[6%] mr-[6%]'>
        <img src={searchMe} alt='searchImg' />
        <p className='text-xs text-[#A7A9B7]'>Track your delivery</p>
        <img src={pickSearch} alt='searchImg' />
      </div>

      <div>
        <p className='text-xs font-medium ml-[6%] mt-8'>Select Product</p>
        <Link to = '/productSelected'>
        <div className='grid grid-cols-2 gap-2 text-xs p-3 pb-[10%]'>     
          <div className='bg-[#F9F9F9] flex flex-col justify-center items-center p-3  rounded-md'>
            <img src={threekg} alt='threeImg' className='h-14' />
            <p> 3kg {'\u00a0'}<span className='font-semibold'>₦ 2,700</span></p>
          </div>
          <div className='bg-[#F9F9F9] flex flex-col justify-center items-center p-3  rounded-md'>
            <img src={fivekg} alt='fiveImg' className='h-14' />
            <p> 5kg {'\u00a0'}<span className='font-semibold'>₦ 4,500</span></p>
          </div>
          <div className='bg-[#F9F9F9] flex flex-col justify-center items-center p-3  rounded-md'>
            <img src={sixkg} alt='sixImg' className='h-14' />
            <p> 6kg {'\u00a0'}<span className='font-semibold'>₦ 5,400</span></p>
          </div>
          <div className='bg-[#F9F9F9] flex flex-col justify-center items-center p-3 rounded-md'>
            <img src={tenkg} alt='tenImg' className='h-14' />
            <p> 10kg {'\u00a0'}<span className='font-semibold'>₦ 9,000</span></p>
          </div>
          <div className='bg-[#F9F9F9] flex flex-col justify-center items-center p-3 rounded-md'>
            <img src={twelvekg} alt='twelveImg' className='h-14' />
            <p> 12kg {'\u00a0'}<span className='font-semibold'>₦ 10,800</span></p>
          </div>
          <div className='bg-[#F9F9F9] flex flex-col justify-center items-center p-3 rounded-md'>
            <img src={twelvehalfkg} alt='twelvehalfImg' className='h-14' />
            <p> 12.5kg{'\u00a0'}<span className='font-semibold'>₦ 11,250</span></p>
          </div>
          <div className='bg-[#F9F9F9] flex flex-col justify-center items-center p-3 rounded-md'>
            <img src={twentykg} alt='twentyImg' className='h-14' />
            <p> 20kg {'\u00a0'}<span className='font-semibold'>₦ 18,450</span></p>
          </div>
          <div className='bg-[#F9F9F9] flex flex-col justify-center items-center p-3 rounded-md'>
            <img src={twentyhalfkg} alt='twentyhalfImg' className='h-14' />
            <p> 20.5kg{'\u00a0'}<span className='font-semibold'>₦ 18,450</span></p>
          </div>
          <div className='bg-[#F9F9F9] flex flex-col justify-center items-center p-3 rounded-md'>
            <img src={fiftykg} alt='fiftyImg' className='h-14' />
            <p> 50kg {'\u00a0'}<span className='font-semibold'>₦ 45,000</span></p>
          </div>        
        </div>
        </Link>
      </div>
    </div>
  )
}

export default PickNDrop