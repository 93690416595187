import React, { useCallback } from 'react'
import { close, confirm } from '../asset';
import { useNavigate } from 'react-router-dom';

const InterestReceived = () => {
    const navigate = useNavigate();
    const handleBack = useCallback(() => navigate(-3), [navigate]);
    return (
        <div className="bg-[#00000073] ">
            <div className="p-6 pb-[50%] pt-[20%]">
                <img src={close} alt='closeImg' className='ml-[90%] translate-y-[70%]' onClick={handleBack} />

                <div className="flex flex-col items-center max-w-md mx-auto rounded-2xl mt-4 p-4 bg-white">
                    <img src={confirm} alt='confirmImg' className='mt-[10%]' />
                    <h2 className='text-[#1F6FE2] font-bold text-xl'>Your Interest Received!</h2>
                    <h2 className='text-xs'>Onr of our Customer Support</h2>
                    <h2 className='text-xs mb-20'>Personnel will give you a call</h2>
                </div>
            </div>
        </div>
    );
}

export default InterestReceived