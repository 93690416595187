import React from "react";
import { useNavigate } from "react-router-dom";

const RefillCallback = () => {  
  const navigate = useNavigate();

  const handlePaymentSuccess = () => {
    navigate(`/refill`);
  };

  return (
    <div>
      <p>Payment Completed!</p>
      <button onClick={handlePaymentSuccess}>Go Back to Refill Page</button>
    </div>
  );
};

export default RefillCallback;