import React, { useState, useCallback } from "react";
import { proceed } from "../asset/index";
import { useNavigate } from "react-router-dom";
import IndividualForm from "../components/formComponent/IndividualForm";
import BusinessForm from "../components/formComponent/BuisnessForm";

const AddCustomer = () => {
  const [tab, setTab] = useState("individual");
  const navigate = useNavigate();
  const [selectedUnitIndividual, setSelectedUnitIndividual] = useState("");
  const [selectedUnitBusiness, setSelectedUnitBusiness] = useState("");

  const handleBack = useCallback(() => navigate(-1), [navigate]);

  const [formData, setFormData] = useState({
    address: "", agent_id: "", city: "",
    country: "", dob: "", email: "",
    firstname: "", gender: "", lastname: "",
    // meter_type: "",
     phone: "", state: "", 
    //  unitgroup_id: "",
  });

  const handleUnitChange = (event, tab, selectedUnit) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));

    if (tab === "individual") {
      setSelectedUnitIndividual(selectedUnit);
    } else if (tab === "business") {
      setSelectedUnitBusiness(selectedUnit);
    }
  };

  const handleTabChange = (selectedTab) => {
    setTab(selectedTab);
  };

  return (
    <div className="bg-[#F7F8FA] ">
      <div className="flex pt-[10%] justify-center gap-[38%] items-center">
        <h2 className="font-bold">Add Customer</h2>
        <img src={proceed} alt="logoImg" className="h-6 cursor-pointer" onClick={handleBack} />
      </div>
      <div className="flex justify-center mt-[10%] bg-transparent border  cursor-pointer gap-6 text-sm text-black p-3 rounded-full ml-[6%] mr-[6%]">
        <div
          className={`hover:border hover:border-l hover:border-r text-sm text-black pl-2 pr-2 pt-1 pb-1 rounded-full ${tab === "individual" ? "hover:bg-blue-700 hover:text-white cursor-pointer" : ""
            }`}
          onClick={() => handleTabChange("individual")}>
          Individual
        </div>
        <div
          className={`hover:border hover:border-l hover:border-r text-sm text-black pl-2 pr-2 pt-1 pb-1 rounded-full ${tab === "business" ? "hover:bg-blue-700 hover:text-white cursor-pointer" : ""
            }`}
          onClick={() => handleTabChange("business")}>
          Business
        </div>
      </div>
      {tab === "individual" ? (
        <IndividualForm
          formData={formData}
          handleUnitChange={(e) => handleUnitChange(e, "individual", selectedUnitIndividual)}
        />
      ) : (
        <BusinessForm
          formData={formData}
          handleUnitChange={(e) => handleUnitChange(e, "business", selectedUnitBusiness)}
        />
      )}
    </div>
  )
};
export default AddCustomer;