import React from 'react';
import { close } from '../asset';

const ModalGas = ({ closeModal }) => {
  return (
    <div className="bg-[#00000026] fixed top-0 left-0 w-full h-[100vh] flex justify-center items-center">
      <div className="rounded-md p-8 bg-[#F9F9F9] relative ml-[4%] mr-[4%]">
        <img
          src={close}
          alt="closeImg"
          className="absolute top-2 right-2 w-6 h-6 cursor-pointer"
          onClick={closeModal}
        />
        <h2 className="text-xs">
          If you opt for this option, we will pick up your gas cylinder, refurbish it, and brand it for you.
          You will have a unique serial number to identify your cylinder.
        </h2>
      </div>
    </div>
  );
};

export default ModalGas;
