import React from 'react'
import { close, confirm } from '../asset';
import { Link } from 'react-router-dom';

const Congratulation = () => {
    return (
        <div className="bg-[#00000073] h-screen">
            <div className="p-6 pb-[30%] pt-[20%]">
                <Link to="/addCustomer">
                    <img src={close} alt='closeImg' className='ml-[90%] translate-y-[70%]' />
                </Link>
                <div className="flex flex-col items-center max-w-md mx-auto rounded-2xl mt-4 p-4 bg-white">
                    <img src={confirm} alt='confirmImg' />
                    <h2 className='text-[#1F6FE2] font-bold text-xl'>Congratulation!</h2>
                    <h2 className='text-xs'>Account setup Successful</h2>
                    <h2 className='text-xs font-semibold mb-[30%] pt-[5%]'>Login details sent to recipient email</h2>

                    <Link to='/dashboard'>
                        <button className="w-full p-4 text-white hover:text-[#1F6FE2] hover:bg-white bg-[#1F6FE2] border border-[#7F9395] text-sm font-bold rounded-md">
                            Dashboard
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Congratulation