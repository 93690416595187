import React from "react";

const GroupUnitOrderVolume = ({ data }) => {
    const dataArray = Array.isArray(data) ? data : [];
    
    return (
        <div>
            {dataArray.map((item, idx) => (
                <div key={idx} className="flex items-center justify-between text-[10px] mt-[7%] border-t border-spacing-4 pt-[7%] ">
                    <div className="flex-shrink-0 w-[30%]">
                        <h2 className="overflow-hidden overflow-ellipsis">{item.name}</h2>
                    </div>
                    <h2 className="text-green-400 font-bold">{`${item.subscription.skid_in_kg} KG`}</h2>
                    <div className="relative w-[20%] h-2 bg-gray-200 rounded-full">
                        <div
                            className={`absolute h-full rounded-full ${item.total_customers < 40 ? "bg-red-500" : "bg-green-500"
                                }`}

                            style={{
                                width: `${item.total_customers}%`,
                            }} />
                    </div>
                    <span className="text-blue-500">{`${item.total_customers}%`}</span>
                </div>
            ))}
        </div>
    );
};

export default GroupUnitOrderVolume;