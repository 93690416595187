import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Category1,
  Rgas,
  User2,
  close,
  group1,
  menu,
  notificateWhite,
  shopping,
  userReg,
} from "../asset";

const Header = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const menuRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setNav(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeMenu = () => {
    setNav(false);
  };

  return (
    <header>
      <div
        className="w-full bg-[#F7F8FA] top-0 relative"
      >
        <div className="max-w-screen-lg mx-auto flex items-center pt-[5%] pb-[2%] border-b border-[#EDEFF2] justify-between">


          <div onClick={handleNav} className="block">
            {!nav ? (
              <img src={menu} alt="menuImg" />
            ) : (
              <div className="relative">
                <img
                  src={close}
                  alt="closeImg"
                  size={24}
                  className="hidden"
                  onClick={closeMenu}
                />
              </div>
            )}
          </div>

          <div className="flex">
            <div className="flex">
              <div className="flex">
                <ul className="flex gap-4">
                  <li
                    className="text-base font-bold  decoration-[1px] cursor-pointer duration-300"
                    style={{ color: "#352214", fontSize: "8px" }}
                  >
                    <img src={notificateWhite} alt="notificationImg" className="ml-[50%]" />
                  </li>

                  <Link to="/dashboard">
                    <img className="relative w-6 h-10 ml-[100%] mt-[27%] " src={Rgas} alt="rLogo" />
                  </Link>
                </ul>
              </div>

              <div
                ref={menuRef}
                className={
                  nav
                    ? "fixed left-0 top-0 w-full h-full border-r ease-in-out duration-500 bg-[#F7F8FA] z-50"
                    : "fixed left-[-100%]"
                }
              >
                <div className="flex flex-row justify-between mx-auto px-4 mt-[12%] z-50">

                  <div className="flex gap-2 items-center">
                    <Link to="/profile">
                      <img src={group1} alt="userImg"
                        onClick={closeMenu}
                      />
                    </Link>
                    <div>
                      <h2 className="font-bold">User</h2>
                      <h2 className="font-thin text-xs">Agent</h2>
                    </div>
                  </div>

                  <div className="relative">
                    <img
                      src={close}
                      alt="closeImg"
                      size={24}
                      className="right-4"
                      onClick={closeMenu}
                    />
                  </div>
                </div>

                <ul className="pt-[10%] z-50">
                  <li
                    className="p-4 cursor-pointer duration-300"
                    style={{ color: "#352214", fontSize: "8px" }}
                    onClick={closeMenu}
                  >
                    <div className="flex gap-2">
                      <img src={Category1} alt="userImg" className="w-4 h-4" />
                      <p className="text-[12px]"><Link to="/dashboard">Dashboard</Link></p>
                    </div>
                  </li>

                  <li
                    className="p-4 cursor-pointer duration-300"
                    style={{ color: "#352214", fontSize: "8px" }}
                    onClick={closeMenu}
                  >
                    <div className="flex gap-2">
                      <img src={userReg} alt="userImg" className="w-3 h-4" />
                      <p className="text-[12px]"><Link to="/groupUnit">Unit Groups</Link></p>
                    </div>
                  </li>

                  <li
                    className="p-4 cursor-pointer duration-300"
                    style={{ color: "#352214", fontSize: "8px" }}
                    onClick={closeMenu}
                  >
                    <div className="flex gap-2">
                      <img src={userReg} alt="userImg" className="w-3 h-4" />
                      <p className="text-[12px]"><Link to="/customers">Customers</Link></p>
                    </div>
                  </li>

                  <li
                    className="p-4 cursor-pointer duration-300"
                    style={{ color: "#352214", fontSize: "8px" }}
                    onClick={closeMenu}
                  >
                    <div className="flex gap-2">
                      <img src={shopping} alt="userImg" className="w-4 h-4" />
                      <p className="text-[12px]"><Link to="/orderHistory">Orders</Link></p>
                    </div>
                  </li>

                  <li
                    className="p-4 cursor-pointer duration-300"
                    style={{ color: "#352214", fontSize: "8px" }}
                    onClick={closeMenu}
                  >
                    <div className="flex gap-2">
                      <img src={User2} alt="userImg" className="w-4 h-4" />
                      <p className="text-[12px]"> <Link to="/profile">Profile</Link></p>
                    </div>
                  </li>

                  <li
                    className="p-4 cursor-pointer pt-[50%] duration-300"
                    style={{ color: "#352214", fontSize: "8px" }}

                  >
                    <div className="mt-[50%] text-center">
                      <div className="flex gap-2">
                        <p className="text-sm font-semi-bold">Powered by</p>
                        <img src={Rgas} alt="userImg" className="h-6 w-4" onClick={closeMenu} />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex gap-2 pr-4 pl-14 md:pl-0"></div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;