import React, { useCallback, useState, useEffect } from "react";
import { proceed } from "../asset/index";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Refill = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [weight, setWeight] = useState("");
  const [loading, setLoading] = useState(false);
  const [rate, setRate] = useState(null);
  const [totalRate, setTotalRate] = useState("");

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL
  const rateApiUrl = `${energyUrl}/rates/agent`
  const callback_url = `${window.location.origin}/refill/${id}`;

  const handleBack = useCallback(() => navigate(-1), [navigate]);

  useEffect(() => {
    const fetchRate = async () => {
      try {
        const response = await fetch(rateApiUrl, {
          headers: {
            "Content-Type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setRate(data.data);
        } else {
          toast.error("Failed to fetch rate");
        }
      } catch (error) {
        toast.error("An error occurred while fetching rate");
      }
    };

    fetchRate();
  }, [rateApiUrl]);

  const handleWeightChange = (e) => {
    const enteredWeight = e.target.value;

    if (!isNaN(enteredWeight) && enteredWeight !== "") {
      setWeight(enteredWeight);

      if (rate) {
        const calculatedTotalRate = parseFloat(enteredWeight) * rate;
        setTotalRate(calculatedTotalRate.toFixed(2));
      }
    } else {
      setWeight("");
      setTotalRate("");
    }
  };

  const handlePaymentVerification = async (reference) => {
    try {
      const response = await fetch(
        `${energyUrl}/transactions/verify/${reference}`
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Verification Response:", data);

        if (data.status === "success" && data.data.verified) {
          const { order_status, transaction_status } = data.data;      

          if (order_status === "confirmed" && transaction_status === "success") {
            toast.success("Payment verification successful");
          } else {
            toast.error("Payment verification failed");
          }
        } else {
          toast.error("Payment verification failed");
        }
      } else {
        toast.error("Failed to verify payment");
      }
    } catch (error) {
      toast.error("An error occurred while verifying payment");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await fetch(`${energyUrl}/refills`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          callback_url,
          weight: weight,
          unitgroup_id: id,
          rate: rate,
        }),
      });

      if (response.ok) {
        const orderData = await response.json();
        if (orderData.status === "success") {
          toast.success(orderData.message);

          const { auth_url, reference } = orderData.data;
          window.location.replace(auth_url);
          handlePaymentVerification(reference);

        } else {
          toast.error("Failed to submit the buy token order");
        }
      } else {
        toast.error("Failed to submit the buy token order");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the order");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-[#F7F8FA] ">
      <ToastContainer />
      <div className="flex pt-[10%] justify-center border-b gap-[40%] pb-[6%] items-center">
        <h2 className="font-bold">Order for Refill</h2>
        <img src={proceed} alt="logoImg" className="h-6" onClick={handleBack} />
      </div>

      <div className="p-6 pb-24">
        <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
          <h2 className="text-black font-semibold text-sm"> LPG Weight </h2>
          <div className="mb-8">
            <div className="relative">
              <input
                type="text"
                value={weight}
                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                placeholder="Enter LPG Weight"
                onChange={handleWeightChange} />
            </div>
          </div>

          <h2 className="text-black font-semibold text-sm"> Rate</h2>
          <div className="mb-8 text-sm">
            <p>{rate ? `Rate/Kg: ${rate}` : "Fetching rate..."}</p>
          </div>

          <h2 className="text-black font-semibold text-sm">Amount</h2>
          <div className="mb-8 text-sm">
            <p className="text-green-500 font-bold">₦ {totalRate || "0.00"}</p>
          </div>

          <button
            onClick={handleSubmit}
            disabled={loading}
            className="w-full pt-4 pb-4 mt-[10%] hover:text-[#1F6FE2] hover:bg-white mb-[10%] text-white bg-[#1F6FE2] border border-[#7F9395] text-sm font-bold rounded-md">
            {loading ? "Ordering..." : "Proceed to Payment"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Refill;
