import React, { useCallback } from 'react'
import { TaskAltRounded, ArrowBackOutlined } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

const GasManAlert = () => {
    const navigate = useNavigate();
    const handleBack = useCallback(() => navigate(-1), [navigate]);
  
    return (
        <div>
         <div className='flex items-center justify-between h-10 mt-8 rounded-3xl ml-[6%] mr-[6%]'>
        <ArrowBackOutlined className='border rounded-full p-1 cursor-pointer' onClick={handleBack} />
      </div>
            <div className="p-6 mt-[60%] overflow-y-hidden">               
                <div className="flex flex-col items-center max-w-md mx-auto rounded-2xl mt-4 p-4 bg-white text-xs font-semibold">
                <TaskAltRounded className='text-green-500' style = {{fontSize: '500%'}}/>
                    <h2 className='mt-3'>Thank you for letting us know.</h2>
                    <h2>We will make a replacement in no time</h2>
                   
                </div>
            </div>
        </div>
    );
}

export default GasManAlert