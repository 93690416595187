import React, { useEffect, useState } from 'react'
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import SplashPage from './pages/SplashPage';
import Footer from './headerNFooter/Footer';
import ScrollButton from './scroll/ScrollButton';
import Login from './pages/Login';
import EditProfile from './pages/EditProfile';
import Profile from './pages/Profile';
import Support from './pages/Support';
import BuyToken from './pages/BuyToken';
import Invoice from './pages/Invoice';
import Dashboard from './pages/Dashboard';
import { useLocation } from 'react-router-dom';
import Refill from './pages/Refill';
import AddUnitGroup from './pages/AddUnitGroup';
import GroupUnit from './pages/GroupUnit';
import AddCustomer from './pages/AddCustomer';
import CustomerInvoice from './pages/CustomerInvoice';
import Customers from './pages/Customers';
import GroupUnitGroup from './pages/GroupUnitGroup';
import CustomerUnitProfile from './pages/CustomerUnitProfile';
import Congratulation from './pages/Congratulation';
import CustomerDashboard from './pages/dashboard/CustomerDashboard';
import AppHeader from './headerNFooter/AppHeader';
import OrderHistory from './order-history/OrderHistory';
import InterestReceived from './pages/InterestReceived';
import CustomerProfile from './pages/profile/CustomerProfile';
import { AuthContextProvider } from './auth/AuthContext';
import Payment from './pages/Payment.';
import PasswordReset from './auth/PasswordReset';
import PasswordAuth from './auth/PasswordAuth';
import IndividualForm from './components/formComponent/IndividualForm';
import BusinessForm from './components/formComponent/BuisnessForm';
import RefillCallback from './components/RefillCallback';
import FirstSplashPage from './pages/FirstSplashPage';
import PickNDrop from './pages/pickNdrop/PickNDrop';
import ProductSelected from './pages/pickNdrop/ProductSelected';
import ProductOrderDetails from './pages/pickNdrop/ProductOrderDetails';
import OrderSummary from './pages/pickNdrop/OrderSummary';
import PickLogin from './pages/pickNdrop/PickLogin';
import PickRegister from './pages/pickNdrop/PickRegister';
import PickSuccess from './pages/pickNdrop/PickSuccess';
import ModalGas from './components/ModalGas';
import PickPaymentConfirmed from './pages/pickNdrop/PickPaymentConfirmed';
import PickProfile from './pages/pickNdrop/pickProfile';
import ModalCode from './components/ModalCode';
import OTP from './pages/pickNdrop/OTP';
import PickMakePayment from './pages/pickNdrop/PickMakePayment';
import PickFeedback from './pages/pickNdrop/PickFeedback';
import ArrivedRider from './pages/rider/ArrivedRider';
import AssigningRider from './pages/rider/AssigningRider';
import DispatchRider from './pages/rider/DispatchRider';
import GasManAlert from './gasManagement/GasManAlert';
import MeterHistory from './meter-history/MeterHistory';
import MeterHistoryGroup from './meter-history/MeterHistoryGroup';
import PNDashboard from './pages/dashboard/PNDashboard';
import ReviewOrder from './order-history/ReviewOrder';
import FundWallet from './pages/FundWallet';
import Successful from './pages/Successful';
import Receipt from './pages/Receipt';
import MeterForm from './components/formComponent/MeterForm';
import RevampInvoice from './pages/RevampInvoice';

const Layout = () => {
  const location = useLocation();
  const [showZohoChat, setShowZohoChat] = useState(true)

  const hideHeaderPaths = ['/customerUnitProfile', '/meterForm', '/revampInvoice/:id', '/receipt', '/successful', '/fund', '/reset', '/reviewOrder', '/assigningRider', '/dispatchRider', '/arrivedRider', '/pickFeedback',
    '/makePayment', '/otp', '/modalCode', '/pickPaymentConfirmed', '/pickSuccess',
    '/modalGas', '/pLogin', '/pRegister', '/orderSummary', '/productOrderDetails',
    '/productSelected', '/pickAndDrop', '/changePassword', '/invoice', '/payment',
    '/buyToken', '/refill', '/support', '/', '/splash', '/login', '/congratulation',
    '/interestReceived', '/gasManAlert'];

  const hideFooterPaths = ['/customerUnitProfile', '/meterForm', '/revampInvoice/:id' , '/receipt', '/successful', '/fund', '/reset', '/reviewOrder', '/assigningRider', '/dispatchRider', '/arrivedRider', '/pickFeedback',
    '/makePayment', '/otp', '/modalCode', '/pickPaymentConfirmed', '/pickSuccess',
    '/modalGas', '/pLogin', '/pRegister', '/orderSummary', '/productOrderDetails',
    '/productSelected', '/pickAndDrop', '/changePassword', '/congratulation',
    '/payment', '/', '/interestReceived', '/splash', '/login', '/gasManAlert'];

  const showHeader = !hideHeaderPaths.includes(location.pathname);
  const showFooter = !hideFooterPaths.includes(location.pathname);

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/splash') {
      setShowZohoChat(false);
    } else {
      setShowZohoChat(true);
    }
  }, [location.pathname]);

  return (
    <div>
      <AuthContextProvider>
        {showHeader && <AppHeader />}
        <ScrollRestoration />
        <Outlet />
        {showFooter && <Footer />}
        {showZohoChat && (
          <script type="text/javascript" id="zsiqchat">
            {`
              var $zoho=$zoho || {};
              $zoho.salesiq = $zoho.salesiq || {
                widgetcode: "siqd35a38db1de95e7bf5e5d278afe1d328667b42578cbd3925f549158362505595",
                values: {},
                ready: function(){}
              };
              var d=document;
              var s=d.createElement("script");
              s.type="text/javascript";
              s.id="zsiqscript";
              s.defer=true;
              s.src="https://salesiq.zohopublic.com/widget";
              var t=d.getElementsByTagName("script")[0];
              t.parentNode.insertBefore(s,t);
            `}
          </script>
        )}
      </AuthContextProvider>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [

      {
        path: "/",
        element: <FirstSplashPage />,
      },
      {
        path: "/splash",
        element: <SplashPage />,

      },
      {
        path: "/login",
        element: <Login />,

      },
      {
        path: "/pLogin",  
        element: <PickLogin />,

      },
      {
        path: "/gasManAlert",
        element: <GasManAlert />,

      },
      {
        path: "/pRegister",
        element: <PickRegister />,

      },
      {
        path: "/pickPaymentConfirmed",
        element: <PickPaymentConfirmed />,

      },
      {
        path: "/pickSuccess",
        element: <PickSuccess />,

      },
      {
        path: "/pndashboard",
        element: <PNDashboard />,

      },
      {
        path: "/pickFeedback",
        element: <PickFeedback />,

      },
      {
        path: "/otp",
        element: <OTP />,

      },
      {
        path: "/modalGas",
        element: <ModalGas />,

      },
      {
        path: "/reset",
        element: <PasswordReset />,

      },
      {
        path: "/changePassword",
        element: <PasswordAuth />,

      },
      {
        path: "/pickAndDrop",
        element: <PickNDrop />
      },
      {
        path: "/pickProfile",
        element: <PickProfile />
      },
      {
        path: "/modalCode",
        element: <ModalCode />
      },
      {
        path: "/arrivedRider",
        element: <ArrivedRider />
      },
      {
        path: "/assigningRider",
        element: <AssigningRider />
      },
      {
        path: "/dispatchRider",
        element: <DispatchRider />
      },
      {
        path: "/productSelected",
        element: <ProductSelected />
      },
      {
        path: "/productOrderDetails",
        element: <ProductOrderDetails />
      },
      {
        path: "/orderSummary",
        element: <OrderSummary />
      },
      {
        path: "/reviewOrder",
        element: <ReviewOrder />
      },
      {
        path: "/makePayment",
        element: <PickMakePayment />
      },
      {
        path: "/edit",
        element: <EditProfile />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/support",
        element: <Support />
      },
      {
        path: "/interestReceived",
        element: <InterestReceived />
      },
      {
        path: "/buyToken",
        element: <BuyToken />
      },
      {
        path: "/refill/:id",
        element: <Refill />
      },
      {
        path: '/refill-callback/:id',
        element: <RefillCallback />,
      },
      {
        path: '/meterHistoryGroup/:id',
        element: <MeterHistoryGroup />,
      },
      {
        path: "/invoice/:id",
        element: <Invoice />
      },
      {
        path: "/revampInvoice/:id",
        element: <RevampInvoice />
      },
      {
        path: "/payment",
        element: <Payment />
      },
      {
        path: "/orderHistory",
        element: <OrderHistory />
      },
      {
        path: "/meterHistory",
        element: <MeterHistory />
      },
      {
        path: "/congratulation",
        element: <Congratulation />
      },
      {
        path: "/addUnitGroup",
        element: <AddUnitGroup />
      },
      {
        path: "/groupUnit",
        element: <GroupUnit />
      },
      {
        path: "/groupUnitGroup/:id",
        element: <GroupUnitGroup />
      },
      {
        path: "/customerUnitProfile/:id",
        element: <CustomerUnitProfile />
      },
      {
        path: "/customerProfile",
        element: <CustomerProfile />
      },
      {
        path: "/dashboard",
        element: <Dashboard />
      },
      {
        path: "/customerDashboard",
        element: <CustomerDashboard />
      },
      {
        path: "/addCustomer",
        element: <AddCustomer />
      },
      {
        path: "/businessForm",
        element: <BusinessForm />
      },
      {
        path: "/individualForm",
        element: <IndividualForm />
      },
      {
        path: "/meterForm/:id",
        element: <MeterForm />
      },
      {
        path: "/customerInvoice",
        element: <CustomerInvoice />
      },
      {
        path: "/customers",
        element: <Customers />
      },
      {
        path: "/fund",
        element: <FundWallet />
      },
      {
        path: "/successful",
        element: <Successful />
      },
      {
        path: "/receipt",
        element: <Receipt />
      },
    ],
  },
]);

const App = () => {

  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
      <ScrollButton />
    </div>

  );
}

export default App