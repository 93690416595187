import React, { useState, useEffect, useCallback } from "react";
import Linemy from "../components/LineChart";
import { useLocation, useNavigate } from "react-router-dom";
import { person, proceed } from "../asset";

const MeterHistoryGroup = () => {
    const location = useLocation()
    const { meter } = location.state || {}
    const [selectedTab, setSelectedTab] = useState("Week");
    const [orders, setOrders] = useState([]);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [userDashboard, setUserDashboard] = useState(null)
    const [userDetails, setUserDetails] = useState(null)
    const [newTagName, setNewTagName] = useState("")
    const [meterTagNames, setMeterTagNames] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedMeter, setSelectedMeter] = useState(null)

    const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

    const handleBack = useCallback(() => navigate(-1), [navigate]);

    useEffect(() => {
        if (meter) {
            console.log('meter', meter);
            setSelectedMeter(meter)
        }
    }, [meter])

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch(
                    `${energyUrl}/users/me?detailed=true`, {
                    headers: {
                        token: localStorage.getItem('token'),
                        "Content-Type": "application/json",
                    },
                }
                )

                if (!response.ok) {
                    throw new Error("Failed to fetch user details");
                }

                const data = await response.json();
                setUserDetails(data.data);

                let meterTagNames = {}
                data.data.meters.forEach((meter) => {

                    meterTagNames[meter.uid] = meter.tag_name || "";
                })
                setMeterTagNames(meterTagNames)
            } catch (error) {
            }
        };

        fetchUserDetails();
    }, [energyUrl]);

    useEffect(() => {
        const fetchUserDashboard = async () => {
            try {
                const response = await fetch(
                    `${energyUrl}/stats/customer_dashboard`, {
                    headers: {
                        token: localStorage.getItem('token'),
                        "Content-Type": "application/json",
                    },
                }
                )
                if (!response.ok) {
                    throw new Error("Failed to fetch user details");
                }

                const data = await response.json();
                const { lpg_bought, total_spent, graph_data } = data.data;
                const chartData = Object.entries(graph_data).map(([yearMonth, revenue]) => {
                    const [year, month] = yearMonth.split('-');
                    return { name: `${month}-${year}`, Revenue: revenue };
                });

                setUserDashboard({
                    total_spent,
                    lpg_bought,
                    chartData,
                });
            } catch (error) {
            }
        };
        fetchUserDashboard();
    }, [energyUrl]);

    useEffect(() => {
        const fetchOrderHistory = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `${energyUrl}/users/me/orders`,
                    {
                        method: "GET",
                        headers: {
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch order history");
                }
                const data = await response.json();
                setOrders(data.data.items);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };
        fetchOrderHistory();
    }, [energyUrl]);




    const updateTagName = async () => {
        try {
            const response = await fetch(
                `${energyUrl}/users/me/meters/${selectedMeter.uid}/tag_name`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem('token'),
                },
                body: JSON.stringify({ tag_name: newTagName }),
            });

            if (!response.ok) {
                throw new Error("Failed to update tag name");
            }

            const responseData = await response.json();
            console.log(responseData.message);

            setMeterTagNames((prevMeterTagNames) => ({
                ...prevMeterTagNames,
                [selectedMeter.uid]: newTagName,
            }))


            setIsModalOpen(false);
        } catch (error) {
            console.error("Error updating tag name:", error);
        }
    };

    if (loading || !userDetails) {
        return <div className="flex justify-center items-center text-xs mt-8 text-green-600">Loading...</div>;
    }

    return (
        <div className="bg-[#F7F8FA] ">
            <div className="p-2 pb-24">
                <div className="max-w-md mx-auto rounded-lg p-4 bg-white">
                    <div className="flex items-end justify-end"> <img src={proceed} alt="logoImg" className="h-6 cursor-pointer text-center" onClick={handleBack} /></div>
                    {selectedMeter && (
                        <>
                            <div className="flex flex-row justify-between mx-auto px-4 mt-[12%]">
                                <div className="flex gap-2 items-center">
                                    <div className="flex justify-center items-center p-8 border rounded-full bg-blue-500 text-white font-bold  w-4 h-4"><img src={person} alt="personImg" /></div>
                                    <div className="relative text-xs -mt-[5%]">
                                        <h2 className="font-bold text-xs text-[#FF9D00]">Meter Number</h2>
                                        <h2 className="font-bold text-xs">{selectedMeter?.meter_number}</h2>
                                    </div>
                                </div>
                                <div className="w-[1px] h-6 bg-[#7F9395] mt-4"></div>
                                <div className="relative text-xs mt-[5%]">
                                    <div>
                                        <h2 className="font-bold text-[#FF9D00]">SIM card Number</h2>
                                        <h2 className="font-bold">{selectedMeter?.sim_number}</h2>
                                    </div>
                                </div>
                            </div>

                            {isModalOpen && (
                                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                    <div className="bg-white p-8 flex flex-col">
                                        <input type="text" value={newTagName} onChange={(e) => setNewTagName(e.target.value)} className="border border-gray-500 p-2" />
                                        <div className="flex gap-2 mt-2">
                                            <button className="border p-2 rounded-md font-bold hover:bg-[#FF9D00] hover:text-white" onClick={updateTagName}>Save</button>
                                            <button className="border p-2 rounded-md font-bold hover:bg-[#FF9D00] hover:text-white" onClick={() => setIsModalOpen(false)}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="text-xs gap-2 text-center">
                                <h2 className="font-bold text-[#FF9D00]">Tag Name</h2>
                                <h2 className="font-bold">{meterTagNames[selectedMeter?.uid] || <input type="text" value={newTagName} onChange={(e) => setNewTagName(e.target.value)} />} <span></span></h2>
                                <button className="border p-2 rounded-md font-bold bg-[#FF9D00] text-white hover:bg-white hover:text-[#FF9D00]" onClick={() => setIsModalOpen(true)}>Update Tag name</button>
                            </div>

                            <div className="w-full h-[1px] bg-[#7F9395] mt-[6%]"></div>
                            <div className="mt-[2%] grid grid-cols-2 text-sm gap-4 text-center max-w-md mx-auto p-4 items-center justify-between">

                                <div>
                                    <div className="flex justify-center items-center p-2 border rounded-md bg-pink-300 text-black font-bold  w-[100%] h-12">
                                        <h2 className="font-bold">
                                            {selectedMeter?.total_kg_balance !== null ? `${selectedMeter?.total_kg_balance}KG` : "N/A"}
                                        </h2>
                                    </div>
                                    <div>Total Balance</div>
                                </div>

                                <div>
                                    <div className="flex justify-center items-center p-2 border rounded-md bg-blue-300 text-black font-bold   w-[100%] h-12">

                                        <h2 className={`font-bold w-[80%] text-center border rounded-md ${selectedMeter && selectedMeter?.battery_status !== null ?
                                            selectedMeter?.battery_status <= 40 ? 'bg-red-500' : 'bg-green-500' : 'bg-red-500'}`}> {
                                                selectedMeter &&
                                                    selectedMeter?.battery_status !== null ?
                                                    `${selectedMeter?.battery_status}%` : 'N/A'}
                                        </h2>
                                    </div>
                                    <div> Battery Level </div>
                                </div>

                                <div>
                                    <div className="flex justify-center items-center p-2 border rounded-md bg-gray-300 text-black font-bold  w-[100%] h-12">
                                        <h2 className="font-bold">
                                            {selectedMeter?.total_kg_overdrawn !== null ? `${selectedMeter?.total_kg_overdrawn}KG` : "N/A"}
                                        </h2>
                                    </div>
                                    <div>Total KG Overdrawn</div>
                                </div>

                                <div>
                                    <div className="flex justify-center items-center p-6 border rounded-md bg-green-300 text-black font-bold  w-[100%] h-12">
                                        {selectedMeter?.total_kg_purchased !== null ? `${selectedMeter?.total_kg_purchased}KG` : "N/A"}
                                    </div>
                                    <div>Total KG Purchased</div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                {/* USAGE */}

                <div className="font-bold ml-[6%] text-md mt-8"> Usage </div>
                <div className="flex justify-center mt-[4%] bg-transparent border gap-6 text-sm text-black p-3 rounded-full ml-[6%] mr-[6%]">
                    <div
                        className={`hover:border hover:border-l hover:border-r text-sm text-black hover:border-black pl-2 pr-2 pt-1 pb-1 rounded-full hover:bg-white ${selectedTab === "Week" ? "border" : ""
                            }`}
                        onClick={() => setSelectedTab("Week")}
                    >
                        Week
                    </div>

                    <div
                        className={`hover:border hover:border-l hover:border-r text-sm text-black hover:border-black pl-2 pr-2 pt-1 pb-1 rounded-full hover.bg-white ${selectedTab === "Month" ? "border" : ""
                            }`}
                        onClick={() => setSelectedTab("Month")}
                    >
                        Month
                    </div>

                    <div
                        className={`hover:border hover.border-l hover.border-r text-sm text.black hover.border-black pl-2 pr-2 pt-1 pb-1 rounded-full hover.bg-white ${selectedTab === "Year" ? "border" : ""
                            }`}
                        onClick={() => setSelectedTab("Year")}
                    >
                        Year
                    </div>
                </div>

                <div className="p-6 pb-4">
                    {userDashboard && (
                        <div className="max-w-md mx-auto rounded-lg p-4 bg-white">
                            <div className="mt-[2%] grid grid-cols-3 text-[10px] gap-[35%] max-w-md mx-auto p-4 items-center justify-between">
                                <div>
                                    <h2 className="text-xl font-bold">{userDashboard.lpg_bought}KG</h2>
                                    <h2 className="w-full">LPG&nbsp;&nbsp;Bought</h2>
                                </div>

                                <div>
                                    <h2 className="text-xl font-bold">₦{userDashboard.total_spent.toLocaleString()}</h2>
                                    <h2>Total&nbsp;&nbsp;Spent</h2>
                                </div>
                            </div>

                            <div className="mt-[20%] text-[8px]">
                                <Linemy data={userDashboard.chartData} selectedMeter={meter} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MeterHistoryGroup;
