import React, { useEffect, useState, useCallback, useRef } from "react";
import { proceed } from "../asset/index";
import { useNavigate, useLocation } from "react-router-dom";
import { Download, EmailRounded, FacebookRounded, Print, Twitter, WhatsApp } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode.react";

const Invoice = ({ text }) => {
  const navigate = useNavigate();
  const location = useLocation()
  const handleBack = useCallback(() => navigate(-1), [navigate]);
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  const componentRef = useRef()

  const handlePrintClick = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
            @page {
                size: A4;
                margin: 0;
            }
            body {
                margin: 1cm;
            }
        `,
  })

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const orderId = location.pathname.split("/")[2];
        const response = await fetch(
          `${energyUrl}/users/me/orders/${orderId}?detailed=true`,
          {
            method: "GET",
            headers: {
              token: localStorage.getItem("token"),
              "Content-Type": "application/json"
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch order details");
        }
        const data = await response.json();
        setOrderData(data.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [energyUrl, location.pathname]);


  const handleCopyClick = (token) => {
    if (token) {
      const textField = document.createElement("textarea");
      textField.innerText = token
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      alert("This is your token: " + token);
    } else {
      alert("Token is not available.");
    }
  }
  const handleShareViaWhatsapp = (token) => {
    return () => {
      const whatsappLink = `https://wa.me/?text=Your%20Token:%20${encodeURIComponent(token)}`;
      window.open(whatsappLink, '_blank');
    }
  };

  const handleShareViaFacebook = (token) => {
    return () => {
      const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(token)}`;
      window.open(facebookLink, '_blank');
    }
  };

  const handleShareViaTwitter = (token) => {
    return () => {
      const twitterLink = `https://twitter.com/intent/tweet?text=Your%20Token:%20${encodeURIComponent(token)}`;
      window.open(twitterLink, '_blank');
    }
  };

  const handleShareViaEmail = (token) => {
    return () => {
      const emailLink = `mailto:?subject=Token%20Sharing&body=Your%20Token:%20${encodeURIComponent(token)}`;
      window.location.href = emailLink;
    }
  };



  return (
    <div className="bg-[#F7F8FA]">
      <div className="flex pt-[10%] justify-center border-b gap-[50%] pb-[6%] items-center">
        <h2 className="font-bold">Invoice</h2>
        <img
          src={proceed}
          alt="logoImg"
          className="h-6 cursor-pointer"
          onClick={handleBack}
        />
      </div>

      <div className="p-6 pb-24" ref={componentRef}>
        <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
          {loading ? (
            <div className="flex justify-center items-center text-xs text-green-600">Loading...</div>
          ) : (
            <>
              <div className="flex justify-between border-b mt-4 mb-2 pb-2">
                <h2 className="font-bold text-lg">LOGO</h2>
                <h2 className="text-xs font-bold">INVOICE</h2>
              </div><div className="border-b pt-2 pb-2">
                <h2 className="text-xs font-bold text-[#cfb542]">{orderData.type}</h2>
                <div className="flex justify-between mt-4 mb-2 pb-2 font-bold text-[10px] ">
                  <div>
                    <h2 className="flex gap-[5%]">Date <br /> <span className="font-semibold">{new Date(orderData.created_at).toLocaleDateString()}</span></h2>
                    <h2 className="flex gap-[5%]">Meter No <br /> <span className="font-semibold">{orderData.meter || "N/A"}</span></h2>
                    <h2 className="flex gap-[5%]">Invoice ID <br /> <span className="font-semibold">{orderData.transaction?.id}</span></h2>
                    <h2 className="flex gap-[5%]">Customer Number <br /> <span className="font-semibold">{orderData.user?.id_number || "N/A"}</span></h2>
                    <h2 className="flex gap-[5%]">Due Date <br /> <span className="font-semibold">{new Date(orderData.created_at).toLocaleDateString()}</span></h2>
                  </div>

                  <div>
                    <h2 className="flex gap-[5%]">Delivered to: </h2>
                    <h2 className="font-semibold">{orderData.user?.firstname} {orderData.user?.lastname} <br />
                      {orderData.user?.company_name || "N/A"} <br />
                      {orderData.user?.city || "N/A"} </h2>
                  </div>
                </div>
              </div><div className="grid grid-cols-4 border-b mt-4 mb-2 pb-2 font-bold text-[10px] ">
                <h2>Description <br /> <span className="font-semibold">{orderData.user?.description || "N/A"}</span></h2>
                <h2>Price <br /> <span className="font-semibold">₦{orderData.transaction?.amount.toLocaleString()}</span></h2>
                <h2>Qty <br /> <span className="font-semibold">1</span></h2>
                <h2>Total <br /> <span className="font-semibold">₦{orderData.transaction?.amount.toLocaleString()}</span></h2>
                <div className="mt-4 grid grid-cols-1">
                  <h2 className="flex justify-between gap-[41%]">SubTotal <br /> <span>₦{orderData.amount.toLocaleString()}</span></h2>
                  <h2 className="flex justify-between gap-[76.6%]">Tax<br /> <span>₦{orderData.transaction?.tax || "N/A"}</span></h2>
                </div>
              </div><div className="border-b mt-4 mb-2 pb-8">
                <div className="flex justify-end items-end text-[10px] pb-6">
                  <h2>You can scan the QR code <br /> below to download or <br /> share your token</h2>
                </div>
                <div className="flex justify-between">
                  <h2 className="text-xs">Token <br />
                    <div className="flex">
                      <p className="font-bold ">{orderData?.token} </p>
                      <button
                        className="bg-black text-white text-[10px] border rounded-lg pl-2 pr-2"
                        onClick={() => handleCopyClick(orderData.token ?? "")}  >  copy
                      </button>
                    </div>
                  </h2>
                  <QRCode value={orderData.token} className="w-48 h-48" />
                </div>

                <div className="text-[8px] -mt-[14%]">
                  <h2>SHARE VIA</h2>
                  <div className="flex justify-start gap-4 ">
                    <WhatsApp className="border rounded-full  bg-[#E2FCEF] p-1 text-green-500" onClick={handleShareViaWhatsapp(orderData.token ?? "")} />
                    <FacebookRounded className="border rounded-full bg-[#E9F1FF] p-1 text-blue-700" onClick={handleShareViaFacebook(orderData.token ?? "")} />
                    <Twitter className="border rounded-full  bg-[#EAF6FE] p-1 text-blue-300" onClick={handleShareViaTwitter(orderData.token ?? "")} />
                    <EmailRounded className="border rounded-full  bg-[#FCF2EB] p-1 text-[#CB324C]" onClick={handleShareViaEmail(orderData.token ?? "")} />
                  </div>
                </div>
              </div><div className="border-b mt-4 mb-2 pb-2 text-[9px]">
                <h2>Please retain this invoice for your reference</h2>
                <h2>Sales tax is included</h2>
                <h2>For questions regarding this invoice please email us</h2>
              </div>
              <div className="flex gap-2 justify-center mt-8 mb-4">
                <button
                  className="flex w-32 h-10 gap-4 justify-center items-center border rounded-md text-[#1F6FE3] border-black bg-[#1f6fe340]"
                  onClick={handlePrintClick}
                >
                  <Download />
                  <p>Download</p>
                </button>
                <button
                  className="flex w-32 h-10 gap-4 justify-center items-center border rounded-md text-[#1F6FE3] border-black bg-[#1F6FE340]"
                  onClick={handlePrintClick}
                >
                  <Print />
                  <p>Print</p>
                </button>
              </div></>
          )}
        </div>
      </div>
    </div>
  );
};
export default Invoice;