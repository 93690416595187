import React, { useEffect, useState, useCallback } from "react";
import { MdOutlineMoreVert } from "react-icons/md";
import { proceed, valve } from "../asset";
import { useNavigate } from "react-router-dom";

const MeterHistory = () => {
    const [theMeters, setTheMeters] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const handleBack = useCallback(() => navigate(-1), [navigate]);

    const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

    useEffect(() => {
        const fetchMeterHistory = async () => {
            try {
                const response = await fetch(
                    `${energyUrl}/users/me?detailed=true`, {
                    headers: {
                        token: localStorage.getItem('token'),
                        "Content-Type": "application/json",
                    },
                }
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch meter history");
                }

                const data = await response.json();
                setTheMeters(data.data);
            } catch (error) {

            } finally {
                setLoading(false);
            }
        };

        fetchMeterHistory();
    }, [energyUrl]);

    const handleSelectedMeterChange = useCallback(
        (meter) => {
        navigate(`/meterHistoryGroup/${meter.id}`, {
            state: {meter, theMeters}});
    }, [navigate, theMeters])


    if (loading) {
        return <div className="flex justify-center items-center text-xs mt-8 text-green-600">Loading...</div>;
    }


    return (
        <div className="bg-[#F7F8FA]">
            <div className="flex pt-[10%] justify-center gap-[50%] pb-[6%]">
                <h2 className="font-bold">Meter</h2>
                <img src={proceed} alt="logoImg" className="h-6 cursor-pointer" onClick={handleBack} />
            </div>

            <div className="p-6">
                <div className="max-w-md mx-auto rounded-lg mt-4 p-4 pb-20 bg-white">
                    <div className="flex justify-between mb-[10%]">
                        <h2 className="text-black font-semibold text-sm">Meter</h2>
                        <MdOutlineMoreVert />
                    </div>

                  
                        {theMeters && theMeters.meters ? (
                            theMeters.meters.map((meter, idx) => (
                                <>
                                    <div key={idx} className="grid grid-col-3 justify-start items-center border border-green-500  p-8 cursor-pointer" onClick={() => handleSelectedMeterChange(meter)}>
                                        <div className="font-semibold text-xs flex flex-col">
                                            <div>{meter.type}</div> 
                                            <div className="text-black font-normal text-xs flex"> 
                                            <img src={valve} alt="valveImg" className="w-4 h-4 " /> {
                                                meter.valve_is_open === true ? 'Open' :
                                                    meter.valve_is_open === false ? 'Closed' : 'N/A'
                                            }
                                            </div>
                                            <div className="text-green-600 text-xs font-bold">Tag name:{meter.tag_name}
                                        </div>
                                        </div>

                                        <div className="font-semibold text-xs cursor-pointer">
                                            {meter.meter_number}
                                        </div>
                                        <div className="text-green-500 font-bold text-xs">
                                        {meter.total_kg_purchased ? `${meter.total_kg_purchased}KG` : <span className="text-xs">N/A</span>} <br /> 
         
                                         <span className="text-black font-normal text-[10px]">{new Date(meter.created_at).toLocaleDateString()}</span></div>

                                    </div>
                                    </>
                            ))
                        ) : (
                            <div className="flex justify-center items-center text-xs mt-8 text-green-600">Fetching available meters</div>
                        )}
                   
                </div>
            </div>
        </div>
    );
};

export default MeterHistory;