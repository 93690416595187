import { createContext, useEffect, useReducer } from 'react';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { user: action.payload, token: action.payload.token }
    case 'LOGOUT':
      return { user: null, token: null }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const storedUser = JSON.parse(localStorage.getItem('user'))
  const storedToken = localStorage.getItem('token');

  const [state, dispatch] = useReducer(authReducer, {
    user: storedUser || null,
    token: storedToken || null,
  })

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    if (user && token) {
      dispatch({ type: 'LOGIN', payload: { user, token } })
    }
  }, [])
  
  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};