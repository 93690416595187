import React, { useState } from 'react'
import { useAuthContext } from '../hook/useAuthContext'
import CustomerHeader from './CustomerHeader'
import { Route, Routes } from 'react-router-dom'
import CustomerDashboard from '../pages/dashboard/CustomerDashboard'
import { useEffect } from 'react'
import Header from './Header'

const AppHeader = () => {
  const { user } = useAuthContext()
  const [isCustomer, setIsCustomer] = useState(false);

  useEffect(() => {
    if (user?.user?.role === 'individual' || user?.user?.role === 'business') {
      setIsCustomer(true)
    } else if (user?.user?.role === 'agent') {
      setIsCustomer(false)
    }
  }, [user])

  const renderHeader = () => {
    if (isCustomer) {
      return<CustomerHeader /> 
    } else {
      return <Header />;
    }  
  };

  return (
    <>
      {renderHeader()}
      <Routes>
        {isCustomer && <Route path="/dashboard" element={<CustomerDashboard />} />}       
      </Routes>
    </>
  )
}

export default AppHeader