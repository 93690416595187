import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Rgas } from '../../asset'
import { ArrowBackOutlined } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import { indicator } from '../../assetPicknDrop';

const OTP = () => {
    const [seconds, setSeconds] = useState(35)
    const [digitsHere, setDigitsHere] = useState(['','','',''])
    const [isConfirmed, setIsConfirmed] = useState(false)
    const digitRefs = [useRef(), useRef(), useRef(), useRef()]
    const navigate = useNavigate();    
    const handleBack = useCallback(() => navigate(-1), [navigate]);

    useEffect(()=> {
        const countdownInterval = setInterval(()=> {
            setSeconds((prevSeconds) => {
                const newSeconds = prevSeconds - 1
                if (newSeconds < 0){
                    clearInterval(countdownInterval)
                    return 0
                }

                if (newSeconds === 0 && digitsHere.some((digit) => digit === '')){
                    clearInterval(countdownInterval)
                }
                return newSeconds
            })
        }, 1000)
        return () => clearInterval(countdownInterval)
    }, [digitsHere])
    
    const handleInputChange = (idx, value) => {
        const newDigits = [...digitsHere]
        
        newDigits[idx] = value

        setDigitsHere(newDigits.map((digit)=> digit.slice(0, 1)))

        if (idx < digitRefs.length - 1 && value !== '' && digitRefs[idx + 1].current) {
            digitRefs[idx + 1].current.focus()
    } else {
        const range = document.createRange()
        const selection = window.getSelection()
        range.setStart(digitRefs[idx].current.firstChild, 0)
        range.collapse(true)
        selection.removeAllRanges()
        selection.addRange(range)
    }

    const allDigitsInputted = newDigits.every((digit)=> digit !== '')
    setIsConfirmed(allDigitsInputted && seconds > 0)
}

    const formatTime = (time) => (time < 10 ? `0${time}` : time)
    return (
        <div>
            <div className='flex items-center justify-between h-10 mt-8 rounded-3xl ml-[6%] mr-[6%]'>
                <ArrowBackOutlined className='border rounded-full p-1 cursor-pointer' onClick={handleBack} />
                <p className='text-xs text-[#A7A9B7]'>Phone Number Confirmation</p>
                <img src={Rgas} alt='LogoImg' className='w-4 h-6' />
            </div>
            <div>
                <p className='text-xs mt-12 text-center p-10'>An OTP has been sent to your phone number 080*******87, insert the numbers to confirm your number.</p>

                <div className='grid text-xs p-3'>     
                    <div className='flex flex-col p-3 rounded-md'>
                    <div className='flex justify-center items-center gap-4'>
                        {digitsHere.map((digit, idx)=> (
                            <div
                                key = {idx}
                                className='w-10 h-10 rounded-md bg-gray-200 text-center border-[#1F6FE2] font-bold flex justify-center items-center text-3xl'
                                contentEditable
                                suppressContentEditableWarning
                                ref={digitRefs[idx]}
                                onKeyDown={(e) => {
                                    if (!/\d/.test(e.key)){
                                        e.preventDefault();
                                    }
                                }}
                                onInput = {(e) => {
                                    handleInputChange(idx, e.currentTarget.textContent.slice(0,1))
                                }}
                            >
                                {digit}
                            </div>
                        ))}                                                
                    </div>
                    <p className='text-center text-xs mt-2'>Expires in 00:{formatTime(seconds)}</p>   
                    <p className="text-xs flex justify-center items-center mt-[30%]">Didn't receive OTP? {'\u00a0'}<span className="underline text-[#1F6FE2] cursor-pointer">Resend</span></p>                     
                    </div>

                    {/* <Link to='/pLogin'> */}
                    <button
                        className={`w-full mt-[45%] mb-[10%] pt-4 pb-4 ${
                            isConfirmed ? 'bg-[#1F6FE2] text-white hover:bg-[#ffffff] hover:text-[#1F6FE2] border border-[#1F6FE2]' : 'bg-[#e1e0e0] text-[#aecbf3] '
                        } text-sm font-bold rounded-md`}
                        disabled={!isConfirmed}
                    >
                        Confirm
                    </button>
                    {/* </Link> */}
                </div>
            </div>
        </div>
    )
}

export default OTP

