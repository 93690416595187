import React, { useEffect, useState } from "react";
import { proceed } from "../asset/index";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../hook/useAuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddUnitGroup = ({ notifyGroupUnit }) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext()
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [gCat, setGCat] = useState("");
  const [subscription, setSubscription] = useState("");
  const [category, setCategory] = useState([]);
  const [groupSubscription, setGroupSubscription] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [address, setAddress] = useState("");
  const [errors, setErrors] = useState({
    groupName: "",
    gCat: "",
    subscription: "",
    country: "",
    state: "",
    address: "",
  });

  const navigate = useNavigate();

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  const handleCreateUnitGroup = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!groupName) newErrors.groupName = "Group Name is required";
    if (!gCat) newErrors.gCat = "Group Category is required";
    if (!subscription) newErrors.subscription = "Subscription is required";
    if (!country) newErrors.country = "Country is required";
    if (!state) newErrors.state = "State is required";
    if (!address) newErrors.address = "Address is required";
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      setErrors([]);
      setTimeout(() => {
        navigate("/groupUnit");
      }, 2000)
    } catch (error) {
      toast.error("Error creating unit group:", error.message);
    }

    try {
      setLoading(true);

      const response = await fetch(
        `${energyUrl}/agents/me/unitgroups`,
        {
          method: "POST",
          headers: {
            token: localStorage.getItem('token'),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: groupName,
            category: gCat,
            subscription: subscription,
            country: country,
            state: state,
            address: address,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create unit group");
      }

      const data = await response.json();
      if (data && data.data) {
        const { address, category, country, name, state, subscription } = data.data;
        toast.success("Unit group created successfully:", {
          address,
          category,
          country,
          name,
          state,
          subscription: subscription.name,
        });

        if (typeof notifyGroupUnit === 'function') {
          notifyGroupUnit();
        } else {
        }
      } else {
      }
    } catch (error) {
      toast.error("Error creating unit group:", error.message);

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCategoryTypes = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/unitgroup_categories`,
          {
            method: "GET",
            headers: {
              token: localStorage.getItem('token'),
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch categories types");
        }
        const data = await response.json();

        setCategory(data.data.items);
      } catch (error) {
      }
    };

    const fetchGroupSubscriptionTypes = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/group_subscriptions`,
          {
            method: "GET",
            headers: {
              token: localStorage.getItem('token'),
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch subscription types");
        }
        const data = await response.json();
        setGroupSubscription(data.data.items);
      } catch (error) {
      }
    };
    fetchCategoryTypes();
    fetchGroupSubscriptionTypes()
  }, [energyUrl, user.token]);

  return (
    <div className="bg-[#F7F8FA] ">
      <form>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
        <div className="flex pt-[10%] justify-center gap-[38%] items-center">
          <h2 className="font-bold">Add Unit Group</h2>
          <Link to='/dashboard'>
            <img src={proceed} alt="logoImg" className="h-6" />
          </Link>
        </div>

        <div className="p-6 pb-24 cursor-pointer">
          <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
            <div className="mb-4">
              <label className="text-[#7F9395] text-bold text-sm">Group Name</label>
              <div className="relative">
                <input
                  id="groupName"
                  name="groupName"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  type="text"
                  className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                  placeholder="Group Name"
                />
              </div>
            </div>
            {errors.groupName && <div className="text-red-500 text-xs -mt-3">{errors.groupName}</div>}

            <div className="mt-6">
              <label htmlFor="gender" className="block text-[#7F9395] text-bold text-sm"> Group Category </label>
              <select
                id="gcat"
                name="gcat"
                value={gCat}
                onChange={(e) => setGCat(e.target.value)}
                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4">

                <option value="">Select Category</option>
                {category.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            {errors.gCat && <div className="text-red-500 text-xs mt-1">{errors.gCat}</div>}

            <div className="mt-6">
              <label htmlFor="gender" className="block text-[#7F9395] text-bold text-sm"> Subscription </label>
              <select
                id="sub"
                name="sub"
                value={subscription}
                onChange={(e) => setSubscription(e.target.value)}
                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
              >
                <option value="">Select Subscription</option>
                {groupSubscription.map((type) => (
                  <option key={type.name} value={type.name}>
                    {type.name} - {type.volume_in_kg} kg
                  </option>
                ))}
              </select>
            </div>
            {errors.subscription && <div className="text-red-500 text-xs mt-1">{errors.subscription}</div>}

            <div className="mt-6">
              <label htmlFor="country" className="block text-[#7F9395] text-bold text-sm"> Country </label>
              <select
                id="country"
                name="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
              >
                <option>Select Country</option>
                <option>Nigeria</option>
                <option>Ghana</option>
                <option>Namibia</option>
                <option>Rwanda</option>
                <option>Canada</option>
                <option>USA</option>
              </select>
            </div>
            {errors.country && <div className="text-red-500 text-xs mt-1">{errors.country}</div>}

            <div className="mb-6 mt-6">
              <label className="text-[#7F9395] text-bold text-sm">State</label>
              <div className="relative">
                <input
                  id="state"
                  name="state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  type="text"
                  className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                  placeholder="Enter State"
                />
              </div>
            </div>
            {errors.state && <div className="text-red-500 text-xs -mt-6">{errors.state}</div>}

            <div className="mb-6 mt-6">
              <label className="text-[#7F9395] text-bold text-sm"> Address </label>
              <div className="relative">
                <input
                  id="address"
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="message"
                  className="w-full h-40 bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                  placeholder="Enter Address"
                />
              </div>
            </div>
            {errors.address && <div className="text-red-500 text-xs -mt-6">{errors.address}</div>}

            <button
              type="submit"
              className="w-full mt-[10%] mb-[10%] pt-4 pb-4 hover:text-[#1F6FE2] hover:bg-white text-white bg-[#1F6FE2] border border-[#7F9395] text-sm font-bold rounded-md"
              onClick={handleCreateUnitGroup}>
              {loading ? "Creating Unitgroup..." : "Create"}
            </button>

          </div>
        </div>
      </form>
    </div>
  );
};

export default AddUnitGroup;