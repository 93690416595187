import React, { useState, useEffect } from "react";
import { plus } from "../asset";
import { Link } from "react-router-dom";
import VolumeGauge from "../components/VolumeGauge";
import Barmy from "../components/BarChart";

const Dashboard = () => {
  const [selectedTab, setSelectedTab] = useState("Week");
  const [showAll, setShowAll] = useState(false);
  const [userDashboard, setUserDashboard] = useState(null)

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  useEffect(() => {
    const fetchUserDashboard = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/stats/agent_dashboard`, {
          headers: {
            token: localStorage.getItem('token'),
            "Content-Type": "application/json",
          },
        }
        )
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const data = await response.json();
        const { customers_total, total_lpg_sold, total_revenue, orders_graph_data } = data.data;

        const chartData = Object.entries(orders_graph_data).map(([yearMonth, revenue]) => {
          const [year, month] = yearMonth.split('-');
          return { name: `${month}-${year}`, Revenue: revenue };
        });

        setUserDashboard({
          customers_total,
          total_lpg_sold,
          total_revenue,
          chartData,
        });
      } catch (error) {
      }
    };

    fetchUserDashboard();
  }, [energyUrl]);

  return (
    <div className="bg-[#F7F8FA] overflow-x-hidden">
      <div className="flex flex-col pt-[10%] justify-start gap-[28%] items-center">

        <h2 className="font-bold left-0 mb-[10%]">Dashboard</h2>
        <div className="flex justify-center gap-4">
          <Link to="/addUnitGroup">
            <div className="flex justify-center gap-3 items-center border border-l border-r text-xs text-white border-[#1F6FE2] p-2 rounded-full bg-[#33ccff]">
              <img src={plus} alt="logoImg" />
              Add Unit Group
            </div>
          </Link>

          <Link to={{ pathname: (`/addCustomer`) }}>
            <div className="flex justify-center gap-3 items-center border border-l border-r text-xs text-white border-[#1F6FE2] p-2 rounded-full bg-[#1F6FE2]">
              <img src={plus} alt="logoImg" />
              Add Customer
            </div>
          </Link>
        </div>
      </div>

      <div className="flex justify-center mt-[10%] bg-transparent border gap-6 text-sm text-black p-3 rounded-full ml-[6%] mr-[6%]">
        <div
          className={`hover:border hover:border-l hover:border-r text-sm text-black hover:border-black pl-2 pr-2 pt-1 pb-1 rounded-full hover:bg-white ${selectedTab === "Week" ? "border" : ""
            }`}
          onClick={() => setSelectedTab("Week")} > Week </div>

        <div
          className={`hover:border hover:border-l hover:border-r text-sm text-black hover:border-black pl-2 pr-2 pt-1 pb-1 rounded-full hover.bg-white ${selectedTab === "Month" ? "border" : ""
            }`}
          onClick={() => setSelectedTab("Month")} > Month </div>

        <div
          className={`hover:border hover.border-l hover.border-r text-sm text.black hover.border-black pl-2 pr-2 pt-1 pb-1 rounded-full hover.bg-white ${selectedTab === "Year" ? "border" : ""
            }`}
          onClick={() => setSelectedTab("Year")} >  Year </div>
      </div>

      <div className="p-6 pb-4">
        {userDashboard && (
          <div className="max-w-md mx-auto rounded-lg p-4 bg-white">
            <div className="mt-[2%] grid grid-cols-3 text-[10px] gap-4 max-w-md mx-auto p-4 items-center justify-between">
              <div>
                <h2 className="text-md font-bold">{userDashboard.customers_total}</h2>
                <h2>Total Customers</h2>
              </div>

              <div>
                <h2 className="text-md font-bold">{userDashboard.total_lpg_sold}</h2>
                <h2>LPG Sold</h2>
              </div>

              <div>
                <h2 className="text-md font-bold">{userDashboard.total_revenue}</h2>
                <h2>Revenue</h2>
              </div>
            </div>

            <div className="mt-[20%] text-[8px]">
              <Barmy data={userDashboard.chartData} />
            </div>
          </div>
        )}
      </div>

      <div className="p-6 pb-24">
        <div className="max-w-md mx-auto rounded-lg p-4 bg-[#fceceb]">
          <div className="mt-[2%] grid grid-cols-1 text-[10px] gap-4 items-center justify-between">
            <div>
              <h2 className="text-sm font-bold">Volume Gauge</h2>
            </div>
          </div>

          <div className="flex flex-col">
            <VolumeGauge showAll={showAll} />
          </div>
        </div>
        {/* <button
              className="text-xs text-blue-500 underline cursor-pointer"
              onClick={handleToggleView}
            >
              {showAll ? "View Less" : "View More"}
            </button> */}
      </div>     
    </div>
  );
};
export default Dashboard;