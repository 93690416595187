import React, { useState, useEffect, useCallback } from "react";
import { Category1, plus, proceed } from "../asset";
import { Link, useNavigate, useParams } from "react-router-dom";
import VolumeCustomerRemaining from "../components/VolumeCustomerRemaining";

const GroupUnitGroup = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [unitGroupDetails, setUnitGroupDetails] = useState(null);
    const [customers, setCustomers] = useState([]);
    const handleBack = useCallback(() => navigate(-1), [navigate]);

    const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

    useEffect(() => {
        const fetchUnitGroupDetails = async () => {
            try {
                const response = await fetch(
                    `${energyUrl}/agents/me/unitgroups/${id}`,
                    {
                        method: "GET",
                        headers: {
                            token: localStorage.getItem('token'),
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch unit group details");
                }

                const data = await response.json();
                setUnitGroupDetails(data.data);

                const customerResponse = await fetch(
                    `${energyUrl}/agents/me/unitgroups/${id}?detailed=true`,
                    {
                        method: "GET",
                        headers: {
                            token: localStorage.getItem('token'),
                            "Content-Type": "application/json",
                        },
                    }
                )

                if (!customerResponse.ok) {
                    throw new Error("Failed to fetch customer data");
                }

                const customerData = await customerResponse.json();
                setCustomers(customerData.data.customers);
                setUnitGroupDetails(customerData.data);
            } catch (error) {
                navigate("/error");
            }
        };
        fetchUnitGroupDetails();
    }, [id, navigate, energyUrl]);

    if (!unitGroupDetails) {
        return <div className="flex justify-center items-center text-xs mt-2 text-green-600">Loading...</div>;
    }

    const trimUnitGroupName = (name, maxLength) => {
        if (name.length <= maxLength) {
            return name;
        }
        return name.substring(0, maxLength) + '...';
    };

    const VolumeCustomerRemainingData = [
        { percentage: 45 },
    ];
    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div className="bg-[#F7F8FA] ">
            <>
                <div className="flex pt-[10%] justify-center gap-4 items-center">
                    <h2 className="font-bold text-xs"> {trimUnitGroupName(unitGroupDetails.name, 12)} </h2>
                    <div className="flex gap-2">
                        <Link to={{ pathname: (`/addCustomer`) }}>
                            <div className="flex justify-center gap-3 items-center border border-l border-r text-xs text-white border-[#1F6FE2] p-2 rounded-full bg-[#1F6FE2]">
                                <img src={plus} alt="logoImg" />
                                Add Customer
                            </div>
                        </Link>
                        <img src={proceed} alt="logoImg" className="h-6 mt-[7%] cursor-pointer" onClick={handleBack} />
                    </div>
                </div>

                <div className="p-6 pb-4">
                    <div className="max-w-md mx-auto rounded-lg p-4 bg-white">

                        <div className="flex flex-row justify-between mx-auto -mt-[13%] px-4 text-[10px]">
                            <div className="flex gap-2 items-center">
                                <div>
                                    <h2 className="font-bold text-[#1F6FE2]">GROUP UNIT NAME</h2>
                                    <h2 className="font-thin text-xs">
                                        {unitGroupDetails.name}

                                    </h2>
                                </div>
                            </div>

                            <div className="flex items-center">
                                <div className="mt-[50%]">
                                    <h2 className="font-bold text-[#1F6FE2]">VOLUME REMAINING</h2>
                                    <h2>
                                        <VolumeCustomerRemaining data={VolumeCustomerRemainingData} />
                                    </h2>
                                    <Link
                                        to={{
                                            pathname: (`/refill/${id}`),
                                            state: { unitgroupId: unitGroupDetails.id }
                                        }}

                                    >
                                        <button className="justify-center items-center border border-l border-r text-gray-400 p-2 rounded-full bg-[#dadbdb]">
                                            Order for Refill
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="w-full h-[1px] bg-[#7F9395] mt-[6%]"></div>
                        <div className="mt-[2%] grid grid-cols-2 text-[10px] gap-4 max-w-md mx-auto p-4 items-center justify-between">
                            <div>
                                <h2 className="text-[#1F6FE2] font-bold">CATEGORY</h2>
                                <h2> {unitGroupDetails.category} </h2>
                            </div>
                            <div>
                                <h2 className="text-[#1F6FE2] font-bold">SUBSCRIPTION</h2>
                                <h2> {unitGroupDetails.subscription.name} {unitGroupDetails.subscription.volume_in_kg}kg </h2>
                            </div>
                            <div>
                                <h2 className="text-[#1F6FE2] font-bold">Customers</h2>
                                <h2> {unitGroupDetails.total_customers} </h2>
                            </div>
                            <div>
                                <h2 className="text-[#1F6FE2] font-bold">COUNTRY</h2>
                                <h2> {unitGroupDetails.country} </h2>
                            </div>
                            <div>
                                <h2 className="text-[#1F6FE2] font-bold">STATE</h2>
                                <h2> {unitGroupDetails.state} </h2>
                            </div>
                            <div>
                                <h2 className="text-[#1F6FE2] font-bold">ADDRESS</h2>
                                <h2> {unitGroupDetails.address} </h2>
                            </div>
                            <div>
                                <h2 className="text-[#1F6FE2] font-bold">STATUS</h2>
                                <h2 className="font-bold">
                                    <div className={`h-4 w-4 rounded-full ${unitGroupDetails.is_active ? 'bg-green-500' : 'bg-red-500'}`} />
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </>

            <div className="p-6 pb-2">
                <div className="max-w-md mx-auto p-4 bg-white">
                    <div className="flex gap-6 text-sm text-black p-3">
                        <img src={Category1} alt="CategoryImg" className="w-[8%] h-[8%]" />
                        <div className="text-sm text-black font-bold pb-1">
                            Customers
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col">
                            {customers.map((customer, idx) => (
                                <div key={idx} className="flex justify-between items-center mb-4 border-t pt-2 pb-2">
                                    <div className={`h-8 w-[12%] border rounded-full bg-${customer.is_active ? 'green' : 'red'}-500`}></div>
                                    <div className="-translate-x-[20%]">
                                        <h2 className="text-[8px]">{customer.serial_number}</h2>
                                        <h2 className="text-xs font-bold">{`${customer.firstname} ${customer.lastname}`}</h2>
                                    </div>
                                    <button className={`text-${customer.is_active ? 'green' : 'red'}-600 font-bold text-xs bg-${customer.is_active ? 'green' : 'red'}-50 w-fit pl-2 pr-2 h-6 border rounded-2xl`}>
                                        {customer.is_active ? 'Active' : 'Pending'}
                                    </button>

                                </div>
                            ))}

                        </div>
                        <div className="mb-4">
                        </div>
                    </form>
                </div>
                <button className="w-full mt-[10%] mb-[10%] pb-4 text-black text-sm">
                    View All
                </button>
            </div>
        </div>
    )
}
export default GroupUnitGroup;