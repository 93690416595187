import React from "react";
import { met2, proceed } from "../asset/index";
import { Link } from "react-router-dom";
import { Download, EmailRounded, FacebookRounded, Print, Twitter, WhatsApp } from "@mui/icons-material";

const CustomerInvoice = ({ text }) => {
  const handleCopyClick = () => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    alert("Copied to clipboard: " + text);
  };

  return (
    <div className="bg-[#F7F8FA] ">
      <div className="flex pt-[10%] justify-center gap-[48%] items-center">
        <h2 className="font-bold">Customers</h2>
        <Link to='/buyToken'>
          <img src={proceed} alt="logoImg" className="h-6" />
        </Link>
      </div>

      <div className="p-6 pb-24">
        <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
          <div className="flex justify-between border-b mt-4 mb-2 pb-2">
            <h2 className="font-bold text-lg">LOGO</h2>
            <h2 className="text-xs">INVOICE</h2>
          </div>
          <div className="border-b pt-2 pb-2">
            <h2 className="text-xs font-bold text-[#FFE156]">Cash Transfer</h2>
            <div className="flex justify-between mt-4 mb-2 pb-2 font-bold text-[8px] ">
              <div>
                <h2 className="flex gap-[5%]">Date <br /> <span className="font-semibold">Friday, May 10, 2034 </span></h2>
                <h2 className="flex gap-[5%]">Meter No <br /> <span className="font-semibold">IN2345</span></h2>
                <h2 className="flex gap-[5%]">Invoice ID <br /> <span className="font-semibold">IN2345</span></h2>
                <h2 className="flex gap-[5%]">Customer Number <br /> <span className="font-semibold">+234 700 000 000</span></h2>
                <h2 className="flex gap-[5%]">Due Date <br /> <span className="font-semibold">Friday, May 16, 2034</span></h2>
              </div>

              <div>
                <h2 className="flex gap-[5%]">Delivered to: </h2>
                <h2 className="font-semibold">John Doe <br /> Cedi Plaza <br /> 958 Allen Avenue <br /> Abuja</h2>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-4 border-b mt-4 mb-2 pb-2 font-bold text-[8px] ">
            <h2>Description <br /> <span className="font-semibold">120kg Gas</span></h2>
            <h2>Price <br /> <span className="font-semibold">NGN34,545</span></h2>
            <h2>Qty <br /> <span className="font-semibold">1</span></h2>
            <h2>Total <br /> <span className="font-semibold">NGN34,545</span></h2>
            <div className="mt-4 grid grid-cols-1">
              <h2 className="flex justify-between gap-[55%]">SubTotal <br /> <span>NGN34,545</span></h2>
              <h2 className="flex justify-between gap-[84%]">Tax<br /> <span>NGNO</span></h2>
            </div>
          </div>
          <div className="border-b mt-4 mb-2 pb-8">
            <div className="flex justify-end items-end text-[8px] pb-6">
              <h2>You can scan the QR code <br /> below to download or <br /> share your token</h2>
            </div>
            <div className="flex justify-between">
              <h2 className="text-xs">Token <br />
                <div className="flex">
                  <p className="font-bold ">145440392767666 </p>
                  <button
                    className="bg-black text-white text-[10px] border rounded-lg pl-2 pr-2"
                    onClick={handleCopyClick}>copy
                  </button>

                </div>
              </h2>
              <img src={met2} alt="scanPic" className="w-[35%] h-[35%]" />
            </div>
            <div className="text-[8px] -mt-[14%]">
              <h2>SHARE VIA</h2>
              <div className="flex justify-start gap-4 ">
                <WhatsApp className="border rounded-full  bg-[#E2FCEF] p-1 text-green-500" />
                <FacebookRounded className="border rounded-full bg-[#E9F1FF] p-1 text-blue-700" />
                <Twitter className="border rounded-full  bg-[#EAF6FE] p-1 text-blue-300" />
                <EmailRounded className="border rounded-full  bg-[#FCF2EB] p-1 text-[#CB324C]" />
              </div>
            </div>
          </div>

          <div className="border-b mt-4 mb-2 pb-2 text-[8px]">
            <h2>Please retain this invoice for your reference</h2>
            <h2>Sales tax is included</h2>
            <h2>For questions regarding this invoice please email us</h2>
          </div>

          <div className="flex gap-2 justify-center mt-8 mb-4">
            <button
              className="flex w-32 h-10 gap-4 justify-center items-center border rounded-md text-[#1F6FE3] border-black bg-[#1f6fe340]"
            >
              <Download />
              <p>Download</p>
            </button>
            <button
              className="flex w-32 h-10 gap-4 justify-center items-center border rounded-md text-[#1F6FE3] border-black bg-[#1F6FE340]"
            >
              <Print />
              <p>Print</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerInvoice;