import React, { useState, useEffect, useCallback } from "react";
import { call, down, flag, proceed } from "../asset/index";
import { useNavigate } from "react-router-dom";
import { EmailOutlined } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Support = () => {
  const navigate = useNavigate();
  const handleBack = useCallback(() => navigate(-1), [navigate]);
  const [agentData, setAgentData] = useState({ name: "", email: "", phone: "", message: "", });
  const [customerData, setCustomerData] = useState({ name: "", email: "", phone: "", message: "", });
  const [tab, setTab] = useState("agent");
  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  useEffect(() => {
    setTab("agent");
  }, []);

  const handleTabChange = (selectedTab) => {
    setTab(selectedTab);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = tab === "agent" ? agentData : customerData;
    if (!formData.name || !formData.email || !formData.phone || !formData.message) {
      toast.error("Please fill in all required fields");;
      return;
    }
    if (!isValidEmail(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    if (!isValidPhoneNumber(formData.phone)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    try {
      const response = await fetch(
        `${energyUrl}/interests`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        })

      if (response.ok) {
        const result = await response.json()
        toast.success(result.message)

        setTimeout(() => {
          navigate('/interestReceived');
        }, 2000)
      } else {
        toast.error("Failed to submit the form")
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form");
    }

    if (tab === "agent") {
      setAgentData({ name: "", email: "", phone: "", message: "", role: "agent" });
    } else {
      setCustomerData({ name: "", email: "", phone: "", message: "", role: "customer" });
    }
  }

  const handleInputChange = (field, value) => {
    if (tab === "agent") {
      setAgentData((prevData) => ({ ...prevData, [field]: value, role: "agent" }));
    } else {
      setCustomerData((prevData) => ({ ...prevData, [field]: value, role: "customer" }));
    }
  };

  return (
    <div className="bg-[#F7F8FA] ">
      <ToastContainer />
      <div className="flex pt-[10%] border-b justify-center gap-[55%] pb-[6%] items-center">
        <h2 className="font-bold">Get Started</h2>
        <img src={proceed} alt="logoImg" className="h-6" onClick={handleBack} />
      </div>

      <div className="flex flex-col justify-center items-center gap-4 border-t text-xs">
        <div>
          <div className="flex justify-center items-center mt-4">
            <img src={call} alt="callImg" />
            <h2>Call Support</h2>
          </div>
          <h2 className="font-bold">+234 091 200 00601</h2>
        </div>

        <div>
          <div className="flex justify-center items-center">
            <EmailOutlined />
            <h2>Email Support</h2>
          </div>
          <h2 className="font-bold">info@rokswoodgas.com</h2>
        </div>
      </div>

      <div className="p-6 pb-24">
        <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
          <form onSubmit={handleSubmit}>
            <div>
              <h2 className="text-black font-bold mb-8 text-xs">
                Select User Type
              </h2>
              <div className="flex justify-center border rounded-full gap-10 text-sm text-black p-3 mb-[15%] cursor-pointer">
                <div
                  className={`text-sm text-gray-400 hover:border hover:rounded-full hover:bg-[#1F6FE2] hover:text-white pl-5 pr-5 pt-2 pb-2 ${tab === "agent" ? "hover:text-blue-700" : ""}`}
                  onClick={() => handleTabChange("agent")}
                >
                  Agent
                </div>
                <div
                  className={`text-sm text-gray-400 hover:border hover:rounded-full hover:bg-[#1F6FE2] hover:text-white pl-2 pr-2 pt-2 pb-2 ${tab === "customer" ? "hover:text-blue-700" : ""}`}
                  onClick={() => handleTabChange("customer")}
                >
                  Customer
                </div>
              </div>
            </div>

            <div className="mb-4">
              <div className="relative">
                <input
                  type="text"
                  className="w-full bg-[#ffffff] border rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                  placeholder="Name"
                  value={tab === "agent" ? agentData.name : customerData.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                />
              </div>
            </div>

            <div className="mb-4">
              <div className="relative">
                <input
                  type="text"
                  className="w-full bg-[#ffffff] border rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                  placeholder="Email"
                  value={tab === "agent" ? agentData.email : customerData.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
              </div>
            </div>

            <div className="mt-4 mb-4">
              <div className="relative w-full flex bg-[#ffffff] items-center border rounded-md focus:outline-none focus:border-[#1F6FE2]">
                <img src={flag} alt="flagImg" className="ml-4 mr-1 w-8 h-5" />
                <img src={down} alt="flagImg" className="mr-2 w-3 h-3" />

                <input
                  type="text"
                  className=" bg-[#ffffff] focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                  placeholder="+234 803 000 0000"
                  value={tab === "agent" ? agentData.phone : customerData.phone}
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                />
              </div>
            </div>

            <div className="mb-4">
              <div className="relative">
                <textarea
                  type="message"
                  className="w-full h-40 bg-[#ffffff] border rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                  placeholder="Your message"
                  value={tab === "agent" ? agentData.message : customerData.message}
                  onChange={(e) => handleInputChange("message", e.target.value)}
                />
              </div>
            </div>
            <button className="w-full pt-4 pb-4 text-white bg-[#1F6FE2] hover:bg-white hover:text-[#1F6FE2] border text-sm font-bold rounded-md">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Support;

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isValidPhoneNumber = (phone) => {
  const phoneRegex = /^\d+$/;
  return phoneRegex.test(phone);
};