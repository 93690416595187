import React from 'react'

const Footer = () => {
  return (
    <footer style={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#3E3E3E', color: 'white', padding: '1rem', textAlign: 'center' }}>
    <h2>Copyright © 2024 Rokswood Energy</h2>
  </footer>
  )
}

export default Footer