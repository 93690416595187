import React, { useState, useEffect } from "react";
import { back } from "../asset";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";

const FirstSplashPage = () => {
    const navigate = useNavigate()
    const [moveToSplashTwo, setMoveToSplashTwo] = useState(false)

    useEffect(() => {
        const stayTime = setTimeout(() => {
            setMoveToSplashTwo(true)
            navigate('/splash')
        }, 4000)
        return () => clearTimeout(stayTime)
    }, [navigate])

    if (moveToSplashTwo) {
        return null
    }

    return (
        <div className="min-h-screen bg-[#1F6FE2] flex flex-col justify-center items-center overflow-y-hidden">
            <div className="flex justify-center items-center translate-y-[100%]">
                <img src={back} alt="backgroundImg" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                <Spin size="large" className="text-white" />

            </div>
            <div className="text-[#1F6FE2] pt-[50%] pb-[20%] bg-[#1F6FE2]">
            </div>
        </div>
    );
};

export default FirstSplashPage;