import React, { useEffect, useState, useCallback } from "react";
import { MdOutlineMoreVert } from "react-icons/md";
import { proceed } from "../asset";
import { Link, useNavigate } from "react-router-dom";

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()
  const handleBack = useCallback(() => navigate(-1), [navigate]);

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const response = await fetch(
          `${energyUrl}/users/me/orders?detailed=true`,
          {
            method: "GET",
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch order history");
        }

        const data = await response.json();
        setOrders(data.data.items);
      } catch (error) {

      } finally {
        setLoading(false);
      }
    };

    fetchOrderHistory();
  }, [energyUrl]);


  return (
    <div className="bg-[#F7F8FA] ">
      <div className="flex pt-[10%] justify-center gap-[40%] pb-[6%]">
        <h2 className="font-bold">Order History</h2>
        <img src={proceed} alt="logoImg" className="h-6 cursor-pointer" onClick={handleBack} />
      </div>

      <div className="p-6 pb-[40%]">
        <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
          <div className="flex justify-between mb-[10%]">
            <h2 className="text-black font-semibold text-sm">Order History</h2>
            <MdOutlineMoreVert />
          </div>

          <div className="text-[10px] pb-4">
            {loading ? (
              <div className="flex justify-center items-center text-xs text-green-600">Loading...</div>
            ) : (
              orders.map((order, idx) => (
                <div
                  key={idx}
                  className="flex justify-between  pt-4 pb-4 border-b">
                  <h2>{order.serial_number}</h2>
                  <h2>{new Date(order.created_at).toLocaleDateString()}</h2>
                  <h2>{order.weight}kg</h2>
                  <h2 className="text-green-500">{`₦${order.amount}`}</h2>

                  {((order.status === "pending") ||
                    (order.status === "failed")) &&
                    order.method === "paystack" ? (
                    <h2
                      className="text-red-500"
                    >
                      <p>Retry</p>
                    </h2>
                  ) : (
                    <Link to={`/revampInvoice/${order.id}`}>View </Link>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;