import React, { useState, useEffect, useCallback } from "react";
import { plus, proceed, userG } from "../asset";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineMoreVert } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomerUnitProfile = () => {
    const { id } = useParams()
    const [customerDetails, setCustomerDetails] = useState(null)
    const [orderHistory, setOrderHistory] = useState([])
    const navigate = useNavigate()
    const handleBack = useCallback(() => navigate(-1), [navigate]);


    const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

    useEffect(() => {
        const fetchCustomerDetails = async () => {
            try {
                const response = await fetch(`${energyUrl}/agents/me/customers/${id}?detailed=true`, {
                    headers: {
                        token: localStorage.getItem('token'),
                        "Content-Type": "application/json",
                    }
                })

                const data = await response.json()

                if (data.status === "success") {
                    setCustomerDetails(data.data)
                } else {
                    toast.error("Failed to fetch customers details", data.message)
                }
            } catch (error) {
                toast.error("Network error while fetching customers details", error)
            }
        }


        const fetchOrderHistory = async () => {
            try {
                const response = await fetch(`${energyUrl}/agents/me/customers/${id}/orders`, {
                    headers: {
                        token: localStorage.getItem('token'),
                        "Content-Type": "application/json",
                    }
                })

                const data = await response.json()

                if (data.status === "success") {
                    setOrderHistory(data.data)
                } else {
                    toast.error("Failed to fetch Order History", data.message)
                }
            } catch (error) {
                toast.error("Network error while fetching Order History", error)
            }
        }

        fetchCustomerDetails()
        fetchOrderHistory()
    }, [energyUrl, id])



    return (
        <div className="bg-[#F7F8FA] ">
            <ToastContainer />
            <div className="flex pt-2 gap-[20%] justify-end border-b items-center">
                <img src={proceed} alt="logoImg" className="max-w-md mx-auto h-6 cursor-pointer" onClick={handleBack} />
                <h2 className="max-w-md mx-auto p-4 font-medium">Customer</h2>
            </div>
            <div className="flex pt-[10%] justify-center gap-[20%] items-center">
                {customerDetails && (
                    <>
                        <div className="flex flex-row items-center justify-between gap-4">
                            <div>
                                <h2 className="font-bold">User</h2>
                                <h2 className="font-thin text-xs">{customerDetails?.role}</h2>

                            </div>
                            <div className="w-[1px] h-7 bg-[#7F9395]"></div>
                            <div className="relative text-xs">
                                <div>
                                    <h2 className="font-bold text-[#FF9D00]">Meter Number</h2>
                                    <h2 className="font-thin text-xs">{customerDetails?.meter?.meter_number}</h2>
                                </div>
                            </div>
                        </div>

                        <Link to={`/meterForm/${id}`}>
                            <div className="flex items-center justify-center border text-xs text-white p-2 rounded-md bg-gray-500 hover:text-gray-500 hover:bg-white">
                                <img src={plus} alt="logoImg" className="w-8 h-8" />
                            </div>
                            <span className="text-[10px] flex items-center justify-center">Add New Meter</span>
                        </Link>
                    </>
                )}
            </div>

            <div className="p-4 pb-20">
                {customerDetails && (
                    <>
                        <div className="max-w-md mx-auto rounded-lg p-4 bg-white">
                            <div className="flex flex-row items-center justify-between mx-auto px-4 mt-[10%] text-xs">

                                <img src={userG} alt="userImg" className="border rounded-full border-black p-4" />
                                <div>
                                    <h2 className="font-bold text-[#1F6FE2]">SURNAME</h2>
                                    <h2 className="font-thin ">{customerDetails.lastname}</h2>
                                </div>
                                <div>
                                    <h2 className="font-bold text-[#1F6FE2]">FIRST NAME</h2>
                                    <h2 className="font-thin">{customerDetails.firstname}</h2>
                                </div>
                            </div>
                            <div className="w-full h-[1px] bg-[#7F9395] mt-[6%]"></div>

                            <div className="mt-[2%] grid grid-cols-2 text-[10px] gap-4 max-w-md mx-auto p-4 items-center justify-between">
                                <div>
                                    <h2 className="text-[#1F6FE2] font-bold">OTHER NAME</h2>
                                    <h2> {customerDetails.company_name || "N/A"}</h2>
                                </div>
                                <div>
                                    <h2 className="text-[#1F6FE2] font-bold">GENDER</h2>
                                    <h2>{customerDetails.gender}</h2>
                                </div>
                                <div>
                                    <h2 className="text-[#1F6FE2] font-bold">COUNTRY</h2>
                                    <h2>{customerDetails.country}</h2>
                                </div>
                                <div>
                                    <h2 className="text-[#1F6FE2] font-bold">STATE</h2>
                                    <h2>{customerDetails.state || "N/A"}</h2>
                                </div>
                                <div>
                                    <h2 className="text-[#1F6FE2] font-bold">DATE OF BIRTH</h2>
                                    <h2>{new Date(customerDetails.dob).toDateString()}</h2>
                                </div>
                                <div>
                                    <h2 className="text-[#1F6FE2] font-bold">EMAIL</h2>
                                    <h2>{customerDetails.email}</h2>
                                </div>
                                <div>
                                    <h2 className="text-[#1F6FE2] font-bold">ADDRESS</h2>
                                    <h2>
                                        {customerDetails.address}
                                    </h2>
                                </div>
                                <div>
                                    <h2 className="text-[#1F6FE2] font-bold">PHONE NUMBER</h2>
                                    <h2>{customerDetails.phone}</h2>
                                </div>
                                <div>
                                    <h2 className="text-[#1F6FE2] font-bold">STATUS</h2>
                                    <h2 className="font-bold">{customerDetails.status}</h2>
                                </div>
                                <div>
                                    <h2 className="text-[#1F6FE2] font-bold">AGENT</h2>
                                    <h2>{customerDetails.agent.firstname} {customerDetails.agent.lastname}</h2>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
                    <div className="flex justify-between mb-[10%]">
                        <h2 className="text-black font-semibold text-sm">Order History</h2>
                        <MdOutlineMoreVert />
                    </div>

                    <div className="text-[10px] pb-4" >
                        {Array.isArray(orderHistory) && orderHistory.length > 0 ? (orderHistory.map((order, idx) => (
                            <div key={idx} className="flex justify-between pt-4 pb-4 border-b border-t">
                                <h2>{orderHistory.number}</h2>
                                <h2>{orderHistory.total.toLocaleString()}</h2>
                                <h2 className="font-bold">{orderHistory.total_kg_purchased}KG</h2>
                            </div>
                        ))
                        ) : (
                            <p className="flex items-center justify-center">No order history found</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerUnitProfile;