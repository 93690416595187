import React, { useState, useEffect } from "react";
import axios from "axios";

const VolumeGauge = ({ data }) => {
  const [volumeGaugeData, setVolumeGaugeData] = useState([]);
  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${energyUrl}/agents/me/unitgroups?detailed=true&page=${currentPage}&pageSize=${itemsPerPage}`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        });
        setVolumeGaugeData(response.data.data.items);
      } catch (error) {
        console.error("Error fetching volume gauge data:", error);
      }
    };

    fetchData();
  }, [currentPage, energyUrl]);

  return (
    <div>
      {volumeGaugeData.map((item, index) => (
        <div key={index} className="flex items-center justify-between text-xs mt-[7%] border-t border-white border-spacing-4 pt-[7%] ">
          <h2>{item.name}</h2>
          <div className="relative w-[50%] h-3 bg-gray-200 rounded-full">
            <div
              className={`absolute h-full rounded-full ${item.total_customers < 50 ? "bg-red-500" : "bg-green-500"
                }`}
              style={{
                width: `${item.total_customers}%`,
              }}
            />
          </div>
          <span className="text-blue-500">{`${item.total_customers}%`}</span>
        </div>
      ))}
    </div>
  );
};

export default VolumeGauge;
