import React, { useCallback } from 'react'
import { Rgas } from '../../asset'
import { ArrowBackOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { indicator } from '../../assetPicknDrop';

const OrderSummary = () => {
    const navigate = useNavigate();
    const handleBack = useCallback(() => navigate(-1), [navigate]);
    return (
        <div>
            <div className='flex items-center justify-between h-10 mt-8 rounded-3xl ml-[6%] mr-[6%]'>
                <ArrowBackOutlined className='border rounded-full p-1 cursor-pointer' onClick={handleBack} />
                <p className='text-xs text-[#A7A9B7]'>New Request</p>
                <img src={Rgas} alt='LogoImg' className='w-4 h-6' />
            </div>
            <div>
                <p className='text-xs font-medium ml-[6%] mt-8'>Select Product</p>

                <div className='grid text-xs p-3'>
                    <div className='flex flex-col p-3  rounded-md'>
                        <p className='font-semibold text-xs text-blue-400'>Sender</p>
                        <p className='font-semibold'>23 Ring road, Ikeja, Lagos State</p>
                        <p className='text-[11px] flex'> Rokswood Gas  {'\u00a0'}<img src = {indicator} alt='pixImg' className='w-2 h-2 mt-1'/>{'\u00a0'}<span className='font-semibold'>07000000000</span></p>
                    </div>

                    <div className='flex flex-col p-3  rounded-md'>
                        <p className='font-semibold text-xs text-blue-400'>Recipient</p>
                        <p className='font-semibold'>30 Ring road, Ikeja, Lagos State</p>
                        <p className='text-[11px] flex'> John Doe  {'\u00a0'}<img src = {indicator} alt='pixImg' className='w-2 h-2 mt-1'/>{'\u00a0'} <span className='font-semibold'>07000000000</span></p>
                    </div>

                    <div className='flex flex-col p-3  rounded-md'>
                        <p className='font-semibold text-xs text-blue-400'>Item</p>
                        <p className='font-semibold'>3KG Cooking Gas</p>
                        <p className='text-[11px]'> Quantity{'\u00a0'}<span className='font-semibold'>1</span></p>
                    </div>

                    <div className='bg-[#3361ff] flex items-center justify-between p-3 pl-5 pr-5 text-white rounded-xl'>
                        <div>
                            <p className='text-[10px]'>Product</p>
                            <p className='font-semibold text-xl'>₦2,700</p>
                        </div>
                        <div style={{ backgroundColor: "#ccc", width: 2, height: "80%" }} />
                        <div>
                            <p className='text-[10px]'>Delivery Fee</p>
                            <p className='font-semibold text-xl'>₦700</p>
                        </div>
                        <div style={{ backgroundColor: "#ccc", width: 2, height: "80%" }} />
                        <div>
                            <p className='text-[10px]'>Total</p>
                            <p className='font-semibold text-xl'>₦3,400</p>
                        </div>
                    </div>

                    <div className='flex flex-col p-3  rounded-md'>
                        <p className='font-semibold text-xs mt-4'>Payment Method</p>
                        <div className='font-semibold mt-4'>
                            <div className='bg-gray-100 p-4 rounded-md text-[15px] text-gray-800 hover:text-blue-500'>
                                <input type='radio' id='card' name='paymentMethod' value='Card' />
                                <label htmlFor='card'> {'\u00a0'}Card</label>
                            </div>
                            <br />

                            <div className='bg-gray-100 p-4 rounded-md text-[15px] text-gray-800 hover:text-blue-500'>
                                <input type='radio' id='payOnDelivery' name='paymentMethod' value='Pay on Delivery' className='' />
                                <label htmlFor='payOnDelivery'> {'\u00a0'}Pay on Delivery</label>
                            </div>
                        </div>
                    </div>

                    <Link to='/pLogin'>
                        <button className="w-full mt-[15%] mb-[10%] pt-4   pb-4 text-[#ffffff] bg-[#1F6FE2]  border border-[#7F9395] text-sm font-bold rounded-md">
                            Proceed
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default OrderSummary