import React from 'react'
import { Link } from 'react-router-dom'

const ModalCode = ({ closeModal }) => {
  return (
    <div className="bg-[#00000026] fixed top-0 left-0 w-full h-[100vh] flex justify-center items-center z-50">
      <div className="rounded-md p-12 bg-[#F9F9F9] relative ml-[4%] mr-[4%] flex flex-col justify-center items-center">
        <h2 className='font-bold'>Delivery Confirmation Code</h2>
        <p className="text-xs mt-4 text-center">
          Please provide the rider with this code to confirmed that you’ve receive your product as requested.
        </p>
        <p className='text-3xl font-bold mt-8'>1234</p>


        <button
          className="w-fit p-4 pl-[10%] pr-[10%] text-white mt-[10%] hover:text-[#1F6FE2] hover:bg-white bg-[#1F6FE2] border border-[#7F9395] text-sm font-bold rounded-md"
          onClick={closeModal}>
          <Link to='/pickFeedback'>  Done </Link>
        </button>

      </div>

    </div>
  )
}

export default ModalCode
