import React, { useState } from "react";
import { proceed } from "../asset/index";
import { Link } from "react-router-dom";
const EditProfile = () => {
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [logo, setLogo] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  return (
    <div className="bg-[#F7F8FA] ">
      <div className="flex pt-[10%] justify-center gap-[49%] items-center">
        <h2 className="font-bold">Edit Profile</h2>
        <Link to='/profile'>
          <img src={proceed} alt="logoImg" className="h-6" />
        </Link>
      </div>

      <div className="p-6 pb-24">
        <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
          <div className="mb-4">
            <label className="text-[#7F9395] text-bold text-sm">Surname</label>
            <div className="relative">
              <input
                type="text"
                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                placeholder="Enter Surname"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="text-[#7F9395] text-bold text-sm"> First Name </label>
            <div className="relative">
              <input
                type="text"
                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                placeholder="Enter First name"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="text-[#7F9395] text-bold text-sm"> Other Name </label>
            <div className="relative">
              <input
                type="text"
                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                placeholder="Enter Other Name"
              />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="gender" className="block text-[#7F9395] text-bold text-sm"> Gender </label>
            <select
              id="gender"
              name="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="mt-4 mb-4">
            <label htmlFor="dob" className="text-[#7F9395] text-bold text-sm"> Date of Birth </label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            />
          </div>
          <div className="mt-4 mb-4">
            <label htmlFor="phoneNumber" className="text-[#7F9395] text-bold text-sm"> Company Logo </label>
            <input
              type="file"
              name="logoInd"
              accept="image/*"
              value={logo}
              onChange={(e) => setLogo(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 mb-2"
              placeholder="Company Logo" />
          </div>

          <div className="mb-4">
            <label className="text-[#7F9395] text-bold text-sm">Email</label>
            <div className="relative">
              <input
                type="text"
                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                placeholder="Enter email address"
              />
            </div>
          </div>
          <div className="mt-4 mb-4">
            <label htmlFor="phoneNumber" className="text-[#7F9395] text-bold text-sm"> Phone Number </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
              placeholder="+1-123-456-7890"
            />
          </div>

          <div className="mt-4">
            <label htmlFor="country" className="block text-[#7F9395] text-bold text-sm"> Country </label>
            <select
              id="con"
              name="con"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
            >

              <option value="male">Nigeria</option>
              <option value="female">Ghana</option>
              <option value="other">Namibia</option>
              <option value="other">Rwanda</option>
              <option value="other">Canada</option>
              <option value="other">USA</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="text-[#7F9395] text-bold text-sm">State</label>
            <div className="relative">
              <input
                type="text"
                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                placeholder="Enter State"
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="text-[#7F9395] text-bold text-sm"> Residential Address </label>
            <div className="relative">
              <input
                type="message"
                className="w-full h-40 bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                placeholder="Enter Address"
              />
            </div>
          </div>
          <button className="w-full mt-[10%] mb-[10%] pt-4   pb-4 text-[#ffffff] bg-[#bed5f7] border border-[#7F9395] text-sm font-bold rounded-md">
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;