import React from 'react'
import { Rgas } from '../asset';

const Payment = () => {
    return (
        <div className="bg-[#00000073] ">
            <div className="p-6 pb-[30%] pt-[20%]">            
                <div className="flex flex-col items-center max-w-md mx-auto rounded-2xl mt-4 p-4 bg-white">
                    <img src={Rgas} alt='confirmImg' className='w-24 h-40' />
                    <h2 className='text-[#1F6FE2] font-bold text-sm'>Your have successfully placed your token order</h2>
                    <h2 className='text-[#e21f1f] font-bold text-sm'>One last step to go</h2>                  
                    <h2 className='text-xs font-semibold mb-[30%] pt-[5%]'>Click below to finalize your order</h2>
                     <button className="w-full p-4 text-white hover:text-[#1fe21f] hover:bg-white bg-[#1fe21f] border border-[#7F9395] text-sm font-bold rounded-md">
                        Finalize your Order
                    </button>                    
                </div>
            </div>
        </div>
    );
}
export default Payment