import React, { useState } from "react";
import { hide, userReg, Rgas } from "../asset/index";
import { Link, useNavigate } from 'react-router-dom';
import { useLogin } from "../hook/useLogin";
import LoadingScreen from "../loading/LoadingScreen";
import { animateScroll as scroll } from 'react-scroll';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const { login, isLoading } = useLogin()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter your email');
      return;
    } else {
      setEmailError('');
    }

    if (!password) {
      setPasswordError('Please enter your password');
      return;
    } else {
      setPasswordError('');
    }

    try {
      setLoading(true);
      setError(null);
      const response = await login(email, password);

      if (response?.data?.role === 'agent') {
        navigate('/dashboard');
      } else if (response?.data?.role === 'individual' || response?.data?.role === 'business') {
        navigate('/customerDashboard');
      } else {
        setError("Something went wrong make sure you are a customer or agent")
      }
      scroll.scrollToTop({ duration: 300, smooth: true });

    } catch (error) {
      setError("An error occurred while logging in, Please try again");
    } finally {
      setLoading(false);
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };
  if (isLoading) {
    return <LoadingScreen />
  }

  const handleClearPassword = () => {
    setPassword('');
  };

  return (
    <div className="overflow-y-hidden bg-[#F7F8FA] p-4">
      <ToastContainer />
      <div className="pt-[20%] justify-center items-center flex animate-bounce">
        <img src={Rgas} alt="logoImg" className="left-40 w-[12%] h-[14%]" />
      </div>
      <div className="max-w-md mx-auto mt-[10%] p-4">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="text-gray-700 text-bold">Email</label>
            <div className="relative">
              <input
                type="text"
                className="w-full bg-[#F7F8FA] border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
                placeholder="enter your email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <div className="absolute top-0 right-0 m-2">
                <img
                  src={userReg} alt="personImg"
                  className="h-4 w-3 text-black"
                />
              </div>
            </div>
            {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
          </div>

          <div className="mb-4 pt-4">
            <label className="text-gray-700 text-bold">Password</label>
            <div className="relative">
              <input
                type={passwordVisibility ? "password" : "text"}
                className="w-full bg-[#F7F8FA] border-b border-black  text-sm focus:outline-none focus:border-[#1F6FE2] py-2"
                placeholder="enter your password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                onClick={togglePasswordVisibility}
              />
              <div className="absolute top-0 right-0 m-2">
                <img
                  src={hide} alt="hideImg"
                  className="h-3 w-4 text-black"
                />
              </div>
              {password && (
                <button
                  type="button"
                  onClick={handleClearPassword}
                  className="ml-2 text-[10px] -mt-8 text-gray-500 hover:text-gray-700"
                >
                  Clear password
                </button>
              )}
            </div>
            {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
          </div>

          <h2 className="text-sm">forget password? <Link to='/reset'><span className="text-bold text-[#1F6FE2]  border-b border-[#1F6FE2]">click here to reset</span></Link></h2>

          <button
            onClick={handleSubmit}
            className="w-full mt-[30%] mb-[20%] pt-4 pb-4 text-[#1F6FE2] hover:bg-[#1F6FE2] hover:text-white border border-[#1F6FE2] text-sm font-bold rounded-md"
            disabled={isLoading}>
            {loading ? "Signing in..." : "SIGN IN"}
          </button>
          {error && <div className="error text-[12px] text-red-500 -mt-4 -pt-4 flex justify-center">{error}</div>}
          <Link to="/support">
            <p className="text-sm flex justify-center items-center mb-[30%]">Don't have an account? <span className="underline text-[#1F6FE2] cursor-pointer">click here</span></p>
          </Link>
        </form>
      </div>
    </div>
  )
};
export default Login;