import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { indicator, locating, record } from '../../assetPicknDrop';
import Map from '../../components/map/Map';

const AssigningRider = () => {
    const navigate = useNavigate()
    const [userLocation, setUserLocation] = useState(null)
    const [riderLocation, setRiderLocation] = useState({ lat: 0, lng: 0})
    const [moveToSplashTwo, setMoveToSplashTwo] = useState(false)  

    useEffect(()=>{
        navigator.geolocation.getCurrentPosition(
            (position) => {
            setUserLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            })
        },
        (error) => console.error ('Error getting user location:', error)
       )
    }, []);

    useEffect(()=>{
        const updateLocationInterval = setInterval(()=> {
            setRiderLocation({
                lat: riderLocation.lat + 0.02,
                lng: riderLocation.lng + 0.02
            })
        }, 1000)
        return () => clearInterval(updateLocationInterval)
    }, [riderLocation])

    useEffect(() => {
        const stayTime = setTimeout(() => {
            setMoveToSplashTwo(true)
            navigate('/dispatchRider')
        }, 4000)
        return () => clearTimeout(stayTime)
    }, [navigate])

    if (moveToSplashTwo) {
        return null
    }
    
    return (
        <div>            
            <div>
                <div className='ml-[0%] w-full h-[40vh]'>
                <Map riderLocation={userLocation} />
                </div>

                <div className='grid text-xs p-3 ml-[6%] mr-[6%]'>
                    <div className='flex flex-col p-3  rounded-md'>
                    <div className = 'flex gap-2  -ml-[9%]'>
                    <div>
                    <img src = {record} alt = 'recordImg' className='w-4 h-4'  />
                    <div className='ml-[40%]'>
                    <div>|</div>
                    <div>|</div>
                    <div>|</div>                                     
                    </div>
                    </div>
                   
                        <p className='font-semibold text-xs text-[#33ccff]'>Sender</p>
                    </div>
                   
                        <p className='font-semibold -mt-[18%]'>23 Ring road, Ikeja, Lagos State</p>
                        <p className='text-[11px] flex'> Rokswood Gas  {'\u00a0'}<img src = {indicator} alt='pixImg' className='w-2 h-2 mt-1'/>{'\u00a0'}<span className='font-semibold'>07000000000</span></p>
                    </div>

                    <div className='flex flex-col p-3  rounded-md'>
                    <div className='flex gap-2  -ml-[9%]'>
                    <img src = {locating} alt = 'recordImg'  className='w-4 h-4' />
                        <p className='font-semibold text-xs text-[#33ccff]'>Recipient</p>
                    </div>
                   
                        <p className='font-semibold'>30 Ring road, Ikeja, Lagos State</p>
                        <p className='text-[11px] flex'> John Doe  {'\u00a0'}<img src = {indicator} alt='pixImg' className='w-2 h-2 mt-1'/>{'\u00a0'} <span className='font-semibold'>07000000000</span></p>
                    </div>

                    <div className='flex flex-col p-3  rounded-md'>
                        <p className='font-semibold text-xs text-[#33ccff]'>Item</p>
                        <p className='font-semibold'>3KG Cooking Gas</p>
                        <p className='text-[11px]'> Quantity{'\u00a0'}<span className='font-semibold'>1</span></p>
                    </div>

                    <div className='bg-[#3361ff] flex items-center justify-between p-3 pl-5 pr-5 text-white rounded-xl'>
                        <div>
                            <p className='text-[10px]'>Product</p>
                            <p className='font-semibold text-xl'>₦2,700</p>
                        </div>
                        <div style={{ backgroundColor: "#ccc", width: 2, height: "80%" }} />
                        <div>
                            <p className='text-[10px]'>Delivery Fee</p>
                            <p className='font-semibold text-xl'>₦700</p>
                        </div>
                        <div style={{ backgroundColor: "#ccc", width: 2, height: "80%" }} />
                        <div>
                            <p className='text-[10px]'>Total</p>
                            <p className='font-semibold text-xl'>₦3,400</p>
                        </div>
                    </div>

                    <div className='flex p-3 gap-2'>
                        <div className = 'bg-red-500 rounded-full border w-4 h-4 animate-bounce direction-normal '></div>
                        <div className = 'bg-blue-500 rounded-full border w-4 h-4 animate-bounce direction-reverse '></div>
                        <div className = 'bg-yellow-500 rounded-full border w-4 h-4 animate-bounce direction-alternate'></div>
                        <div className = 'text-xs animate-bounce spin'>...fulfilling order</div>
                       
                    </div>                    
                </div>
            </div>
        </div>
    )
}

export default AssigningRider