import React, {useCallback} from 'react'
import { Rgas } from '../../asset'
import { ArrowBackOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

const ProductOrderDetails = () => {
    const navigate = useNavigate();
    const handleBack = useCallback(() => navigate(-1), [navigate]);

    return (
        <div>
            <div className='flex items-center justify-between h-10 mt-8 rounded-3xl ml-[6%] mr-[6%]'>
                <ArrowBackOutlined className='border rounded-full p-1 cursor-pointer' onClick={handleBack} />
                <p className='text-xs text-[#A7A9B7]'>New Request</p>
                <img src={Rgas} alt='LogoImg' className='w-4 h-6' />
            </div>

            <div className="p-6 pb-12">
                <div className="max-w-md mx-auto rounded-lg mt-4 p-4 bg-white">
                    <div className="mb-4">
                        <label className="text-bold text-sm">Full Name</label>
                        <div className="relative">
                            <input
                                type="text"
                                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                                placeholder="Enter Name"
                            />
                        </div>
                    </div>
                    <div className="mt-4 mb-4">
                        <label htmlFor="phoneNumber" className="text-bold text-sm"> Phone Number </label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            //   value={phoneNumber}
                            //   onChange={(e) => setPhoneNumber(e.target.value)}
                            className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                            placeholder="Enter Phone Number"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="text-bold text-sm">City/State</label>
                        <div className="relative">
                            <input
                                type="text"
                                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                                placeholder="City/State"
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="text-bold text-sm"> Drop-off Location </label>
                        <div className="relative">
                            <input
                                type="text"
                                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                                placeholder="Address"
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="text-bold text-sm"> House/Apartment Number {'\u00a0'}<span className='text-gray-400'>(Optional)</span> </label>
                        <div className="relative">
                            <input
                                type="text"
                                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                                placeholder="Add"
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="text-bold text-sm">Landmark {'\u00a0'}<span className='text-gray-400'>(Optional)</span> </label>
                        <div className="relative">
                            <input
                                type="text"
                                className="w-full bg-[#F7F8FA] border border-[#7F9395] rounded-md focus:outline-none focus:border-[#1F6FE2] text-xs p-4"
                                placeholder="Add"
                            />
                        </div>
                    </div>

                    <Link to='/orderSummary'>
                        <button className="w-full mt-[10%] mb-[10%] pt-4   pb-4 text-[#ffffff] bg-[#1F6FE2]  border border-[#7F9395] text-sm font-bold rounded-md">
                            Proceed
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ProductOrderDetails