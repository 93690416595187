// PasswordAuth.js
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PasswordAuth = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter your email');
      return;
    } else {
      setEmailError('');
    }

    if (!password) {
      setPasswordError('Please enter your password');
      return;
    } else {
      setPasswordError('');
    }

    try {
      setLoading(true);

      const response = await axios.put(`${energyUrl}/auth/reset/${token}`, {
        email,
        password,
        confirm_password: confirmPassword,
      });
      await toast.success(response.data.message);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      await toast.error("Failed to reset password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="overflow-y-hidden bg-[#ffffff] p-4 mt-12 mb-12">
      <h2 className="max-w-md mx-auto mt-[10%] p-4 font-medium">
        Check your email for the for the authentication token needed for the completion of your password reset </h2>
      <div className="max-w-md mx-auto mt-[10%] p-4 text-sm">

        <div className="mb-8 ">
          <label className="text-gray-700 text-bold text-sm">Token From Email</label>
          <div className="relative">
            <input
              type="text"
              className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
              placeholder="enter token pin"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="mb-8 ">
          <label className="text-gray-700 text-bold">Enter Email</label>
          <div className="relative">
            <input
              type="email"
              className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
              placeholder="enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
        </div>

        <div className="mb-8 ">
          <label className="text-gray-700 text-bold">Enter New Password</label>
          <div className="relative">
            <input
              type="text"
              className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
              placeholder="enter your new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
        </div>

        <div>
          <label className="text-gray-700 text-bold">Confirm password</label>
          <div className="relative">
            <input
              type="text"
              className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
              placeholder="confirm your new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={loading}
          onClick={handleSubmit}
          className="w-full mt-[20%] mb-[20%] pt-4 pb-4 text-[#1F6FE2] hover:bg-[#1F6FE2] hover:text-white border border-[#1F6FE2] text-sm font-bold rounded-md"
        > {loading ? "Resetting..." : "Complete Reset Password"}
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};

export default PasswordAuth;