import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Rgas, proceed } from '../asset';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdCheckCircleOutline, MdReorder, MdShare } from 'react-icons/md';
import QRCode from 'qrcode.react';


const Receipt = () => {
    const location = useLocation();
    const { gasVolume = '', totalAmount = 0, meterNumbers = '', } = location.state || {};
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const energyUrl = process.env.REACT_APP_ENERGY_BASE_URL
    const handleBack = useCallback(() => navigate(-4), [navigate]);

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Share Receipt',
                text: 'Check out this receipt!',
                url: window.location.href
            })
                .then(() => console.log('Successfully shared'))
                .catch((error) => console.error('Error sharing:', error));
        } else {
            console.log('Web Share API not supported');
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const response = await fetch(
                    `${energyUrl}/users/me?detailed=true`,
                    {
                        headers: {
                            token: localStorage.getItem('token'),
                            "Content-Type": "application/json"
                        },
                    }
                );
                if (response.ok) {
                    const data = await response.json();
                    setUserData(data.data);
                } else {
                    console.error("Failed to fetch user data");
                }
            } catch (error) {
                console.error("An error occurred while fetching user data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [energyUrl]);


    const formatNumber = (number) => {
        return new Intl.NumberFormat().format(number);
    };

    const formatDateTime = (dateTimeString) => {
        if (!dateTimeString) return '';

        const date = new Date(dateTimeString);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const paymentType = formatDateTime(userData?.created_at) || '';

    if (loading) {
        return <div className="flex justify-center items-center text-xs mt-8 text-green-600">Loading...</div>;
    }

    return (
        <>
            <div className='bg-[#F7F8FA] h-[100vh] mt-10 mb-40
            '>
                <div className='flex items-center justify-between h-10 mt-2 rounded-3xl ml-[6%] mr-[6%]'>
                    <p className='text-xs font-bold'>Transaction Receipt</p>
                    <img src={proceed} alt='LogoImg' className='w-12 h-6 cursor-pointer' onClick={handleBack} />
                </div>

                <div className='bg-white mt-8 pb-10 ml-[6%] mr-[6%] rounded-xl'>
                    <div className='grid grid-cols-2 gap-[10%] text-xs p-3'>
                        <div className='flex flex-col p-3'>
                            <p className='font-semibold text-gray-500'>Meter Number</p>
                            <p className='font-bold'>
                                {meterNumbers}
                            </p>
                        </div>

                        <div className='flex flex-col p-3'>
                            <p className='font-semibold text-xs text-gray-500'>Gas Volume</p>
                            <p className='font-bold flex'>
                                {gasVolume}KG
                            </p>
                        </div>

                        <div className='flex flex-col p-3'>
                            <p className='font-semibold text-xs text-gray-500'>Reference</p>
                            <p className='font-bold'>
                                {userData?.serial_number}
                            </p>
                        </div>

                        <div className='flex flex-col p-3'>
                            <p className='font-semibold text-gray-500'>Payment Type</p>
                            <p className='font-semibold'>
                                {formatDateTime(userData?.created_at)}
                            </p>
                        </div>

                        <div className='flex flex-col p-3'>
                            <p className='font-semibold text-xs text-gray-500'>Meter Name</p>
                            <p className='font-bold'>
                                {userData?.meter?.type}
                            </p>
                        </div>

                        <div className='flex flex-col p-3'>
                            <p className='font-semibold text-gray-500'>Meter Address</p>
                            <p className='font-semibold'>
                                {userData?.address}
                            </p>
                        </div>
                    </div>

                    <div className='flex justify-between items-center mt-6 text-xs p-6 text-gray-500'>
                        <p className='font-semibold'>Payment Breakdown</p>
                        <p className='font-semibold'>
                            {formatDateTime(userData?.last_purcharse?.created_at)}
                        </p>
                    </div>

                    <div>
                        <div className='bg-[#3361ff] grid grid-cols-3 gap-[5%]  ml-[6%] mr-[6%] p-3 pl-5 pr-5 text-white rounded-xl'>
                            <div>
                                <p className='text-[10px]'>Unit</p>
                                <p className='font-semibold text-xl'> ₦{formatNumber(userData?.last_purcharse?.total_product_price)} </p>
                            </div>
                           
                            <h6 className='bg-white text-white h-10 w-[1%] ml-[50%]'> </h6>

                            <div>
                                <p className='text-[10px]'>VAT</p>
                                <p className='font-semibold text-xl'>₦{formatNumber(userData?.last_purcharse?.VAT)}</p>
                            </div>
                            

                        </div>

                        <div className='bg-[#3361ff] grid grid-cols-3  mt-2  gap-[5%] ml-[6%] mr-[6%] p-3 pl-5 pr-5 text-white rounded-xl'>
                            <div>
                                <p className='text-[10px] w-fit'>Service Charge</p>
                                <p className='font-semibold text-xl'>₦{formatNumber(userData?.last_purcharse?.total_service_charge)}</p>
                            </div>
                           

                            <h6 className='bg-white text-white h-10 w-[1%] ml-[50%]'> </h6>

                            <div>
                                <p className='text-[10px]'>Total</p>
                                <p className='font-semibold text-xl'>₦{formatNumber(userData?.last_purcharse?.total)}</p>
                            </div>
                            
                        </div>

                        <div className="flex justify-center">
                            <button className='bg-[#29CC39] flex items-center mt-2 justify-center p-3 text-white border rounded-full'>
                                <MdCheckCircleOutline />
                                <p className='font-semibold text-xs'>Successful </p>
                            </button>
                        </div>
                    </div>
                </div>

                <div className='flex p-3 ml-[6%] mr-[6%] gap-6  items-center justify-center rounded-md'>
                    <div className="flex justify-between">
                        <QRCode
                            value={`Gas Volume: ${gasVolume}, Total Amount: ${userData?.last_purcharse?.amount}`}
                            size={64}
                        />
                    </div>
                    <div className="border-b mt-4 mb-2 pb-2 text-[9px]">
                        <h2>Unlock Innovation at Your  </h2>
                        <h2>Fingertips. Download the </h2>
                        <h2>Rokswood App Now!</h2> <br />
                        <h2>info@rokswood.com  </h2>
                        <h2>www.rokswoodgas.com</h2>
                    </div>
                    <img src={Rgas} alt='LogoImg' className='w-6 h-12 cursor-pointer' />
                </div>

                <div className="flex gap-[20%] justify-center mt-8 mb-4">
                    <button
                        onClick={handleShare}
                        className="flex w-24 h-10 gap-4 justify-center items-center border rounded-md text-[#1F6FE3] border-black bg-[#1f6fe340]">
                        <MdShare />
                        <p className='text-sm'>Share </p>
                    </button>

                    <Link to='/buyToken'>
                        <button className="flex w-24 h-10 gap-4 justify-center items-center border rounded-md text-[#1F6FE3] border-black bg-[#ff9d0090]">
                            <MdReorder />
                            <p className='text-sm'>Reorder</p>
                        </button>
                    </Link>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}

export default Receipt