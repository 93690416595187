import React, { useState, useCallback } from "react";
import { hide, userReg, Rgas } from "../../asset/index";
import { Link, useNavigate } from 'react-router-dom';
import LoadingScreen from "../../loading/LoadingScreen"
import { animateScroll as scroll } from 'react-scroll';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ArrowBackOutlined, ErrorOutlined, GpsFixedOutlined } from "@mui/icons-material";
import { call2, discover, message2 } from "../../assetPicknDrop";
import ModalGas from "../../components/ModalGas";
import { useLogin } from "./hook/useLogin";
import { useAuthContext } from "../../hook/useAuthContext";

const PickRegister = () => {
    const { login, isLoading } = useLogin()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [passwordVisibility, setPasswordVisibility] = useState(true);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const handleBack = useCallback(() => navigate(-1), [navigate]);

    const pndUrl = process.env.REACT_APP_PND_BASE_URL

    const [formData, setFormData] = useState({
        address: "",
        country: "",
        dob: "",
        email: "",
        firstname: "",
        gender: "",
        has_cylinder: "",
        lastname: "",
        phone: "",
        privacy_policy_consent: "",
        state: "",
        terms_and_conditions_consent: "",
      });
    

    const handleModalOpen = () => {
        setShowModal(true)
    }

    const handleModalClose = () => {
        setShowModal(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

      
    if (!formData.privacy_policy_consent || !formData.terms_and_conditions_consent) {
        toast.error('Please accept both privacy policy and terms and conditions');
        return;
    }
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (formData.email.trim() !== '' && !emailRegex.test(formData.email)) {
            toast.error('Please enter a valid email address');
            return;
          }

          try {
            setLoading(true);
      
            const response = await fetch(`${pndUrl}/users/register`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            body: JSON.stringify({
                ...formData, 
                privacy_policy_consent: true, 
                terms_and_conditions_consent: true,
            }),
            });
      
            if (!response.ok) {
              throw new Error("Failed to create Pick & Drop User");
            }
      
            const data = await response.json();
            toast.success(data.message);
    
            setTimeout(() => {
                navigate('/pLogin');
            }, 2000);    
      
          } catch (error) {
            toast.error("Error creating Pick & Drop User:", error.message);
      
          } finally {
            setLoading(false);
          }
        };

    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };
    if (isLoading) {
        return <LoadingScreen />
    }

    const handleClearPassword = () => {
        setPassword('');
    };

    const handleValueChange = (field, value) => {
        setFormData((prevState) => ({ ...prevState, [field]: value }));
    
        };

    const handleConsentChange = (e) => {
            setFormData({
              ...formData,
              privacy_policy_consent: e.target.checked,
              terms_and_conditions_consent: e.target.checked,
            });
          };
          

    return (
        <div className="overflow-y-hidden p-4">
            <ToastContainer />
            <div className='flex items-center justify-between h-10 mt-8 rounded-3xl ml-[6%] mr-[6%]'>
                <ArrowBackOutlined className='border rounded-full p-1 cursor-pointer' onClick={handleBack} />
                <p className='text-xs text-[#A7A9B7]'>Register</p>
                <img src={Rgas} alt='LogoImg' className='w-4 h-6' />
            </div>
            <div className="max-w-md mx-auto mt-[10%] p-4">
                <form onSubmit={handleSubmit}>

                    {/* name */}
                    <div className="mb-4">
                        <label className="text-gray-700 text-bold">First Name</label>
                        <div className="relative">
                            <input
                                type="text"
                                className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
                                placeholder="enter your name"
                                onChange={(e) => handleValueChange("firstname", e.target.value)}                                
                            
                            />
                            <div className="absolute top-0 right-0 m-2">
                                <img
                                    src={userReg} alt="personImg"
                                    className="h-4 w-3 text-black"
                                />
                            </div>
                        </div>
                    </div>

                     {/* name */}
                     <div className="mb-4">
                        <label className="text-gray-700 text-bold">Last Name</label>
                        <div className="relative">
                            <input
                                type="text"
                                className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
                                placeholder="enter your last name"
                                onChange={(e) => handleValueChange("lastname", e.target.value)}
                            />
                            <div className="absolute top-0 right-0 m-2">
                                <img
                                    src={userReg} alt="personImg"
                                    className="h-4 w-3 text-black"
                                />
                            </div>
                        </div>
                    </div>

                    {/* email */}

                    <div className="mb-4">
                        <label className="text-gray-700 text-bold">Email</label>
                        <div className="relative">
                            <input
                                type="text"
                                className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
                                placeholder="enter your email"
                                onChange={(e) => handleValueChange("email", e.target.value)}
                            />
                            <div className="absolute top-0 right-0 m-2">
                                <img
                                    src={message2} alt="personImg"
                                    className="h-4 w-5 text-black"
                                />
                            </div>
                        </div>
                        {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
                    </div>

                    {/* number */}
                    <div className="mb-4">
                        <label className="text-gray-700 text-bold">Phone Number</label>
                        <div className="relative">
                            <input
                                type="text"
                                className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
                                placeholder="enter your phone number"
                                onChange={(e) => handleValueChange("phone", e.target.value)}
                            />
                            <div className="absolute top-0 right-0 m-2">
                                <img
                                    src={call2} alt="personImg"
                                    className="h-5 w-4 text-black"
                                />
                            </div>
                        </div>
                    </div>

                    {/* country */}
                    <div className="mb-4">
                        <label className="text-gray-700 text-bold">Country</label>
                        <div className="relative">
                            <input
                                type="text"
                                className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
                                placeholder="enter your country"
                                onChange={(e) => handleValueChange("country", e.target.value)}
                            />
                            <div className="absolute top-0 right-0 m-2">
                                <img
                                    src={discover} alt="personImg"
                                    className="h-5 w-5 text-black"
                                />
                            </div>
                        </div>
                    </div>

                    {/* state */}
                    <div className="mb-4">
                        <label className="text-gray-700 text-bold"> State</label>
                        <div className="relative">
                            <input
                                type="text"
                                className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
                                placeholder="enter your state"
                                onChange={(e) => handleValueChange("state", e.target.value)}
                            />
                            <div className="absolute top-0 right-0 m-2">
                                <img
                                    src={discover} alt="personImg"
                                    className="h-5 w-5 text-black"
                                />
                            </div>
                        </div>
                    </div>

                    {/* address */}
                    <div className="mb-4">
                        <label className="text-gray-700 text-bold">Address</label>
                        <div className="relative">
                            <input
                                type="text"
                                className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
                                placeholder="enter your address"
                                onChange={(e) => handleValueChange("address", e.target.value)}
                            />
                            <div className="absolute top-0 right-0 m-2">
                            <GpsFixedOutlined/>                               
                            </div>
                        </div>
                    </div>

                    {/* gender */}
                    <div className="mb-4">
                        <label className="text-gray-700 text-bold">Gender</label>
                        <div className="relative">
                            <select
                                type="text"
                                className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
                                placeholder="enter your gender"
                                onChange={(e) => handleValueChange("gender", e.target.value)} >
                                    <option value="male">male</option>
                                    <option value="female">female</option>
                            </select>
                            <div className="absolute top-0 right-3 m-2">
                                <img
                                    src={userReg} alt="personImg"
                                    className="h-4 w-3 text-black"
                                />
                            </div>
                        </div>
                    </div>                   


                    {/* date */}

                    <div className="mb-4">
                        <label className="text-gray-700 text-bold">Date</label>
                        <div className="relative">
                            <input
                                type="date"
                                className="w-full border-b border-black focus:outline-none focus:border-[#1F6FE2] text-sm py-2"
                                placeholder="enter your dob"
                                onChange={(e) => handleValueChange("dob", e.target.value)}
                            />                           
                        </div>
                    </div>

                    {/* password */}
                    <div className="mb-4 pt-4">
                        <label className="text-gray-700 text-bold">Password</label>
                        <div className="relative">
                            <input
                                type={passwordVisibility ? "password" : "text"}
                                className="w-full border-b border-black  text-sm focus:outline-none focus:border-[#1F6FE2] py-2"
                                placeholder="enter your password"
                                onChange={(e) => handleValueChange("password", e.target.value)}
                                onClick={togglePasswordVisibility}
                            />
                            <div className="absolute top-0 right-0 m-2">
                                <img
                                    src={hide} alt="hideImg"
                                    className="h-3 w-4 text-black"
                                />
                            </div>
                            {password && (
                                <button
                                    type="button"
                                    onClick={handleClearPassword}
                                    className="ml-2 text-[10px] -mt-8 text-gray-500 hover:text-gray-700"
                                >  Clear password
                                </button>
                            )}
                        </div>
                        {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
                    </div>

                    <div className="text-xs"> 
                        <input type='checkbox' id='card' name='paymentMethod' 
                            value='Card' 
                            onClick = {handleModalOpen} 
                            className="cursor-pointer" 
                            onChange={(e) => handleValueChange("has_cylinder", e.target.value)}
                            />
                        <label htmlFor='card'> {'\u00a0'}Onboard and refurbish my gas</label>                        
                        <ErrorOutlined className="text-[#7caf87] ml-[29%] cursor-pointer" />                        
                    </div>
                        {showModal && <ModalGas closeModal = {handleModalClose} />}
                    <br />

                    <div className="text-xs">
                        <input type='checkbox' id='payOnDelivery' name='paymentMethod' 
                            value='Pay on Delivery' 
                            onChange={handleConsentChange}                        
                            />
                        <label htmlFor='payOnDelivery'> {'\u00a0'}Accepted <u>terms and condition</u> & <u>privacy policy</u></label>
                    </div>

                    <button
                        className="w-full mt-[10%] mb-[4%] pt-4 pb-4 text-[#1F6FE2] hover:bg-[#1F6FE2] hover:text-white border border-[#1F6FE2] text-sm font-bold rounded-md"
                        disabled={isLoading}>
                        {loading ? "Signing Up..." : "SIGN UP"}
                    </button>
                    {error && <div className="error text-[12px] text-red-500 -mt-4 -pt-4 flex justify-center">{error}</div>}
                    <Link to="/pLogin">
                        <p className="text-sm flex justify-center items-center mb-[30%]">Do you have an account? <span className="underline text-[#1F6FE2] cursor-pointer">{'\u00a0'}login here</span></p>
                    </Link>                              
                </form>
            </div>
        </div>
    )
};
export default PickRegister;